import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import RoleModel from "stores/projects/roles/models/RoleModel";
import ProjectModel from "../ProjectModel";

import { initial as IRoleApplicationInitial, IRoleApplicationState } from "./models/role-application/IRoleApplication";

export default interface IProjectRoles {
  readonly roleDetail: RoleModel | undefined;
  readonly project: ProjectModel | undefined;
  readonly roleInvitations: RoleInvitationModel[];
  readonly roles: RoleModel[];

  readonly createdRoleInvitation: string | undefined;
  readonly withdrawRoleInvitation: string | undefined;
  readonly resendRoleInvitation: string | undefined;
  readonly requestCreatedRole: string | undefined;
  readonly requestUpdatedRole: string | undefined;

  readonly roleApplication: IRoleApplicationState;
}

export const initialState: IProjectRoles = {
  roleDetail: undefined,
  project: undefined,
  roleInvitations: [],
  roles: [],

  createdRoleInvitation: undefined,
  withdrawRoleInvitation: undefined,
  resendRoleInvitation: undefined,
  requestCreatedRole: undefined,
  requestUpdatedRole: undefined,

  roleApplication: IRoleApplicationInitial,
};
