import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import IStore from "../models/IStore";
import IAction from "../models/IAction";
import IError from "../models/IError";
import ToastStatusEnum from "../constants/ToastStatusEnum";
import * as ToastsAction from "../stores/toasts/ToastsAction";
import { removeCookieData } from "utilities/CookieUtility";
import CookieEnum from "constants/CookieEnum";

const errorToastMiddleware =
  (): Middleware =>
  (store: MiddlewareAPI<Dispatch, IStore>) =>
  (next: Dispatch) =>
  (action: IAction<any>): void => {
    if (action.error) {
      const errorAction = action as Required<IAction<IError>>;

      if (errorAction.payload.message === "Invalid token." || errorAction.payload.message === "Geçersiz simge.") {
        removeCookieData(CookieEnum.User);
        removeCookieData(CookieEnum.Token);
      }
      next(ToastsAction.add(errorAction.payload.message, ToastStatusEnum.Error));
    }

    next(action);
  };

export default errorToastMiddleware;
