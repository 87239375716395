import IStore from "models/IStore";
import IAction from "models/IAction";
import IPagination from "models/IPagination";
import { ReduxDispatch } from "models/ReduxProps";
import CastAgencyModel from "./models/CastAgencyModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as CastAgenciesEffect from "./CastAgenciesEffect";
import ActorModel from "stores/actors/models/actors/ActorModel";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import CastAgencyActorModel from "./models/actor/CastAgencyActorModel";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | IPagination<CastAgencyModel[]>
  | CastAgencyModel
  | ActorModel[]
  | CastAgencyActorModel[]
  | CastAgencyActorModel;

export const REQUEST_CAST_AGENCIES: string = "CastAgenciesAction.REQUEST_CAST_AGENCIES";
export const REQUEST_CAST_AGENCIES_FINISHED: string = "CastAgenciesAction.REQUEST_CAST_AGENCIES_FINISHED";
//SEARCH CAST AGENCIES WITH SEARCH AND PAGE
export const requestCastAgencies = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<CastAgencyModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_AGENCIES,
      CastAgenciesEffect.requestCastAgencies,
      search,
      page,
    );
  };
};

export const CLEAR_CAST_AGENCIES: string = "CastAgenciesAction.CLEAR_CAST_AGENCIES";
export const clearCastAgencies = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_CAST_AGENCIES);
};

export const REQUEST_CAST_AGENCY: string = "CastAgenciesAction.REQUEST_CAST_AGENCY";
export const REQUEST_CAST_AGENCY_FINISHED: string = "CastAgenciesAction.REQUEST_CAST_AGENCY_FINISHED";
//FIND CAST AGENCY WITH SLUG
export const requestCastAgency = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CastAgencyModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_AGENCY,
      CastAgenciesEffect.requestCastAgency,
      slug,
    );
  };
};

export const CLEAR_CAST_AGENCY_DETAIL: string = "CastAgenciesAction.CLEAR_CAST_AGENCY_DETAIL";
//SET UNDEFINED CAST DETAIL
export const clearCastDetail = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_CAST_AGENCY_DETAIL);
};

export const REQUEST_CREATE_CAST_AGENCY: string = "CastAgenciesAction.REQUEST_CREATE_CAST_AGENCY";
export const REQUEST_CREATE_CAST_AGENCY_FINISHED: string = "CastAgenciesAction.REQUEST_CREATE_CAST_AGENCY_FINISHED";
//REQUEST CREATE CAST AGENCY
export const requestCreateCastAgency = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_CAST_AGENCY,
      CastAgenciesEffect.requestCreateCastAgency,
      formData,
    );
  };
};

export const CLEAR_REQUEST_CREATE_CAST_AGENCY: string = "CastAgenciesAction.CLEAR_REQUEST_CREATE_CAST_AGENCY";
//SET UNDEFINED REQUEST CREATE CAST AGENCY
export const clearRequestCreateCastAgency = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_CAST_AGENCY);
};

export const REQUEST_UPDATE_CAST_AGENCY: string = "CastAgenciesAction.REQUEST_UPDATE_CAST_AGENCY";
export const REQUEST_UPDATE_CAST_AGENCY_FINISHED: string = "CastAgenciesAction.REQUEST_UPDATE_CAST_AGENCY_FINISHED";
//REQUEST UPDATE CAST AGENCY PROFILE
export const requestUpdateCastAgency = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_CAST_AGENCY,
      CastAgenciesEffect.requestUpdateCastAgency,
      formData,
      slug,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_CAST_AGENCY: string = "CastAgenciesAction.CLEAR_REQUEST_UPDATE_CAST_AGENCY";
//SET UNDEFINED REQUEST UPDATE CAST AGENCY
export const clearRequestUpdateCastAgency = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_CAST_AGENCY);
};

export const REQUEST_CAST_AGENCY_ACTORS: string = "CastAgenciesAction.REQUEST_CAST_AGENCY_ACTORS";
export const REQUEST_CAST_AGENCY_ACTORS_FINISHED: string = "CastAgenciesAction.REQUEST_CAST_AGENCY_ACTORS_FINISHED";
//REQUEST CAST ACTORS
export const requestCastAgencyActors = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CastAgencyActorModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_AGENCY_ACTORS,
      CastAgenciesEffect.requestCastAgencyActors,
      slug,
    );
  };
};

export const REQUEST_CAST_LIKED_BY_ME: string = "CastAgenciesAction.REQUEST_CAST_LIKED_BY_ME";
export const REQUEST_CAST_LIKED_BY_ME_FINISHED: string = "CastAgenciesAction.REQUEST_CAST_LIKED_BY_ME_FINISHED";

export const requestCastLikedByMe = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const cast_slug: string | undefined = getState().companies.castAgencyDetail?.slug;
    await ActionUtility.createThunkEffect<CastAgencyActorModel | undefined | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_LIKED_BY_ME,
      CastAgenciesEffect.requestCastLikedByMe,
      cast_slug,
    );
  };
};

export const REQUEST_ACTOR_LIKE_TO_CAST: string = "CastAgenciesAction.REQUEST_ACTOR_LIKE_TO_CAST";
export const REQUEST_ACTOR_LIKE_TO_CAST_FINISHED: string = "CastAgenciesAction.REQUEST_ACTOR_LIKE_TO_CAST_FINISHED";

export const requestActorLikeToCast = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const cast_slug: string | undefined = getState().companies.castAgencyDetail?.slug;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_LIKE_TO_CAST,
      CastAgenciesEffect.requestActorLikeToCast,
      cast_slug,
    );
  };
};

export const REQUEST_ACTOR_UNLIKE_TO_CAST: string = "CastAgenciesAction.REQUEST_ACTOR_UNLIKE_TO_CAST";
export const REQUEST_ACTOR_UNLIKE_TO_CAST_FINISHED: string = "CastAgenciesAction.REQUEST_ACTOR_UNLIKE_TO_CAST_FINISHED";

export const requestActorUnLikeToCast = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const cast_slug: string | undefined = getState().companies.castAgencyDetail?.slug;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_UNLIKE_TO_CAST,
      CastAgenciesEffect.requestActorUnLikeToCast,
      cast_slug,
    );
  };
};
