import { ReportProfileType } from "interfaces";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import { ReduxDispatch } from "models/ReduxProps";
import GeneralBudgetAndCashCastModel from "models/api-models/reports/GeneralBudgetAndCashCastModel";
import GeneralBudgetAndCashModel from "models/api-models/reports/GeneralBudgetAndCashModel";
import GeneralProjectsModel from "models/api-models/reports/GeneralProjectsModel";
import InvitationAndApplicationStats from "models/api-models/reports/InvitationAndApplicationStats";
import ResourceDistributionByProjectCastModel from "models/api-models/reports/ResourceDistributionByProjectCastModel";
import ResourceDistributionByProjectModel from "models/api-models/reports/ResourceDistributionByProjectModel";
import ResourceDistributionByProjectTypeModel from "models/api-models/reports/ResourceDistributionByProjectTypeModel";
import RoleAndApplicationDistributionModel from "models/api-models/reports/RoleAndApplicationDistributionModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as ReportsEffect from "./ReportsEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | GeneralBudgetAndCashModel
  | GeneralBudgetAndCashCastModel
  | GeneralProjectsModel
  | InvitationAndApplicationStats[]
  | RoleAndApplicationDistributionModel[]
  | ResourceDistributionByProjectModel[]
  | ResourceDistributionByProjectCastModel[]
  | ResourceDistributionByProjectTypeModel[];

export const REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH: string = "ReportsAction.REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH";
export const REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_FINISHED";

export const requestReportsGeneralBudgetAndCash = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<GeneralBudgetAndCashModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH,
      ReportsEffect.requestReportsGeneralBudgetAndCash,
    );
  };
};

export const REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_CAST: string =
  "ReportsAction.REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_CAST";
export const REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_CAST_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_CAST_FINISHED";

export const requestReportsGeneralBudgetAndCashCast = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<GeneralBudgetAndCashCastModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_CAST,
      ReportsEffect.requestReportsGeneralBudgetAndCashCast,
    );
  };
};

export const REQUEST_REPORTS_GENERAL_PROJECTS: string = "ReportsAction.REQUEST_REPORTS_GENERAL_PROJECTS";
export const REQUEST_REPORTS_GENERAL_PROJECTS_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_GENERAL_PROJECTS_FINISHED";

export const requestReportsGeneralProjects = (role: ReportProfileType): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<GeneralProjectsModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_GENERAL_PROJECTS,
      ReportsEffect.requestReportsGeneralProjects,
      role,
    );
  };
};

export const REQUEST_REPORTS_INVITATION_AND_APPLICATION_STATS: string =
  "ReportsAction.REQUEST_REPORTS_INVITATION_AND_APPLICATION_STATS";
export const REQUEST_REPORTS_INVITATION_AND_APPLICATION_STATS_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_INVITATION_AND_APPLICATION_STATS_FINISHED";

export const requestReportsInvitationAndApplicationStats = (role: ReportProfileType): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<InvitationAndApplicationStats[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_INVITATION_AND_APPLICATION_STATS,
      ReportsEffect.requestReportsInvitationAndApplicationStats,
      role,
    );
  };
};

export const REQUEST_REPORTS_ROLE_AND_APPLICATION_DISTRIBUTION: string =
  "ReportsAction.REQUEST_REPORTS_ROLE_AND_APPLICATION_DISTRIBUTION";
export const REQUEST_REPORTS_ROLE_AND_APPLICATION_DISTRIBUTION_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_ROLE_AND_APPLICATION_DISTRIBUTION_FINISHED";

export const requestReportsRoleAndApplicationDistribution = (role: ReportProfileType): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleAndApplicationDistributionModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_ROLE_AND_APPLICATION_DISTRIBUTION,
      ReportsEffect.requestReportsRoleAndApplicationDistribution,
      role,
    );
  };
};

export const REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT: string =
  "ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT";
export const REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_FINISHED";

export const requestReportsResourceDistributionByProject = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ResourceDistributionByProjectModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT,
      ReportsEffect.requestReportsResourceDistributionByProject,
    );
  };
};

export const REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_CAST: string =
  "ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_CAST";
export const REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_CAST_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_CAST_FINISHED";

export const requestReportsResourceDistributionByProjectCast = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ResourceDistributionByProjectCastModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_CAST,
      ReportsEffect.requestReportsResourceDistributionByProjectCast,
    );
  };
};

export const REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_TYPE: string =
  "ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_TYPE";
export const REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_TYPE_FINISHED: string =
  "ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_TYPE_FINISHED";

export const requestReportsResourceDistributionByProjectType = (role: ReportProfileType): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ResourceDistributionByProjectTypeModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_TYPE,
      ReportsEffect.requestReportsResourceDistributionByProjectType,
      role,
    );
  };
};

export const CLEAR_REPORTS: string = "ReportsAction.CLEAR_REPORTS";

export const clearReports = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REPORTS);
};
