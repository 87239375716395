import { BaseModel } from "sjs-base-model";

export default class AvatarModel extends BaseModel {
  public readonly small: string = "";
  public readonly medium: string = "";
  public readonly large: string = "";
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<AvatarModel>) {
    super();

    this.update(data);
  }
}
