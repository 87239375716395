import * as ClientsEffect from "./ClientsEffect";
import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import { ReduxDispatch } from "models/ReduxProps";
import ClientModel from "./models/ClientModel";
import ClientOptionsModel from "./models/ClientOptionsModel";
import IAction from "models/IAction";
import IPagination from "models/IPagination";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | IPagination<ClientModel[]>
  | ClientModel
  | ClientOptionsModel
  | string;

export const REQUEST_CLIENT_COMPANY_OPTIONS: string = "ClientsAction.REQUEST_CLIENT_COMPANY_OPTIONS";
export const REQUEST_CLIENT_COMPANY_OPTIONS_FINISHED: string = "ClientsAction.REQUEST_CLIENT_COMPANY_OPTIONS_FINISHED";

export const requestClientOptions = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ClientOptionsModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CLIENT_COMPANY_OPTIONS,
      ClientsEffect.requestClientOptions,
    );
  };
};

export const REQUEST_CLIENT_COMPANIES: string = "ClientsAction.REQUEST_CLIENT_COMPANIES";
export const REQUEST_CLIENT_COMPANIES_FINISHED: string = "ClientsAction.REQUEST_CLIENT_COMPANIES_FINISHED";
//SEARCH CLIENT COMPANIES WITH SEARCH AND PAGE
export const requestClientCompanies = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<ClientModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CLIENT_COMPANIES,
      ClientsEffect.requestClientCompanies,
      search,
      page,
    );
  };
};

export const REQUEST_CLIENT_COMPANY: string = "ClientsAction.REQUEST_CLIENT_COMPANY";
export const REQUEST_CLIENT_COMPANY_FINISHED: string = "ClientsAction.REQUEST_CLIENT_COMPANY_FINISHED";
//FIND CLIENT COMPANY WITH SLUG
export const requestClientCompany = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ClientModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CLIENT_COMPANY,
      ClientsEffect.requestClientCompany,
      slug,
    );
  };
};

export const CLEAR_CLIENT_COMPANY_DETAIL: string = "ClientsAction.CLEAR_CLIENT_COMPANY_DETAIL";
//SET UNDEFINED CLIENT COMPANY DETAIL
export const clearClientCompanyDetail = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_CLIENT_COMPANY_DETAIL);
};

export const REQUEST_UPDATE_CLIENT_COMPANY: string = "ClientsAction.REQUEST_UPDATE_CLIENT_COMPANY";
export const REQUEST_UPDATE_CLIENT_COMPANY_FINISHED: string = "ClientsAction.REQUEST_UPDATE_CLIENT_COMPANY_FINISHED";
//REQUEST UPDATE CLIENT COMPANY
export const requestUpdateClientCompany = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_CLIENT_COMPANY,
      ClientsEffect.requestUpdateClientCompany,
      formData,
      slug,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_CLIENT_COMPANY: string = "ClientsAction.CLEAR_REQUEST_UPDATE_CLIENT_COMPANY";
//SET UNDEFINED UPDATE CLIENT COMPANY STATE
export const clearRequestUpdateClientCompany = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_CLIENT_COMPANY);
};

export const REQUEST_CREATE_CLIENT_COMPANY: string = "ClientsAction.REQUEST_CREATE_CLIENT_COMPANY";
export const REQUEST_CREATE_CLIENT_COMPANY_FINISHED: string = "ClientsAction.REQUEST_CREATE_CLIENT_COMPANY_FINISHED";
//REQUEST CREATE CLIENT COMPANY
export const requestCreateClientCompany = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_CLIENT_COMPANY,
      ClientsEffect.requestCreateClientCompany,
      formData,
    );
  };
};

export const CLEAR_REQUEST_CREATE_CLIENT_COMPANY: string = "ClientsAction.CLEAR_REQUEST_CREATE_CLIENT_COMPANY";
//SET UNDEFINED CREATE CLIENT COMPANY STATE
export const clearRequestCreateClientCompany = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_CLIENT_COMPANY);
};
