enum RoleApplicationStatusEnum {
  Pending = "pending",
  Presented = "presented",
  InvitedToCallback = "invited_to_callback",
  Shortlisted = "shortlisted",
  OfferMade = "offer_made",
  Pca = "pca",
  Optioned = "optioned",
  Elected = "elected",
  Actualized = "actualized",
  Declined = "declined",
  Finalized = "finalized",
}

export default RoleApplicationStatusEnum;
