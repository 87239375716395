import * as SeriesProjectsEffect from "./SeriesProjectsEffect";
import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import { ReduxDispatch } from "models/ReduxProps";
import SeriesProjectModel from "./models/SeriesProjectModel";
import IPagination from "models/IPagination";
import IAction from "models/IAction";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | IPagination<SeriesProjectModel[]>
  | SeriesProjectModel
  | SeriesProjectModel[]
  | string;

export const REQUEST_SERIES_PROJECTS: string = "SeriesProjectsAction.REQUEST_SERIES_PROJECTS";
export const REQUEST_SERIES_PROJECTS_FINISHED: string = "SeriesProjectsAction.REQUEST_SERIES_PROJECTS_FINISHED";

export const requestSeriesProjects = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<SeriesProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SERIES_PROJECTS,
      SeriesProjectsEffect.requestSeriesProjects,
      search,
      page,
    );
  };
};

export const REQUEST_SERIES_PROJECT_DETAIL: string = "SeriesProjectsAction.REQUEST_SERIES_PROJECT_DETAIL";
export const REQUEST_SERIES_PROJECT_DETAIL_FINISHED: string =
  "SeriesProjectsAction.REQUEST_SERIES_PROJECT_DETAIL_FINISHED";

//todo add pre request
export const findBySlug = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<SeriesProjectModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SERIES_PROJECT_DETAIL,
      SeriesProjectsEffect.requestFindBySlug,
      slug,
    );
  };
};

export const REQUEST_CREATE_SERIES_PROJECT: string = "SeriesProjectsAction.REQUEST_CREATE_SERIES_PROJECT";
export const REQUEST_CREATE_SERIES_PROJECT_FINISHED: string =
  "SeriesProjectsAction.REQUEST_CREATE_SERIES_PROJECT_FINISHED";

export const requestCreateSeriesProject = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_SERIES_PROJECT,
      SeriesProjectsEffect.requestCreateSeriesProject,
      formData,
    );
  };
};

export const REQUEST_UPDATE_SERIES_PROJECT: string = "SeriesProjectsAction.REQUEST_UPDATE_SERIES_PROJECT";
export const REQUEST_UPDATE_SERIES_PROJECT_FINISHED: string =
  "SeriesProjectsAction.REQUEST_UPDATE_SERIES_PROJECT_FINISHED";

export const requestUpdateSeriesProject = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_SERIES_PROJECT,
      SeriesProjectsEffect.requestUpdateSeriesProject,
      formData,
      slug,
    );
  };
};

export const FILTER_SERIES_PROJECTS_SHOW: string = "SeriesProjectsAction.FILTER_SERIES_PROJECTS_SHOW";

export const filterProjectsShow = (filter: boolean): IAction<boolean> => {
  return ActionUtility.createAction(FILTER_SERIES_PROJECTS_SHOW, filter);
};

export const REQUEST_SERIES_PROJECTS_FILTER: string = "SeriesProjectsAction.REQUEST_SERIES_PROJECTS_FILTER";
export const REQUEST_SERIES_PROJECTS_FILTER_FINISHED: string =
  "SeriesProjectsAction.REQUEST_SERIES_PROJECTS_FILTER_FINISHED";

export const requestProjectsFilter = (filters: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<SeriesProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SERIES_PROJECTS_FILTER,
      SeriesProjectsEffect.requestSeriesProjectsFilter,
      filters,
    );
  };
};

export const REQUEST_UPDATE_SERIES_PROJECT_STATUS: string = "SeriesProjectsAction.REQUEST_UPDATE_SERIES_PROJECT_STATUS";
export const REQUEST_UPDATE_SERIES_PROJECT_STATUS_FINISHED: string =
  "SeriesProjectsAction.REQUEST_UPDATE_SERIES_PROJECT_STATUS_FINISHED";

export const requestUpdateSeriesProjectStatus = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_SERIES_PROJECT_STATUS,
      SeriesProjectsEffect.requestUpdateSeriesProjectStatus,
      formData,
      slug,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_SERIES_PROJECT_STATUS: string =
  "SeriesProjectsAction.CLEAR_REQUEST_UPDATE_SERIES_PROJECT_STATUS";

export const clearRequestUpdateSeriesProjectStatus = (): IAction<any> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_SERIES_PROJECT_STATUS);
};

export const REQUEST_FIND_SERIES_PROJECT_DETAIL: string = "SeriesProjectsAction.REQUEST_FIND_SERIES_PROJECT_DETAIL";
export const REQUEST_FIND_SERIES_PROJECT_DETAIL_FINISHED: string =
  "SeriesProjectsAction.REQUEST_FIND_SERIES_PROJECT_DETAIL_FINISHED";

export const requestFindSeriesProject = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<SeriesProjectModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_FIND_SERIES_PROJECT_DETAIL,
      SeriesProjectsEffect.requestFindBySlug,
      slug,
    );
  };
};
