import BaseCompanyModel from "../../models/BaseCompanyModel";
import ProductionProjectModel from "./ProductionProjectModel";

export default class ProductionModel extends BaseCompanyModel {
  public readonly commercials: boolean = false;
  public readonly movies: boolean = false;
  public readonly series: boolean = false;

  public readonly projects: ProductionProjectModel[] = [ProductionProjectModel as any];

  constructor(production_model: Partial<ProductionModel>) {
    super(production_model);

    this.update(production_model);
  }
}
