import { ReduxDispatch } from "models/ReduxProps";

import IAction from "models/IAction";
import IPagination from "models/IPagination";
import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import CommercialProjectModel from "./models/CommercialProjectModel";
import * as CommercialsProjectsEffect from "./CommercialsProjectsEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | IPagination<CommercialProjectModel[]>
  | CommercialProjectModel
  | string;

export const REQUEST_COMMERCIAL_PROJECTS: string = "CommercialsProjectsAction.REQUEST_COMMERCIAL_PROJECTS";
export const REQUEST_COMMERCIAL_PROJECTS_FINISHED: string =
  "CommercialsProjectsAction.REQUEST_COMMERCIAL_PROJECTS_FINISHED";

export const requestCommercialProjects = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<CommercialProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_COMMERCIAL_PROJECTS,
      CommercialsProjectsEffect.requestCommercialProjects,
      search,
      page,
    );
  };
};

export const REQUEST_COMMERCIAL_PROJECT_DETAIL: string = "CommercialsProjectsAction.REQUEST_COMMERCIAL_PROJECT_DETAIL";
export const REQUEST_COMMERCIAL_PROJECT_DETAIL_FINISHED: string =
  "CommercialsProjectsAction.REQUEST_COMMERCIAL_PROJECT_DETAIL_FINISHED";

export const requestCommercialProject = (slug: string | null): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CommercialProjectModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_COMMERCIAL_PROJECT_DETAIL,
      CommercialsProjectsEffect.requestCommercialProject,
      slug,
    );
  };
};

export const REQUEST_CREATE_COMMERCIAL_PROJECT: string = "CommercialsProjectsAction.REQUEST_CREATE_COMMERCIAL_PROJECT";
export const REQUEST_CREATE_COMMERCIAL_PROJECT_FINISHED: string =
  "CommercialsProjectsAction.REQUEST_CREATE_COMMERCIAL_PROJECT_FINISHED";

export const requestCreateCommercialProject = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_COMMERCIAL_PROJECT,
      CommercialsProjectsEffect.requestCreateCommercialProject,
      formData,
    );
  };
};

export const REQUEST_UPDATE_COMMERCIAL_PROJECT: string = "CommercialsProjectsAction.REQUEST_UPDATE_COMMERCIAL_PROJECT";
export const REQUEST_UPDATE_COMMERCIAL_PROJECT_FINISHED: string =
  "CommercialsProjectsAction.REQUEST_UPDATE_COMMERCIAL_PROJECT_FINISHED";

export const requestUpdateCommercialProject = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_COMMERCIAL_PROJECT,
      CommercialsProjectsEffect.requestUpdateCommercialProject,
      formData,
      slug,
    );
  };
};

export const FILTER_COMMERCIALS_PROJECTS_SHOW: string = "CommercialsProjectsAction.FILTER_COMMERCIALS_PROJECTS_SHOW";

export const filterProjectsShow = (filter: boolean): IAction<boolean> => {
  return ActionUtility.createAction(FILTER_COMMERCIALS_PROJECTS_SHOW, filter);
};

export const REQUEST_COMMERCIALS_PROJECTS_FILTER: string =
  "CommercialsProjectsAction.REQUEST_COMMERCIALS_PROJECTS_FILTER";
export const REQUEST_COMMERCIALS_PROJECTS_FILTER_FINISHED: string =
  "CommercialsProjectsAction.REQUEST_COMMERCIALS_PROJECTS_FILTER_FINISHED";

export const requestProjectsFilter = (filters: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<CommercialProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_COMMERCIALS_PROJECTS_FILTER,
      CommercialsProjectsEffect.requestCommercialsProjectsFilter,
      filters,
    );
  };
};

export const REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS: string =
  "CommercialsProjectsAction.REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS";
export const REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS_FINISHED: string =
  "CommercialsProjectsAction.REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS_FINISHED";

export const requestUpdateCommercialProjectStatus = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS,
      CommercialsProjectsEffect.requestUpdateCommercialProjectStatus,
      formData,
      slug,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS: string =
  "CommercialsProjectsAction.CLEAR_REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS";

export const clearRequestUpdateCommercialProjectStatus = (): IAction<any> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS);
};

export const REQUEST_FIND_COMMERCIAL_PROJECT_DETAIL: string =
  "CommercialsProjectsAction.REQUEST_FIND_COMMERCIAL_PROJECT_DETAIL";
export const REQUEST_FIND_COMMERCIAL_PROJECT_DETAIL_FINISHED: string =
  "CommercialsProjectsAction.REQUEST_FIND_COMMERCIAL_PROJECT_DETAIL_FINISHED";

export const requestFindCommercialProject = (slug: string | null): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CommercialProjectModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_FIND_COMMERCIAL_PROJECT_DETAIL,
      CommercialsProjectsEffect.requestCommercialProject,
      slug,
    );
  };
};
