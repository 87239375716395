import RouteEnum from "constants/RouteEnum";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./NavTopMenuContent.css";

const NavTopMenuContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <li className="header-nav-item">
        <NavLink exact={true} activeClassName="is-active" className="menu-link" to={RouteEnum.ContactPage}>
          {t("general.contact")}
        </NavLink>
      </li>
      <li className="header-nav-item">
        <NavLink exact={true} activeClassName="is-active" className="menu-link" to={RouteEnum.AboutPage}>
          {t("general.about")}
        </NavLink>
      </li>
    </Fragment>
  );
};

export default NavTopMenuContent;
