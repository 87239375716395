import { createSelector, Selector } from "reselect";
import IStore from "models/IStore";
import ProfileModel from "stores/profiles/models/ProfileModel";
import ILanguage from "./models/ILanguage";
import IUserCookieModel from "models/common-models/IUserCookieModel";

const _selectLanguages = (profile: ProfileModel | null): ILanguage[] => {
  const languages: ILanguage[] = profile?.languages || [];

  return languages;
};

export const selectLanguages: Selector<IStore, ILanguage[]> = createSelector(
  (state: IStore) => state.profiles.profile,
  _selectLanguages,
);

const _selectLanguage = (user: IUserCookieModel | null): string => {
  const language: string = user?.language || "";

  return language;
};

export const selectLanguage: Selector<IStore, string> = createSelector(
  (state: IStore) => state.auth.user,
  _selectLanguage,
);
