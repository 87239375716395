import EvaluationCriterionModel from "models/api-models/EvaluationCriterionModel";
import MeetingLocation from "models/api-models/MeetingLocation";
import RoleApplicationEvaluationModel from "models/api-models/RoleApplicationEvaluationModel";
import AuditionVideoModel from "models/api-models/audition-video/AuditionVideoModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import ActorModel from "stores/actors/models/actors/ActorModel";

export interface IRoleApplicationState {
  readonly roleApplications: RoleApplicationModel[];
  readonly roleApplicationDetail: RoleApplicationModel | undefined;
  readonly auditions: AuditionVideoModel[] | undefined;
  readonly actor: ActorModel | undefined;

  readonly requestPresent: string | undefined;
  readonly requestInviteToCallBack: string | undefined;
  readonly requestShortList: string | undefined;
  readonly requestDecline: string | undefined;
  readonly requestOption: string | undefined;
  readonly requestElect: string | undefined;

  readonly requestMakeOfferRole: string | undefined;
  readonly makeOfferModal: boolean;
  readonly makeOfferActive: boolean;

  readonly requestWithdrawRoleOffer: string | undefined;

  readonly finalizeApplicationDetail: RoleApplicationModel | undefined;
  readonly finalizeModal: boolean;
  readonly requestFinalizeApplication: string | undefined;

  readonly inviteCallbackModalApplicationUuid: string | undefined;
  readonly meetingLocationEditModal: boolean | undefined;
  readonly meetingLocationEditModalApplicationUuid: string | undefined;

  readonly requestMeetingLocation: string | undefined;
  readonly actorMeetingLocation: MeetingLocation | undefined;

  readonly roleApplicationEvaluations: RoleApplicationEvaluationModel[] | undefined;
  readonly roleApplicationEvaluationCriteria?: EvaluationCriterionModel[];
  readonly roleApplicationEvaluationCreateRequest?: string;
  readonly roleApplicationEvaluationUpdateRequest?: string;

  readonly roleApplicationEvaluationDetail?: RoleApplicationEvaluationModel;
  readonly newRoleApplicationEvaluation?: boolean;

  readonly createMeetingEvaluationLinkResponse?: string;
}

export const initial: IRoleApplicationState = {
  roleApplications: [],
  roleApplicationDetail: undefined,
  actor: undefined,
  auditions: undefined,

  requestPresent: undefined,
  requestInviteToCallBack: undefined,
  requestShortList: undefined,
  requestDecline: undefined,
  requestOption: undefined,
  requestElect: undefined,

  requestMakeOfferRole: undefined,
  makeOfferModal: false,
  makeOfferActive: false,

  requestWithdrawRoleOffer: undefined,

  finalizeApplicationDetail: undefined,
  finalizeModal: false,
  requestFinalizeApplication: undefined,

  inviteCallbackModalApplicationUuid: undefined,
  meetingLocationEditModalApplicationUuid: undefined,
  meetingLocationEditModal: undefined,

  requestMeetingLocation: undefined,
  actorMeetingLocation: undefined,

  roleApplicationEvaluations: undefined,
};
