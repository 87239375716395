import IAction from "models/IAction";
import AuditionVideoModel from "models/api-models/audition-video/AuditionVideoModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import RoleOfferModel from "models/api-models/role-offer/RoleOfferModel";
import IProjectState from "../models/IProjectsState";
import * as ActorRoleApplicationsAction from "./ActorRoleApplicationsAction";

const ActorRoleApplicationsReducer = {
  [ActorRoleApplicationsAction.REQUEST_ROLE_APPLICATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        applicationDetail: action.payload,
      },
    };
  },

  [ActorRoleApplicationsAction.CLEAR_ROLE_APPLICATION](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        applicationDetail: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_CREATE_ROLE_APPLICATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        applicationDetail: action.payload,
        requestCreateRoleApplication: "success",
      },
    };
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_CREATE_ROLE_APPLICATION](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestCreateRoleApplication: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_UPDATE_ROLE_APPLICATION_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        applicationDetail: new RoleApplicationModel({
          ...state.actorRoleApplicationState.applicationDetail,
          cover_letter: action.payload,
        }),
        requestUpdateRoleApplication: "success",
      },
    };
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_UPDATE_ROLE_APPLICATION](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestUpdateRoleApplication: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_ROLE_INVITATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        invitationDetail: action.payload!,
      },
    };
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_ROLE_INVITATION](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        invitationDetail: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_ROLE_APPLICATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        applications: action.payload!,
      },
    };
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_ROLE_APPLICATIONS](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        applications: [],
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_CREATE_AUDITION_VIDEO_FINISHED](
    state: IProjectState,
    action: IAction<AuditionVideoModel[]>,
  ): IProjectState {
    if (state.actorRoleApplicationState.applicationDetail instanceof RoleApplicationModel) {
      return {
        ...state,
        actorRoleApplicationState: {
          ...state.actorRoleApplicationState,
          applicationDetail: new RoleApplicationModel({
            ...state.actorRoleApplicationState.applicationDetail,
            audition_videos: action.payload!,
          }),
          requestCreateAuditionVideo: "success",
        },
      };
    } else {
      return { ...state };
    }
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_CREATE_AUDITION_VIDEO](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestCreateAuditionVideo: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_DELETE_AUDITION_VIDEO_FINISHED](
    state: IProjectState,
    action: IAction<AuditionVideoModel[]>,
  ): IProjectState {
    if (state.actorRoleApplicationState.applicationDetail instanceof RoleApplicationModel) {
      return {
        ...state,
        actorRoleApplicationState: {
          ...state.actorRoleApplicationState,
          applicationDetail: new RoleApplicationModel({
            ...state.actorRoleApplicationState.applicationDetail,
            audition_videos: action.payload!,
          }),
          requestDeleteAuditionVideo: "success",
        },
      };
    } else {
      return { ...state };
    }
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_DELETE_AUDITION_VIDEO](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestDeleteAuditionVideo: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_UPDATE_AUDITION_VIDEO_FINISHED](
    state: IProjectState,
    action: IAction<AuditionVideoModel[]>,
  ): IProjectState {
    if (state.actorRoleApplicationState.applicationDetail instanceof RoleApplicationModel) {
      return {
        ...state,
        actorRoleApplicationState: {
          ...state.actorRoleApplicationState,
          applicationDetail: new RoleApplicationModel({
            ...state.actorRoleApplicationState.applicationDetail,
            audition_videos: action.payload!,
          }),
          requestUpdateAuditionVideo: "success",
        },
      };
    } else {
      return { ...state };
    }
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_UPDATE_AUDITION_VIDEO](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestUpdateAuditionVideo: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.SHOW_AUDITION_EDIT_MODAL](
    state: IProjectState,
    action: IAction<AuditionVideoModel>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        editAudition: action.payload!,
      },
    };
  },

  [ActorRoleApplicationsAction.CLOSE_AUDITION_EDIT_MODAL](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        editAudition: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_ACCEPT_ROLEOFFER_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestAcceptRoleOffer: action.payload,
      },
    };
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_ACCEPT_ROLEOFFER](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestAcceptRoleOffer: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_DECLINE_ROLEOFFER_FINISHED](
    state: IProjectState,
    action: IAction<RoleOfferModel[]>,
  ): IProjectState {
    if (state.actorRoleApplicationState.applicationDetail instanceof RoleApplicationModel) {
      return {
        ...state,
        actorRoleApplicationState: {
          ...state.actorRoleApplicationState,
          applicationDetail: new RoleApplicationModel({
            ...state.actorRoleApplicationState.applicationDetail,
            offers: action.payload!,
          }),
          requestDeclineRoleOffer: "success",
        },
      };
    } else {
      return {
        ...state,
      };
    }
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_DECLINE_ROLEOFFER](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestDeclineRoleOffer: undefined,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_FIND_ROLE_APPLICATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        applicationDetail: action.payload,
      },
    };
  },

  [ActorRoleApplicationsAction.REQUEST_CANCEL_ROLEOFFER_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestCancelRoleOffer: action.payload,
      },
    };
  },

  [ActorRoleApplicationsAction.CLEAR_REQUEST_CANCEL_ROLEOFFER](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleApplicationState: {
        ...state.actorRoleApplicationState,
        requestCancelRoleOffer: undefined,
      },
    };
  },
};

export default ActorRoleApplicationsReducer;
