import IRegisterState from "./models/IRegisterState";
import * as RegisterAction from "./RegisterAction";
import IAction from "../../models/IAction";
import baseReducer from "../../utilities/BaseReducer";
import { Reducer } from "redux";

export const initialState: IRegisterState = {
  message: null,
};

const registerReducer: Reducer = baseReducer(initialState, {
  //regiter page
  [RegisterAction.REQUEST_REGISTER_FINISHED](state: IRegisterState, action: IAction<string>): IRegisterState {
    return {
      ...state,
      message: action.payload!,
    };
  },

  [RegisterAction.CLEAR_MESSAGE](state: IRegisterState): IRegisterState {
    return {
      ...state,
      message: null,
    };
  },

  [RegisterAction.REQUEST_VERIFY_EMAIL_FINISHED](state: IRegisterState, action: IAction<string>): IRegisterState {
    return {
      ...state,
      message: action.payload!,
    };
  },

  [RegisterAction.REQUEST_RESET_PASSWORD_FINISHED](state: IRegisterState, action: IAction<string>): IRegisterState {
    return {
      ...state,
      message: action.payload!,
    };
  },

  [RegisterAction.REQUEST_RESEND_EMAIL_FINISHED](state: IRegisterState, action: IAction<string>): IRegisterState {
    return {
      ...state,
      message: action.payload!,
    };
  },

  [RegisterAction.REQUEST_PASSWORD_CONFIRM_FINISHED](state: IRegisterState, action: IAction<string>): IRegisterState {
    return {
      ...state,
      message: action.payload!,
    };
  },
});

export default registerReducer;
