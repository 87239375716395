import IAction from "models/IAction";
import * as CastProjectsAction from "./CastProjectsAction";

import CastRoleOfferModel from "models/api-models/cast-role-offer/CastRoleOfferModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import ISimpleProjectModel from "selectors/projects/models/ISimpleProjectModel";
import IProjectState from "../models/IProjectsState";

const CastProjectsReducer = {
  [CastProjectsAction.REQUEST_CAST_ROLE_INVITATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel[]>,
  ): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        roleInvitations: action.payload!,
      },
    };
  },

  [CastProjectsAction.CLEAR_REQUEST_CAST_ROLE_INVITATIONS](state: IProjectState): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        roleInvitations: [],
      },
    };
  },

  [CastProjectsAction.SET_SELECTED_ROLE](state: IProjectState, action: IAction<string | undefined>): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        selectedRole: action.payload,
      },
    };
  },

  [CastProjectsAction.REQUEST_CAST_ROLE_APPLICATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        roleApplications: action.payload!,
      },
    };
  },

  [CastProjectsAction.CLEAR_REQUEST_CAST_ROLE_APPLICATIONS](state: IProjectState): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        roleApplications: [],
      },
    };
  },

  [CastProjectsAction.REQUEST_CAST_ROLE_OFFERS_FINISHED](
    state: IProjectState,
    action: IAction<CastRoleOfferModel[]>,
  ): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        roleOffers: action.payload!,
      },
    };
  },

  [CastProjectsAction.CLEAR_REQUEST_CAST_ROLE_OFFERS](state: IProjectState): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        roleOffers: [],
      },
    };
  },

  [CastProjectsAction.REQUEST_CAST_PROJECTS_FINISHED](
    state: IProjectState,
    action: IAction<ISimpleProjectModel[]>,
  ): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        projects: action.payload!,
      },
    };
  },

  [CastProjectsAction.CLEAR_PROJECTS](state: IProjectState): IProjectState {
    return {
      ...state,
      castProjectsState: {
        ...state.castProjectsState,
        projects: [],
      },
    };
  },
};

export default CastProjectsReducer;
