import { BaseModel } from "sjs-base-model";

export default class CastAgencyModel extends BaseModel {
  //public readonly id: number = 0;
  public readonly created: string = "";
  public readonly status: string = "";
  public readonly status_changed: string = "";
  public readonly slug: string = "";
  public readonly name: string = "";
  public readonly logo: string | null = null;
  public readonly website: string = "";
  public readonly email: string = "";
  public readonly phone_number: string = "";
  public readonly fax_number: string = "";
  public readonly address: string = "";
  public readonly authorized_person: string = "";
  public readonly authorized_person_position: string = "";
  public readonly description: string = "";
  public readonly created_by: number = 0;
  public readonly finalized_role_applications: number = 0;
  public readonly role_applications: number = 0;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<CastAgencyModel>) {
    super();

    this.update(data);
  }
}
