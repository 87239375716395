import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import { AxiosResponse } from "axios";
import ProfileModel from "./models/ProfileModel";
import * as EffectUtility from "utilities/EffectUtility";
import ActorModel from "stores/actors/models/actors/ActorModel";
import CastAgencyModel from "stores/companies/cast-agencies/models/CastAgencyModel";
import GraduationModel from "./models/graduation-model/GraduationModel";

export const requestProfile = async (): Promise<ProfileModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.profiles.me;
  return EffectUtility.getToModel<ProfileModel>(ProfileModel, endpoint);
};

export const requestUpdateProfile = async (
  formData: any,
  userName: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.profiles.update.replace(":userName", userName);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Profile Update";
};

export const requestActorLikedCasts = async (uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.likes.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<CastAgencyModel>) => new CastAgencyModel(json));
};

export const getActorProfile = async (): Promise<ActorModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.me;
  return EffectUtility.getToModel<ActorModel>(ActorModel, endpoint);
};

export const getCastProfile = async (slug: string): Promise<CastAgencyModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.index.concat(slug);

  return EffectUtility.getToModel<CastAgencyModel>(CastAgencyModel, endpoint);
};

export const requestCastLikedActor = async (cast_slug: string): Promise<ActorModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.likes.replace(":slug", cast_slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<ActorModel>) => new ActorModel(json));
};

export const requestAddGraduation = async (formData: any): Promise<GraduationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.graduations.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new GraduationModel(response.data);
};

export const requestUpdateGraduation = async (
  formData: any,
  uuid: string,
  graduations: GraduationModel[],
): Promise<GraduationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.graduations.graduation.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  if (graduations) {
    const updatedGraduation: GraduationModel = new GraduationModel(response.data);
    const objIndex = graduations.findIndex((obj) => obj.uuid === uuid);
    let newGraduations = graduations.filter((object) => {
      return object.uuid !== uuid;
    });
    newGraduations.splice(objIndex, 0, updatedGraduation);
    return newGraduations;
  }
  return [];
};

export const requestDeleteGraduation = async (
  uuid: string,
  graduations: GraduationModel[] | undefined,
): Promise<GraduationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.graduations.graduation.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.del(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (graduations) {
    const newGraduations = graduations.filter((object) => {
      return object.uuid !== uuid;
    });
    return newGraduations;
  }
  return [];
};
