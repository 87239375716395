import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import { ReduxDispatch } from "models/ReduxProps";
import * as ActionUtility from "utilities/ActionUtility";
import * as CastProjectsEffect from "./CastProjectsEffect";

import CastRoleOfferModel from "models/api-models/cast-role-offer/CastRoleOfferModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import ISimpleProjectModel from "selectors/projects/models/ISimpleProjectModel";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | RoleInvitationModel
  | CastRoleOfferModel[]
  | RoleInvitationModel[]
  | ISimpleProjectModel[]
  | RoleApplicationModel[];

export const REQUEST_CAST_ROLE_INVITATIONS: string = "CastProjectsAction.REQUEST_CAST_ROLE_INVITATIONS";
export const REQUEST_CAST_ROLE_INVITATIONS_FINISHED: string =
  "CastProjectsAction.REQUEST_CAST_ROLE_INVITATIONS_FINISHED";

export const requestCastRoleInvitations = (cast_slug: string, role_uuid?: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_ROLE_INVITATIONS,
      CastProjectsEffect.requestCastRoleInvitations,
      cast_slug,
      role_uuid,
    );
  };
};

export const CLEAR_REQUEST_CAST_ROLE_INVITATIONS: string = "CastProjectsAction.CLEAR_REQUEST_CAST_ROLE_INVITATIONS";

export const clearRequestCastRoleInvitations = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CAST_ROLE_INVITATIONS);
};

export const SET_SELECTED_ROLE: string = "CastProjectsAction.SET_SELECTED_ROLE";

export const setSelectedRole = (role_uuid: string | undefined): IAction<string | undefined> => {
  return ActionUtility.createAction(SET_SELECTED_ROLE, role_uuid);
};

export const REQUEST_CAST_ROLE_APPLICATIONS: string = "CastProjectsAction.REQUEST_CAST_ROLE_APPLICATIONS";
export const REQUEST_CAST_ROLE_APPLICATIONS_FINISHED: string =
  "CastProjectsAction.REQUEST_CAST_ROLE_APPLICATIONS_FINISHED";

export const requestCastRoleApplications = (cast_slug: string, role_uuid?: string, role_status?: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_ROLE_APPLICATIONS,
      CastProjectsEffect.requestCastRoleApplications,
      cast_slug,
      role_uuid,
      role_status,
    );
  };
};

export const CLEAR_REQUEST_CAST_ROLE_APPLICATIONS: string = "CastProjectsAction.CLEAR_REQUEST_CAST_ROLE_APPLICATIONS";

export const clearRequestCastRoleApplications = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CAST_ROLE_APPLICATIONS);
};

export const REQUEST_CAST_ROLE_OFFERS: string = "CastProjectsAction.REQUEST_CAST_ROLE_OFFERS";
export const REQUEST_CAST_ROLE_OFFERS_FINISHED: string = "CastProjectsAction.REQUEST_CAST_ROLE_OFFERS_FINISHED";

export const requestCastRoleOffers = (cast_slug: string, role_uuid?: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CastRoleOfferModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_ROLE_OFFERS,
      CastProjectsEffect.requestCastRoleOffers,
      cast_slug,
      role_uuid,
    );
  };
};

export const CLEAR_REQUEST_CAST_ROLE_OFFERS: string = "CastProjectsAction.CLEAR_REQUEST_CAST_ROLE_OFFERS";

export const clearRequestCastRoleOffers = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CAST_ROLE_OFFERS);
};

export const REQUEST_CAST_PROJECTS: string = "CastProjectsAction.REQUEST_CAST_PROJECTS";
export const REQUEST_CAST_PROJECTS_FINISHED: string = "CastProjectsAction.REQUEST_CAST_PROJECTS_FINISHED";

export const requestCastProjects = (cast_slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ISimpleProjectModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_PROJECTS,
      CastProjectsEffect.requestCastProjects,
      cast_slug,
    );
  };
};

export const CLEAR_PROJECTS: string = "CastProjectsAction.CLEAR_PROJECTS";

export const clearProjects = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_PROJECTS);
};
