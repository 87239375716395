import { BaseModel } from "sjs-base-model";
import SkillModel from "stores/actors/skills/models/SkillModel";
import ImageModel from "../ImageModel";
import ActorProfileModel from "./ActorProfileModel";
import ActorEvaluationModel from "./models/ActorEvaluationModel";
import ActorReferenceModel from "./models/ActorReferenceModel";
import ActorVideoModel from "./models/ActorVideo";
import ActorChildModel from "./models/actor-child/ActorChildModel";

export default class ActorModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly slug: string = "";
  public readonly display_name: string = "";
  public readonly full_name: string = "";
  public readonly photos: ImageModel[] = [ImageModel as any];
  public readonly videos: ActorVideoModel[] = [ActorVideoModel as any];

  public readonly created: string = "";
  public readonly modified: string = "";

  public readonly is_removed: boolean = false;
  public readonly experience_commercials: string = "";
  public readonly experience_movie: string = "";
  public readonly experience_series: string = "";
  public readonly experience_short_movie: string = "";
  public readonly experience_theatre: string = "";
  public readonly experience_clips: string = "";
  public readonly experience_promotion: string = "";
  public readonly experience_fashion_catalog: string = "";
  public readonly professional_trainings: string = "";
  public readonly experience_is_empty: boolean = false;
  public readonly acting_level: number | null = null;
  public readonly beard_length: string | null = null;
  public readonly beard_shave_flexibility: number | null = null;
  public readonly description: string = "";
  public readonly hobbies: string = "";
  public readonly obliged_scarf: boolean = false;
  public readonly has_color_lens: boolean = false;
  public readonly has_eyeglasses: boolean = false;

  public readonly has_beard: boolean = false;
  public readonly has_mustache: boolean = false;
  public readonly has_tattoo: boolean = false;
  public readonly tattoo_locations: string = "";
  public readonly children: ActorChildModel[] = [ActorChildModel as any];

  public readonly days_available: Number[] = [Number as any];
  public readonly has_agency_contract: boolean | null = null;
  public readonly has_day_job: boolean | null = null;

  public readonly height: number | null = null;
  public readonly weight: number | null = null;
  public readonly top_size_letter: string = "";
  public readonly top_size_number: number | null = null;

  public readonly pants_size_letter: string | null = null;
  public readonly pants_size_number: number | null = null;
  public readonly suit_size_number: number | null = null;
  public readonly shoe_size: number | null = null;
  public readonly chest_size: number | null = null;
  public readonly waist_size: number | null = null;
  public readonly hips_size: number | null = null;
  public readonly references: ActorReferenceModel[] = [ActorReferenceModel as any];
  public readonly skills: SkillModel[] = [SkillModel as any];
  public readonly body_type: string = "";
  public readonly eye_sight: string = "";
  public readonly eye_shape: string = "";
  public readonly eye_color: string = "";
  public readonly skin_color: string = "";
  public readonly hair_color: string = "";
  public readonly hair_length: string = "";
  public readonly hair_type: string = "";

  public readonly body_data_is_empty: boolean = false;

  public readonly evaluations: ActorEvaluationModel[] = [ActorEvaluationModel as any];

  public readonly profile: ActorProfileModel = ActorProfileModel as any;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<ActorModel>) {
    super();

    this.update(data);

    const isAllExperienceEmpty =
      data.experience_commercials === "" &&
      data.experience_movie === "" &&
      data.experience_series === "" &&
      data.experience_short_movie === "" &&
      data.experience_theatre === "" &&
      data.experience_clips === "" &&
      data.experience_promotion === "" &&
      data.experience_fashion_catalog === "";

    this.experience_is_empty = isAllExperienceEmpty;

    const isAllBodyDataEmpty =
      data.height === null &&
      data.weight === null &&
      data.top_size_letter === "" &&
      data.top_size_number === null &&
      data.pants_size_letter === null &&
      data.pants_size_number === null &&
      data.suit_size_number === null &&
      data.shoe_size === null &&
      data.chest_size === null &&
      data.waist_size === null &&
      data.hips_size === null &&
      data.beard_length === null &&
      data.beard_shave_flexibility === null &&
      data.hair_color === "" &&
      data.hair_length === "" &&
      data.hair_type === "" &&
      data.eye_color === "" &&
      data.skin_color === "";

    // Belirttiğiniz özelliği ayarla
    this.body_data_is_empty = isAllBodyDataEmpty;

    //this.beard_length = data.beard_length === "" ? null : ( data.beard_length === undefined ? null : data.beard_length);
  }
}
