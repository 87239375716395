import { BaseModel } from "sjs-base-model";

export default class GraduationModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly level: string = "";
  public readonly school: string = "";
  public readonly department: string = "";
  public readonly start_year: number = 0;
  public readonly end_year: number = 0;
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<GraduationModel>) {
    super();

    this.update(data);
  }
}
