import { Reducer } from "redux";
import IAction from "../../models/IAction";
import baseReducer from "../../utilities/BaseReducer";
import * as ToastsAction from "./ToastsAction";
import IToast from "./models/IToast";
import IToastsState from "./models/IToastsState";

export const initialState: IToastsState = {
  items: [],
};

const toastsReducer: Reducer = baseReducer(initialState, {
  [ToastsAction.ADD_TOAST](state: IToastsState, action: IAction<IToast>): IToastsState {
    return {
      ...state,
      items: [...state.items, action.payload!],
    };
  },

  [ToastsAction.REMOVE_TOAST](state: IToastsState, action: IAction<string>): IToastsState {
    const toastId: string = action.payload!;

    return {
      ...state,
      items: state.items.filter((model: IToast) => model.id !== toastId),
    };
  },
});

export default toastsReducer;
