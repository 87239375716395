import { AxiosRequestConfig, AxiosResponse } from "axios";

import CookieEnum from "constants/CookieEnum";
import environment from "environment";
import { SelectErrors } from "helper/ResponseErrorHelper";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IPagination from "models/IPagination";
import NotificationModel from "models/api-models/NotificationModel";
import IUserCookieModel from "models/common-models/IUserCookieModel";
import { getCookie, removeCookieData, storeDataToCookie } from "utilities/CookieUtility";
import * as HttpUtility from "utilities/HttpUtility";
import { getLastLoggedUser, storeLastLoggedUser } from "utilities/UserProfileRoleUtility";

export const requestLogin = async (email: string, password: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.login;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, { email, password });

  if (response instanceof HttpErrorResponseModel) {
    response.message = SelectErrors(response);
    return response;
  }

  return response.data.key;
};

export const loginGoogle = async (code: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.google;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, { access_token: code });

  if (response instanceof HttpErrorResponseModel) {
    try {
      response.message = response.raw.data["detail"];
    } catch {}
    return response;
  }

  return response.data.token;
};

export const registerGoogle = async (code: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.google;
  const invite_code = getCookie(CookieEnum.InviteCode);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, {
    access_token: code,
    invite_code: invite_code,
  });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.token;
};

export const requestCurrentUser = async (token: string): Promise<IUserCookieModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.users.me;
  const config: AxiosRequestConfig | undefined = {
    headers: { Authorization: `Token ${token}` },
  };

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint, undefined, config);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const userInfo: IUserCookieModel = new IUserCookieModel({
    uuid: response.data.uuid,
    id: response.data.id,
    username: response.data.username,
    url: response.data.url,
    groups: response.data.groups,
    language: response.data.language,
    full_name: response.data.profile.full_name,
    first_name: response.data.profile.first_name,
    last_name: response.data.profile.last_name,
    avatar: response.data.profile.avatar,
    actor_slug: response.data.profile.actor_slug,
    companies: response.data.profile.companies,
    is_pending: response.data.profile.is_pending,
    actor_uuid: response.data.profile.actor_uuid,
    actor_approved: response.data.profile.actor_approved,
  });

  if (userInfo.username !== getLastLoggedUser()) {
    removeCookieData(CookieEnum.SelectedProfile);
  }

  storeDataToCookie(CookieEnum.User, userInfo);
  storeLastLoggedUser(userInfo.username);

  return userInfo;
};

export const setSelectedProfile = async (profile: string): Promise<string | undefined> => {
  if (profile) {
    storeDataToCookie(CookieEnum.SelectedProfile, profile);
  }

  return profile;
};

export const removeSelectedProfile = async (): Promise<string | undefined> => {
  removeCookieData(CookieEnum.SelectedProfile);

  return undefined;
};

export const requestInviteMember = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.invites;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    try {
      response.message = response.raw.data[0];
    } catch {}
    return response;
  }

  return "success";
};

export const requestGetInviteCode = async (code: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.invites.concat(code);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  storeDataToCookie(CookieEnum.InviteCode, code);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.email;
};

export const requestUpdateLanguage = async (
  language: string,
  user: IUserCookieModel | null,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.users.update_language;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, { language });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  storeDataToCookie(CookieEnum.Lang, language);
  if (user) {
    const newUser: IUserCookieModel = new IUserCookieModel({
      ...user,
      language,
    });
    storeDataToCookie(CookieEnum.User, newUser);
  }

  return response.data.language;
};

export const requestNotifications = async (
  page?: number,
): Promise<IPagination<NotificationModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.notifications.index;

  if (page) {
    endpoint = endpoint.concat(`?page=${page}`);
  }

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const result: IPagination<NotificationModel[]> = {
    currentPage: page || 1,
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<NotificationModel>) => new NotificationModel(json)),
  };
  return result;
};

export const requestSeenNotification = async (id: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.notifications.seen.replace(":uuid", id);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return "success";
};

export const requestSeenAllNotification = async (): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.notifications.seen_all;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return "success";
};

export const requestUnseenNotifications = async (): Promise<
  IPagination<NotificationModel[]> | HttpErrorResponseModel
> => {
  let endpoint: string = environment.api.notifications.index;
  endpoint = endpoint.concat("?seen=false");

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const result: IPagination<NotificationModel[]> = {
    currentPage: 1,
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<NotificationModel>) => new NotificationModel(json)),
  };

  return result;
};
