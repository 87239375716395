import { AxiosResponse } from "axios";

import environment from "environment";
import IPagination from "models/IPagination";
import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import CommercialProjectModel from "./models/CommercialProjectModel";

export const requestCommercialProjects = async (
  search: string,
  page?: number,
): Promise<IPagination<CommercialProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.commercials.concat("?search=", search);
  if (page) {
    endpoint = endpoint.concat("&page=", page.toString());
  }
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint, undefined);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  let result: IPagination<CommercialProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    currentPage: page,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<CommercialProjectModel>) => new CommercialProjectModel(json)),
  };
  return result;
};

export const requestCommercialProject = async (
  slug: string,
): Promise<CommercialProjectModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.commercials + slug + "/";
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint, undefined);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new CommercialProjectModel(response.data);
};

export const requestCreateCommercialProject = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.commercials;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }
  return response.data.slug;
};

export const requestUpdateCommercialProject = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.updateCommercial.replace(":slug", slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data.slug;
};

export const requestCommercialsProjectsFilter = async (
  filters: string,
): Promise<IPagination<CommercialProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.commercials + `${filters}`;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  let result: IPagination<CommercialProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<CommercialProjectModel>) => new CommercialProjectModel(json)),
  };

  return result;
};

export const requestUpdateCommercialProjectStatus = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.updateCommercial.replace(":slug", slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data.slug;
};
