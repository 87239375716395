import LightRoleModel from "models/api-models/LightRoleModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleModel from "stores/projects/roles/models/RoleModel";

export default interface IRolePayments {
  readonly applications: RoleApplicationModel[];
  readonly applicationDetail: RoleApplicationModel | undefined;
  readonly showPaymentModal: boolean;
  readonly roleDetail: RoleModel | undefined;
  readonly requestPayToActor: string | undefined;
  readonly requestPayToCast: string | undefined;
  readonly roles: LightRoleModel[];
}

export const initialState: IRolePayments = {
  applications: [],
  applicationDetail: undefined,
  showPaymentModal: false,
  roleDetail: undefined,
  requestPayToActor: undefined,
  requestPayToCast: undefined,
  roles: [],
};
