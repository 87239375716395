import { BaseModel } from "sjs-base-model";
import ICompanyModel from "./ICompanyModel";

export default class ICompaniesModel extends BaseModel {
  public readonly cast_agency: ICompanyModel | null = null;
  public readonly production_company: ICompanyModel | null = null;
  public readonly client: ICompanyModel | null = null;
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<ICompaniesModel>) {
    super();

    this.update(data);
  }
}
