import { BaseModel } from "sjs-base-model";
import ActorTagModel from "./ActorTagModel";

export default class ActorEvaluationModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly created: string = "";
  public readonly modified: string = "";
  public readonly tags: ActorTagModel[] = [ActorTagModel as any];
  public readonly score: number = 0;
  public readonly created_by: string = "";
  public readonly actor: string = "";

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<ActorEvaluationModel>) {
    super();

    this.update(data);
  }
}
