import CookieEnum from "constants/CookieEnum";
import IUserCookieModel from "models/common-models/IUserCookieModel";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

interface CookieAttributes {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: "strict" | "lax" | "none";
}

const cookieOptions: CookieAttributes = {
  path: "/",
  maxAge: 604800,
  sameSite: "strict",
};

const _setCookie = (key: string, value: IUserCookieModel | string): void => {
  cookies.set(key, value, cookieOptions);
};

const _getCookie = (key: string) => {
  return cookies.get(key);
};

export const getCookie = (key: string) => {
  return _getCookie(key);
};

export const removeCookieData = (key: string): boolean => {
  if (key) {
    cookies.remove(key, cookieOptions);
    return true;
  }
  return false;
};

export const storeDataToCookie = (key: string, data: Object | string | null): boolean => {
  if (data && key) {
    if (typeof data === "object") {
      _setCookie(key, JSON.stringify(data));
    } else {
      _setCookie(key, data);
    }
    return true;
  }
  return false;
};

export const getCurrentCookieUser = (): IUserCookieModel | null => {
  let data = _getCookie(CookieEnum.User);

  try {
    if (data === undefined) {
      return null;
    }
    if (data instanceof Object) {
      return new IUserCookieModel(data);
    } else {
      return null;
    }
  } catch (e: any) {
    return null;
  }
};

export const getCurrentCookieToken = (): string | null => {
  let data = _getCookie(CookieEnum.Token);
  return data;
};

export const getCurrentCookieUserProp = (prop: string): string | null => {
  let data = _getCookie(CookieEnum.User);
  try {
    if (data instanceof Object) {
      return data[prop];
    } else {
      return null;
    }
  } catch (e: any) {
    return null;
  }
};

export const getCookieLang = (): string | null => {
  return _getCookie(CookieEnum.Lang);
};

export const getCastelectReactAppVersion = (): string | null => {
  return _getCookie(CookieEnum.CastelectReactAppVersion);
};

export const clearAllApplication = (): void => {
  cookies.remove(CookieEnum.User, cookieOptions);
  cookies.remove(CookieEnum.Token, cookieOptions);
  cookies.remove(CookieEnum.Lang, cookieOptions);
  cookies.remove(CookieEnum.SelectedProfile, cookieOptions);
  cookies.remove(CookieEnum.LastLoggedUser, cookieOptions);
  cookies.remove(CookieEnum.InviteCode, cookieOptions);
  cookies.remove(CookieEnum.CastelectReactAppVersion, cookieOptions);
  // Session storage'ı temizleme
  sessionStorage.clear();
  // Local storage'ı temizleme
  localStorage.clear();

  console.log("Tüm uygulama verileri temizlendi.");
};
