import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import SkillModel from "./models/SkillModel";

import { AxiosResponse } from "axios";

export const requestActorSkills = async (): Promise<SkillModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.skills;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.results.map((json: SkillModel) => new SkillModel(json));
};
