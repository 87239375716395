import { BaseModel } from "sjs-base-model";

export default class GeneralBudgetAndCashCastModel extends BaseModel {
  public readonly cast_agency_id: number = 0;
  public readonly total_earn: number = 0;
  public readonly earning_actor: number = 0;
  public readonly earning_of_actor: number = 0;

  constructor(data: Partial<GeneralBudgetAndCashCastModel>) {
    super();

    this.update(data);
  }
}
