import { Fragment } from "react";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import * as AuthAction from "stores/auth/AuthAction";

interface IProps {
  readonly role: string;
  readonly profileType: string;
  readonly title: string;
  readonly image: string | null;
  readonly imageNull: string;
  readonly isProfile: boolean;
  readonly selectedProfile: string | undefined;
  readonly redirectToPath: string | undefined;
  readonly changeProfile: (role: string) => void;
}

const ProfileAccount: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const dispatch: Dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { role, profileType, title, image, imageNull, changeProfile, selectedProfile, redirectToPath } = props;

  const handleButton = (event: any) => {
    event.preventDefault();
    changeProfile(role);
  };

  const redirectTo = (event: any) => {
    event.preventDefault();
    if (redirectToPath) {
      dispatch(AuthAction.closeProfileSwitchModal());

      history.push(redirectToPath);
    }
  };

  return (
    <Fragment>
      <div className="cs-profile-account mb-3">
        <p className="pb-2" style={{ textAlign: "center" }}>
          <strong>{t(`components.switch-profile-modal.user-type.${profileType}`)}</strong>
        </p>
        <div className="cs-profile-img d-flex justify-content-center position-relative">
          <i className="cs-profile-detail icofont icofont-eye-alt" onClick={redirectTo}></i>
          <Link to={"#"} onClick={handleButton} className={role === selectedProfile ? "active" : ""}>
            <img src={image || imageNull} style={{ height: "100px", width: "100px" }} alt="author" />
          </Link>
        </div>
        <div className="d-flex justify-content-center">
          <span className="text-center">{title}</span>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileAccount;
