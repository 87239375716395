import { MeetingEvaluationLink } from "interfaces/MeetingEvaluationLink";
import IAction from "models/IAction";
import EvaluationCriterionModel from "models/api-models/EvaluationCriterionModel";
import RoleApplicationEvaluationModel from "models/api-models/RoleApplicationEvaluationModel";
import SimpleAuditionVideoModel from "models/api-models/audition-video/SimpleAuditionModel";
import { Reducer } from "redux";
import ActorModel from "stores/actors/models/actors/ActorModel";
import baseReducer from "utilities/BaseReducer";
import * as PagesAction from "./PagesAction";
import IPagesState from "./models/IPagesState";

export const initialState: IPagesState = {};

const pagesReducer: Reducer = baseReducer(initialState, {
  [PagesAction.REQUEST_SEND_CONTACT_MESSAGE_FINISHED](state: IPagesState, action: IAction<string>): IPagesState {
    return {
      ...state,
      requestContactMessage: action.payload!,
    };
  },

  [PagesAction.CLEAR_REQUEST_SEND_CONTACT_MESSAGE](state: IPagesState): IPagesState {
    return {
      ...state,
      requestContactMessage: undefined,
    };
  },

  [PagesAction.REQUEST_MEETING_EVALUATION_LINK_FINISHED](
    state: IPagesState,
    action: IAction<MeetingEvaluationLink>,
  ): IPagesState {
    return {
      ...state,
      requestMeetingEvaluationLink: action.payload!,
    };
  },

  [PagesAction.REQUEST_MEETING_EVALUATION_ACTORS_FINISHED](
    state: IPagesState,
    action: IAction<ActorModel[]>,
  ): IPagesState {
    return {
      ...state,
      requestMeetingEvaluationActors: action.payload!,
    };
  },

  [PagesAction.SET_MEETING_EVALUATION_SELECTED_ACTOR](
    state: IPagesState,
    action: IAction<ActorModel | undefined>,
  ): IPagesState {
    return {
      ...state,
      selectedActor: action.payload!,
    };
  },

  [PagesAction.REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA_FINISHED](
    state: IPagesState,
    action: IAction<EvaluationCriterionModel[]>,
  ): IPagesState {
    return {
      ...state,
      requestRoleApplicationEvaluationCriteria: action.payload!,
    };
  },

  [PagesAction.REQUEST_CREATE_ROLE_APPLICATION_EVALUATION_FINISHED](
    state: IPagesState,
    action: IAction<RoleApplicationEvaluationModel>,
  ): IPagesState {
    return {
      ...state,
      responseRoleApplicationEvaluation: action.payload!,
    };
  },

  [PagesAction.CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION](state: IPagesState): IPagesState {
    return {
      ...state,
      responseRoleApplicationEvaluation: undefined,
    };
  },

  [PagesAction.REQUEST_APPLICATION_ACTOR_AUDITIONS_FINISHED](
    state: IPagesState,
    action: IAction<SimpleAuditionVideoModel[]>,
  ): IPagesState {
    return {
      ...state,
      roleApplicationActorAuditions: action.payload!,
    };
  },

  [PagesAction.CLEAR_APPLICATION_ACTOR_AUDITIONS](state: IPagesState): IPagesState {
    return {
      ...state,
      roleApplicationActorAuditions: undefined,
    };
  },
});

export default pagesReducer;
