import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import { ReduxDispatch } from "models/ReduxProps";
import MovieProjectModel from "./models/MovieProjectModel";
import * as MoviesProjectsEffect from "../movies/MoviesProjectsEffect";
import IStore from "models/IStore";
import IPagination from "models/IPagination";
import IAction from "../../../models/IAction";

type ActionUnion = undefined | HttpErrorResponseModel | IPagination<MovieProjectModel[]> | MovieProjectModel | string;

export const REQUEST_MOVIE_PROJECTS: string = "MoviesProjectsAction.REQUEST_MOVIE_PROJECTS";
export const REQUEST_MOVIE_PROJECTS_FINISHED: string = "MoviesProjectsAction.REQUEST_MOVIE_PROJECTS_FINISHED";

export const requestMovieProjects = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<MovieProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_MOVIE_PROJECTS,
      MoviesProjectsEffect.requestMovieProjects,
      search,
      page,
    );
  };
};

export const REQUEST_MOVIE_PROJECT_DETAIL: string = "MoviesProjectsAction.REQUEST_MOVIE_PROJECT_DETAIL";
export const REQUEST_MOVIE_PROJECT_DETAIL_FINISHED: string =
  "MoviesProjectsAction.REQUEST_MOVIE_PROJECT_DETAIL_FINISHED";

export const requestMovieProject = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<MovieProjectModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_MOVIE_PROJECT_DETAIL,
      MoviesProjectsEffect.requestMovieProject,
      slug,
    );
  };
};

export const REQUEST_CREATE_MOVIES_PROJECT: string = "MoviesProjectsAction.REQUEST_CREATE_MOVIES_PROJECT";
export const REQUEST_CREATE_MOVIES_PROJECT_FINISHED: string =
  "MoviesProjectsAction.REQUEST_CREATE_MOVIES_PROJECT_FINISHED";

export const requestCreateMoviesProject = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_MOVIES_PROJECT,
      MoviesProjectsEffect.requestCreateMoviesProject,
      formData,
    );
  };
};

export const REQUEST_UPDATE_MOVIES_PROJECT: string = "MoviesProjectsAction.REQUEST_UPDATE_MOVIES_PROJECT";
export const REQUEST_UPDATE_MOVIES_PROJECT_FINISHED: string =
  "MoviesProjectsAction.REQUEST_UPDATE_MOVIES_PROJECT_FINISHED";

export const requestUpdateMoviesProject = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_MOVIES_PROJECT,
      MoviesProjectsEffect.requestUpdateMoviesProject,
      formData,
      slug,
    );
  };
};

export const FILTER_MOVIES_PROJECTS_SHOW: string = "MoviesProjectsAction.FILTER_MOVIES_PROJECTS_SHOW";

export const filterProjectsShow = (filter: boolean): IAction<boolean> => {
  return ActionUtility.createAction(FILTER_MOVIES_PROJECTS_SHOW, filter);
};

export const REQUEST_MOVIES_PROJECTS_FILTER: string = "ProjectsAction.REQUEST_MOVIES_PROJECTS_FILTER";
export const REQUEST_MOVIES_PROJECTS_FILTER_FINISHED: string = "ProjectsAction.REQUEST_MOVIES_PROJECTS_FILTER_FINISHED";

export const requestProjectsFilter = (filters: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<MovieProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_MOVIES_PROJECTS_FILTER,
      MoviesProjectsEffect.requestMoviesProjectsFilter,
      filters,
    );
  };
};

export const REQUEST_UPDATE_MOVIES_PROJECT_STATUS: string = "MoviesProjectsAction.REQUEST_UPDATE_MOVIES_PROJECT_STATUS";
export const REQUEST_UPDATE_MOVIES_PROJECT_STATUS_FINISHED: string =
  "MoviesProjectsAction.REQUEST_UPDATE_MOVIES_PROJECT_STATUS_FINISHED";

export const requestUpdateMoviesProjectStatus = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_MOVIES_PROJECT_STATUS,
      MoviesProjectsEffect.requestUpdateMoviesProjectStatus,
      formData,
      slug,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_MOVIES_PROJECT_STATUS: string =
  "MoviesProjectsAction.CLEAR_REQUEST_UPDATE_MOVIES_PROJECT_STATUS";

export const clearRequestUpdateMoviesProjectStatus = (): IAction<any> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_MOVIES_PROJECT_STATUS);
};

export const REQUEST_FIND_MOVIE_PROJECT_DETAIL: string = "MoviesProjectsAction.REQUEST_FIND_MOVIE_PROJECT_DETAIL";
export const REQUEST_FIND_MOVIE_PROJECT_DETAIL_FINISHED: string =
  "MoviesProjectsAction.REQUEST_FIND_MOVIE_PROJECT_DETAIL_FINISHED";

export const requestFindMovieProject = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<MovieProjectModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_FIND_MOVIE_PROJECT_DETAIL,
      MoviesProjectsEffect.requestMovieProject,
      slug,
    );
  };
};
