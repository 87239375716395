import { BaseModel } from "sjs-base-model";

export default class LightRoleModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly name: string = "";
  public readonly status: string = "";

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<LightRoleModel>) {
    super();

    this.update(data);
  }
}
