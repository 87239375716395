import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";

export default interface IActorRoleInvitation {
  readonly invitationDetail: RoleInvitationModel | undefined;
  readonly requestAcceptRoleInvitation: string | undefined;
  readonly requestDeclineRoleInvitation: string | undefined;

  readonly roleInvitations: RoleInvitationModel[];
  readonly roleApplications: RoleApplicationModel[];
}

export const initialState: IActorRoleInvitation = {
  invitationDetail: undefined,
  requestAcceptRoleInvitation: undefined,
  requestDeclineRoleInvitation: undefined,

  roleInvitations: [],
  roleApplications: [],
};
