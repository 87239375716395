import IAction from "models/IAction";
import { ReduxDispatch } from "models/ReduxProps";
import * as LanguageEffect from "./LanguageEffect";
import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import LanguageModel from "../models/language-model/LanguageModel";
import IStore from "models/IStore";

type ActionUnion = undefined | HttpErrorResponseModel | string | LanguageModel | LanguageModel[];

export const REQUEST_CREATE_LANGUAGE: string = "LanguageAction.REQUEST_CREATE_LANGUAGE";
export const REQUEST_CREATE_LANGUAGE_FINISHED: string = "LanguageAction.REQUEST_CREATE_LANGUAGE_FINISHED";

export const requestCreateLanguage = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<LanguageModel>(
      dispatch,
      REQUEST_CREATE_LANGUAGE,
      LanguageEffect.requestCreateLanguage,
      formData,
    );
  };
};

export const CLEAR_REQUEST_CREATE_LANGUAGE_MESSAGE: string = "LanguageAction.CLEAR_REQUEST_CREATE_LANGUAGE_MESSAGE";

export const clearRequestCreateLanguageMessage = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_LANGUAGE_MESSAGE);
};

export const REQUEST_UPDATE_LANGUAGE: string = "LanguageAction.REQUEST_UPDATE_LANGUAGE";
export const REQUEST_UPDATE_LANGUAGE_FINISHED: string = "LanguageAction.REQUEST_UPDATE_LANGUAGE_FINISHED";

export const requestUpdateLanguage = (uuid: string, formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const languages: LanguageModel[] | undefined = getState().profiles.profile?.languages;
    await ActionUtility.createThunkEffect<LanguageModel[]>(
      dispatch,
      REQUEST_UPDATE_LANGUAGE,
      LanguageEffect.requestUpdateLanguage,
      uuid,
      formData,
      languages,
    );
  };
};

// Clear request update language message
export const CLEAR_REQUEST_UPDATE_LANGUAGE_MESSAGE: string = "LanguageAction.CLEAR_REQUEST_UPDATE_LANGUAGE_MESSAGE";

export const clearRequestUpdateLanguage = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_LANGUAGE_MESSAGE);
};

// REQUEST_DELETE_LANGUAGE
export const REQUEST_DELETE_LANGUAGE: string = "LanguageAction.REQUEST_DELETE_LANGUAGE";
export const REQUEST_DELETE_LANGUAGE_FINISHED: string = "LanguageAction.REQUEST_DELETE_LANGUAGE_FINISHED";

export const requestDeleteLanguage = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const languages: LanguageModel[] | undefined = getState().profiles.profile?.languages;
    await ActionUtility.createThunkEffect<LanguageModel[]>(
      dispatch,
      REQUEST_DELETE_LANGUAGE,
      LanguageEffect.requestDeleteLanguage,
      uuid,
      languages,
    );
  };
};

//clear request delete language message
export const CLEAR_REQUEST_DELETE_LANGUAGE_MESSAGE: string = "LanguageAction.CLEAR_REQUEST_DELETE_LANGUAGE_MESSAGE";

export const clearRequestDeleteLanguage = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DELETE_LANGUAGE_MESSAGE);
};
