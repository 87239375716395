import { BaseModel } from "sjs-base-model";
import AvatarModel from "stores/profiles/models/avatar-model/AvatarModel";

export default class CastAgencyActorModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly avatar: string = "";
  public readonly display_name: string = "";
  public readonly slug: string = "";
  public readonly full_name: string = "";
  public readonly avatar_thumbnails: AvatarModel = AvatarModel as any;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<CastAgencyActorModel>) {
    super();

    this.update(data);
  }
}
