//todo change name. because project role status is exists.
enum RoleEnum {
  Actor = "actors",
  ProductionCompany = "production_company_employees",
  CastAgency = "cast_agency_employees",
  Client = "client_employees",
  Public = "public",
}

export default RoleEnum;
