import { BaseModel } from "sjs-base-model";

export default class LightCountryModel extends BaseModel {
  public readonly id: string = "";
  public readonly display_name: string = "";
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<LightCountryModel>) {
    super();

    this.update(data);
  }
}
