import { EvaluationRequest } from "interfaces";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import IStore from "models/IStore";
import { ReduxDispatch } from "models/ReduxProps";
import EvaluationCriterionModel from "models/api-models/EvaluationCriterionModel";
import MeetingLocation from "models/api-models/MeetingLocation";
import RoleApplicationEvaluationModel from "models/api-models/RoleApplicationEvaluationModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleOfferModel from "models/api-models/role-offer/RoleOfferModel";
import ActorModel from "stores/actors/models/actors/ActorModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as RoleApplicationEffect from "./RoleApplicationEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | RoleApplicationModel[]
  | RoleApplicationModel
  | ActorModel
  | MeetingLocation
  | RoleOfferModel
  | RoleApplicationEvaluationModel[]
  | RoleApplicationEvaluationModel
  | EvaluationCriterionModel[];

export const REQUEST_ROLE_APPLICATIONS: string = "RoleApplicationAction.REQUEST_ROLE_APPLICATIONS";
export const REQUEST_ROLE_APPLICATIONS_FINISHED: string = "RoleApplicationAction.REQUEST_ROLE_APPLICATIONS_FINISHED";

export const requestRoleApplications = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATIONS,
      RoleApplicationEffect.requestRoleApplications,
      uuid,
    );
  };
};

export const CLEAR_ROLE_APPLICATIONS: string = "RoleApplicationAction.CLEAR_ROLE_APPLICATIONS";

export const clearRoleApplications = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_ROLE_APPLICATIONS);
};

export const REQUEST_ACTOR: string = "RoleApplicationAction.REQUEST_ACTOR";
export const REQUEST_ACTOR_FINISHED: string = "RoleApplicationAction.REQUEST_ACTOR_FINISHED";

export const requestActor = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR,
      RoleApplicationEffect.requestActor,
      uuid,
    );
  };
};

export const CLEAR_ACTOR: string = "RoleApplicationAction.CLEAR_ACTOR";

export const clearActor = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_ACTOR);
};

export const REQUEST_PRESENT: string = "RoleApplicationAction.REQUEST_PRESENT";
export const REQUEST_PRESENT_FINISHED: string = "RoleApplicationAction.REQUEST_PRESENT_FINISHED";

export const requestPresent = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PRESENT,
      RoleApplicationEffect.requestPresent,
      uuid,
      roleApplications,
    );
  };
};

export const CLEAR_REQUEST_PRESENT: string = "RoleApplicationAction.CLEAR_REQUEST_PRESENT";

export const clearRequestPresent = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_PRESENT);
};

export const REQUEST_INVITE_TO_CALLBACK: string = "RoleApplicationAction.REQUEST_INVITE_TO_CALLBACK";
export const REQUEST_INVITE_TO_CALLBACK_FINISHED: string = "RoleApplicationAction.REQUEST_INVITE_TO_CALLBACK_FINISHED";

export const requestInviteToCallBack = (uuid: string, data: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_INVITE_TO_CALLBACK,
      RoleApplicationEffect.requestInviteToCallBack,
      uuid,
      data,
      roleApplications,
    );
  };
};

export const CLEAR_REQUEST_INVITE_TO_CALLBACK: string = "RoleApplicationAction.CLEAR_REQUEST_INVITE_TO_CALLBACK";

export const clearRequestInviteToCallBack = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_INVITE_TO_CALLBACK);
};

export const REQUEST_SHORTLIST: string = "RoleApplicationAction.REQUEST_SHORTLIST";
export const REQUEST_SHORTLIST_FINISHED: string = "RoleApplicationAction.REQUEST_SHORTLIST_FINISHED";

export const requestShortList = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;

    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SHORTLIST,
      RoleApplicationEffect.requestShortList,
      uuid,
      roleApplications,
    );
  };
};

export const CLEAR_REQUEST_SHORTLIST: string = "RoleApplicationAction.CLEAR_REQUEST_SHORTLIST";

export const clearRequestShortList = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_SHORTLIST);
};

export const REQUEST_DECLINE: string = "RoleApplicationAction.REQUEST_DECLINE";
export const REQUEST_DECLINE_FINISHED: string = "RoleApplicationAction.REQUEST_DECLINE_FINISHED";

export const requestDecline = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;

    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DECLINE,
      RoleApplicationEffect.requestDecline,
      uuid,
      roleApplications,
    );
  };
};

export const CLEAR_REQUEST_DECLINE: string = "RoleApplicationAction.CLEAR_REQUEST_DECLINE";

export const clearRequestDecline = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DECLINE);
};

export const REQUEST_MAKE_OFFER: string = "RoleApplicationAction.REQUEST_MAKE_OFFER";
export const REQUEST_MAKE_OFFER_FINISHED: string = "RoleApplicationAction.REQUEST_MAKE_OFFER_FINISHED";

export const requestMakeOffer = (uuid: string, formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_MAKE_OFFER,
      RoleApplicationEffect.requestMakeOffer,
      uuid,
      formData,
    );
  };
};

export const CLEAR_REQUEST_MAKE_OFFER: string = "RoleApplicationAction.CLEAR_REQUEST_MAKE_OFFER";

export const clearRequestMakeOffer = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_MAKE_OFFER);
};

export const SHOW_OFFER_MODAL: string = "RoleApplicationAction.SHOW_OFFER_MODAL";

export const showMakeOfferModal = (): IAction<undefined> => {
  return ActionUtility.createAction(SHOW_OFFER_MODAL);
};

export const HIDE_OFFER_MODAL: string = "RoleApplicationAction.HIDE_OFFER_MODAL";

export const hideMakeOfferModal = (): IAction<undefined> => {
  return ActionUtility.createAction(HIDE_OFFER_MODAL);
};

export const SET_MAKE_OFFER_BUTTON: string = "RoleApplicationAction.SET_MAKE_OFFER_BUTTON";

export const setMakeOfferButton = (status: boolean): IAction<boolean> => {
  return ActionUtility.createAction(SET_MAKE_OFFER_BUTTON, status);
};

export const REQUEST_WITHDRAW_OFFER: string = "RoleApplicationAction.REQUEST_WITHDRAW_OFFER";
export const REQUEST_WITHDRAW_OFFER_FINISHED: string = "RoleApplicationAction.REQUEST_WITHDRAW_OFFER_FINISHED";

export const requestWithdrawRoleOffer = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplicationDetail: RoleApplicationModel | undefined =
      getState().projects.projectRoles.roleApplication.roleApplicationDetail;

    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_WITHDRAW_OFFER,
      RoleApplicationEffect.requestWithdrawRoleOffer,
      uuid,
      roleApplicationDetail,
    );
  };
};

export const CLEAR_REQUEST_WITHDRAWN_OFFER: string = "RoleApplicationAction.CLEAR_REQUEST_WITHDRAWN_OFFER";

export const clearRequestWithdrawn = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_WITHDRAWN_OFFER);
};
/*
export const REQUEST_OPTION: string = 'RoleApplicationAction.REQUEST_OPTION';
export const REQUEST_OPTION_FINISHED: string = 'RoleApplicationAction.REQUEST_OPTION_FINISHED';

export const requestOption = (uuid: string): any => {
    return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
        const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;       
        await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>
            (dispatch, REQUEST_OPTION, RoleApplicationEffect.requestOption, uuid, roleApplications);
    }
}

export const CLEAR_REQUEST_OPTION: string = 'RoleApplicationAction.CLEAR_REQUEST_OPTION';

export const clearRequestOption = (): IAction<undefined> => {
    return ActionUtility.createAction(CLEAR_REQUEST_OPTION);
};
*/

export const REQUEST_OPTION: string = "RoleApplicationAction.REQUEST_OPTION";
export const REQUEST_OPTION_FINISHED: string = "RoleApplicationAction.REQUEST_OPTION_FINISHED";

export const requestOptionRoleOffer = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplicationDetail: RoleApplicationModel | undefined =
      getState().projects.projectRoles.roleApplication.roleApplicationDetail;

    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_OPTION,
      RoleApplicationEffect.requestOptionRoleOffer,
      uuid,
      roleApplicationDetail,
    );
  };
};

export const CLEAR_REQUEST_OPTION: string = "RoleApplicationAction.CLEAR_REQUEST_OPTION";

export const clearRequestOption = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_OPTION);
};

export const REQUEST_ROLE_APPLICATION: string = "RoleApplicationAction.REQUEST_ROLE_APPLICATION";
export const REQUEST_ROLE_APPLICATION_FINISHED: string = "RoleApplicationAction.REQUEST_ROLE_APPLICATION_FINISHED";

export const requestRoleApplication = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATION,
      RoleApplicationEffect.requestRoleApplication,
      uuid,
    );
  };
};

export const REQUEST_ELECT: string = "RoleApplicationAction.REQUEST_ELECT";
export const REQUEST_ELECT_FINISHED: string = "RoleApplicationAction.REQUEST_ELECT_FINISHED";

export const requestElectRoleOffer = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ELECT,
      RoleApplicationEffect.requestElectRoleOffer,
      uuid,
      roleApplications,
    );
  };
};

export const CLEAR_REQUEST_ELECT: string = "RoleApplicationAction.CLEAR_REQUEST_ELECT";

export const clearRequestElect = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ELECT);
};

export const REQUEST_FINALIZE: string = "RoleApplicationAction.REQUEST_FINALIZE";
export const REQUEST_FINALIZE_FINISHED: string = "RoleApplicationAction.REQUEST_FINALIZE_FINISHED";

export const requestFinalize = (uuid: string, formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_FINALIZE,
      RoleApplicationEffect.requestFinalize,
      uuid,
      formData,
      roleApplications,
    );
  };
};

export const CLEAR_REQUEST_FINALIZE: string = "RoleApplicationAction.CLEAR_REQUEST_FINALIZE";

export const clearRequestFinalize = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_FINALIZE);
};

export const SHOW_FINALIZE_MODAL: string = "RoleApplicationAction.SHOW_FINALIZE_MODAL";

export const showFinalizeModal = (application: RoleApplicationModel): IAction<RoleApplicationModel> => {
  return ActionUtility.createAction(SHOW_FINALIZE_MODAL, application);
};

export const HIDE_FINALIZE_MODAL: string = "RoleApplicationAction.HIDE_FINALIZE_MODAL";

export const hideFinalizeModal = (): IAction<undefined> => {
  return ActionUtility.createAction(HIDE_FINALIZE_MODAL);
};

export const SET_INVITE_CALLBACK_MODAL_STATUS: string = "RoleApplicationAction.SET_INVITE_CALLBACK_MODAL_STATUS";

export const setInviteCallbackModalStatus = (application_uuid: string | undefined): IAction<string | undefined> => {
  return ActionUtility.createAction(SET_INVITE_CALLBACK_MODAL_STATUS, application_uuid);
};

export const SET_MEETING_LOCATION_EDIT_MODAL_STATUS: string =
  "RoleApplicationAction.SET_MEETING_LOCATION_EDIT_MODAL_STATUS";

export const setMeetingLocationEditModal = (uuid: string | undefined): IAction<string | undefined> => {
  return ActionUtility.createAction(SET_MEETING_LOCATION_EDIT_MODAL_STATUS, uuid);
};

export const REQUEST_MEETING_LOCATION: string = "RoleApplicationAction.REQUEST_MEETING_LOCATION";
export const REQUEST_MEETING_LOCATION_FINISHED: string = "RoleApplicationAction.REQUEST_MEETING_LOCATION_FINISHED";

export const requestUpdateMeetingLocation = (uuid: string, data: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleApplications: RoleApplicationModel[] = getState().projects.projectRoles.roleApplication.roleApplications;
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_MEETING_LOCATION,
      RoleApplicationEffect.requestUpdateMeetingLocation,
      uuid,
      data,
      roleApplications,
    );
  };
};

export const CLEAR_REQUEST_MEETING_LOCATION: string = "RoleApplicationAction.CLEAR_REQUEST_MEETING_LOCATION";

export const clearRequestUpdateMeetingLocation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_MEETING_LOCATION);
};

export const REQUEST_ACTOR_MEETING_LOCATION: string = "RoleApplicationAction.REQUEST_ACTOR_MEETING_LOCATION";
export const REQUEST_ACTOR_MEETING_LOCATION_FINISHED: string =
  "RoleApplicationAction.REQUEST_ACTOR_MEETING_LOCATION_FINISHED";

export const requestActorMeetingLocation = (role_uuid: string, actor_uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<MeetingLocation | undefined | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_MEETING_LOCATION,
      RoleApplicationEffect.requestActorMeetingLocation,
      role_uuid,
      actor_uuid,
    );
  };
};

export const CLEAR_ACTOR_MEETING_LOCATION: string = "RoleApplicationAction.CLEAR_ACTOR_MEETING_LOCATION";

export const clearActorMeetingLocation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_ACTOR_MEETING_LOCATION);
};

export const REQUEST_ROLE_APPLICATION_EVALUATIONS: string =
  "RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATIONS";
export const REQUEST_ROLE_APPLICATION_EVALUATIONS_FINISHED: string =
  "RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATIONS_FINISHED";

export const requestRoleApplicationEvaluations = (application_uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationEvaluationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATION_EVALUATIONS,
      RoleApplicationEffect.requestRoleApplicationEvaluations,
      application_uuid,
    );
  };
};

export const CLEAR_REQUEST_ROLE_APPLICATION_EVALUATIONS: string =
  "RoleApplicationAction.CLEAR_REQUEST_ROLE_APPLICATION_EVALUATIONS";

export const clearRoleApplicationEvaluations = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ROLE_APPLICATION_EVALUATIONS);
};

export const REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA: string =
  "RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA";
export const REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA_FINISHED: string =
  "RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA_FINISHED";

export const requestRoleApplicationEvaluationCriteria = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<EvaluationCriterionModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA,
      RoleApplicationEffect.requestRoleApplicationEvaluationCriteria,
    );
  };
};

export const CLEAR_ROLE_APPLICATION_EVALUATION_CRITERIA: string =
  "RoleApplicationAction.CLEAR_ROLE_APPLICATION_EVALUATION_CRITERIA";

export const clearRoleApplicationEvaluationCriteria = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_ROLE_APPLICATION_EVALUATION_CRITERIA);
};

export const REQUEST_CREATE_ROLE_APPLICATION_EVALUATION: string =
  "RoleApplicationAction.REQUEST_CREATE_ROLE_APPLICATION_EVALUATION";
export const REQUEST_CREATE_ROLE_APPLICATION_EVALUATION_FINISHED: string =
  "RoleApplicationAction.REQUEST_CREATE_ROLE_APPLICATION_EVALUATION_FINISHED";

export const requestCreateEvaluation = (data: EvaluationRequest): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationEvaluationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ROLE_APPLICATION_EVALUATION,
      RoleApplicationEffect.requestCreateEvaluation,
      data,
    );
  };
};

export const CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION: string =
  "RoleApplicationAction.CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION";

export const clearRequestCreateEvaluation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION);
};

export const REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION: string =
  "RoleApplicationAction.REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION";
export const REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION_FINISHED: string =
  "RoleApplicationAction.REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION_FINISHED";

export const requestUpdateEvaluation = (uuid: string, data: EvaluationRequest): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION,
      RoleApplicationEffect.requestUpdateEvaluation,
      uuid,
      data,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION: string =
  "RoleApplicationAction.CLEAR_REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION";

export const clearRequestUpdateEvaluation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION);
};

export const REQUEST_ROLE_APPLICATION_EVALUATION_DETAIL: string =
  "RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATION_DETAIL";
export const REQUEST_ROLE_APPLICATION_EVALUATION_DETAIL_FINISHED: string =
  "RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATION_DETAIL_FINISHED";

export const requestRoleApplicationEvaluationDetail = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationEvaluationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATION_EVALUATION_DETAIL,
      RoleApplicationEffect.requestRoleApplicationEvaluationDetail,
      uuid,
    );
  };
};

export const CLEAR_ROLE_APPLICATION_EVALUATION_DETAIL: string =
  "RoleApplicationAction.CLEAR_ROLE_APPLICATION_EVALUATION_DETAIL";

export const clearRoleApplicationEvaluationDetail = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_ROLE_APPLICATION_EVALUATION_DETAIL);
};

export const REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK: string =
  "RoleApplicationAction.REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK";
export const REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK_FINISHED: string =
  "RoleApplicationAction.REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK_FINISHED";

export const requestCreateMeetingEvaluationLink = (role_uuid: string, isMulti: boolean): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const selectedProfile: string | undefined = getState().auth.selectedProfile;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK,
      RoleApplicationEffect.requestCreateMeetingEvaluationLink,
      role_uuid,
      selectedProfile,
      isMulti,
    );
  };
};

export const CLEAR_REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK: string =
  "RoleApplicationAction.CLEAR_REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK";

export const clearRequestCreateMeetingEvaluationLink = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK);
};
