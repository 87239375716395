import CookieEnum from "constants/CookieEnum";
import RoleEnum from "constants/RoleEnum";
import RouteEnum from "constants/RouteEnum";
import IUserCookieModel from "models/common-models/IUserCookieModel";
import { getCookie, getCurrentCookieUser, removeCookieData, storeDataToCookie } from "./CookieUtility";

//const ALLOW_URL = RegExp(`^${RouteEnum.Profile}`);

const ALLOW_URL: RegExp[] = [
  RegExp(`^${RouteEnum.Profile}`),
  RegExp(`^${RouteEnum.ProfileCreate}`),
  RegExp(`^${RouteEnum.ProfileEdit}`),
  RegExp(`^${RouteEnum.ProductionCreate}`),
  RegExp(`^${RouteEnum.CastCreate}`),
  RegExp(`^${RouteEnum.ClientCreate}`),
  RegExp(`^${RouteEnum.ActorCreate}`),
  RegExp(`^${RouteEnum.RoleInvitationAccept}`),
];

const ALLOW_URL_PRODUCTION_COMPANY_PROFILE: RegExp[] = [
  RegExp(`^${RouteEnum.ProductionEdit}`),
  RegExp(`^${RouteEnum.Production}`),
  RegExp(`^${RouteEnum.ProductionProfile}`),
  RegExp(`^${RouteEnum.ProductionProjects}`),
];

const ALLOW_URL_CAST_AGECNY_PROFILE: RegExp[] = [
  RegExp(`^${RouteEnum.Cast}`),
  RegExp(`^${RouteEnum.CastActors}`),
  RegExp(`^${RouteEnum.CastEdit}`),
  RegExp(`^${RouteEnum.CastProfile}`),
  RegExp(`^${RouteEnum.CastProjects}`),
];

const ALLOW_URL_CLIENT_PROFILE: RegExp[] = [
  RegExp(`^${RouteEnum.Client}`),
  RegExp(`^${RouteEnum.ClientEdit}`),
  RegExp(`^${RouteEnum.ClientProfile}`),
  RegExp(`^${RouteEnum.ClientProjects}`),
];

const ALLOW_URL_ACTOR_PROFILE: RegExp[] = [
  RegExp(`^${RouteEnum.Actor}`),
  RegExp(`^${RouteEnum.ActorEdit}`),
  RegExp(`^${RouteEnum.ActorProfile}`),
];

export const getSelectedUserProfile = (): string | undefined => {
  return getCookie(CookieEnum.SelectedProfile);
};

export const getLastLoggedUser = (): string | undefined => {
  return getCookie(CookieEnum.LastLoggedUser);
};

export const profileDone = (): boolean => {
  const user: IUserCookieModel | null = getCurrentCookieUser();

  if (!user) {
    return false;
  }

  if (user.is_pending === false) {
    return true;
  }

  return false;
};

export const checkUserAccount = (path: string): boolean => {
  // user profile not selected
  // path not include /profile
  //ALLOW_URL.test(path)
  if (!getSelectedUserProfile() && !checkPath(path)) {
    return false;
  }
  return true;
};

// check path is allowed from ALLOW_URL
export const checkPath = (path: string): boolean => {
  return ALLOW_URL.some((url) => url.test(path));
};

export const storeLastLoggedUser = (username: string): void => {
  if (getCookie(CookieEnum.LastLoggedUser) !== username) {
    removeCookieData(CookieEnum.SelectedProfile);
  }

  storeDataToCookie(CookieEnum.LastLoggedUser, username);
};

export const profileNotificationActive = (): boolean => {
  if (!profileDone()) {
    return false;
  }

  const user: IUserCookieModel | null = getCurrentCookieUser();

  if (
    user &&
    (user.actor_slug ||
      user.actor_uuid ||
      user.companies.cast_agency ||
      user.companies.production_company ||
      user.companies.client)
  ) {
    return true;
  }

  return false;
};

// redirect to page depends on profile status
export const unapprovedProfileRedirection = (path: string, profile: string): boolean => {
  if (profile === RoleEnum.ProductionCompany) {
    return ALLOW_URL.concat(ALLOW_URL_PRODUCTION_COMPANY_PROFILE).some((url) => url.test(path));
  } else if (profile === RoleEnum.CastAgency) {
    return ALLOW_URL.concat(ALLOW_URL_CAST_AGECNY_PROFILE).some((url) => url.test(path));
  } else if (profile === RoleEnum.Client) {
    return ALLOW_URL.concat(ALLOW_URL_CLIENT_PROFILE).some((url) => url.test(path));
  } else if (profile === RoleEnum.Actor) {
    return ALLOW_URL.concat(ALLOW_URL_ACTOR_PROFILE).some((url) => url.test(path));
  }

  return false;
};
