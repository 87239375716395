import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";
import SeriesProjectModel from "./models/SeriesProjectModel";
import { AxiosResponse } from "axios";
import IPagination from "models/IPagination";

export const requestSeriesProjects = async (
  search: string,
  page?: number,
): Promise<IPagination<SeriesProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.series.concat("?search=", search);
  if (page) {
    endpoint = endpoint.concat("&page=", page.toString());
  }
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  let result: IPagination<SeriesProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    currentPage: page,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<SeriesProjectModel>) => new SeriesProjectModel(json)),
  };

  return result;
};

export const requestFindBySlug = async (slug: string): Promise<SeriesProjectModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.series + slug + "/";
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new SeriesProjectModel(response.data);
};

export const requestCreateSeriesProject = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.series;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }
  return response.data.slug;
};

export const requestUpdateSeriesProject = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.updateSeries.replace(":slug", slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }
  return slug;
};

export const requestSeriesProjectsFilter = async (
  filters: string,
): Promise<IPagination<SeriesProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.series + `${filters}`;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  let result: IPagination<SeriesProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<SeriesProjectModel>) => new SeriesProjectModel(json)),
  };

  return result;
};

export const requestUpdateSeriesProjectStatus = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.updateSeries.replace(":slug", slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }
  return slug;
};
