import { Dispatch } from "redux";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IStore from "models/IStore";
import CookieEnum from "constants/CookieEnum";
import * as AuthAction from "stores/auth/AuthAction";
import * as LogoutAction from "stores/auth/logout/LogoutAction";
import { getSelectedUserProfile } from "utilities/UserProfileRoleUtility";
import { selectRequesting } from "selectors/requesting/RequestingSelector";
import { getCurrentCookieToken, removeCookieData } from "utilities/CookieUtility";

const DELAY = 5;
let userTimer: NodeJS.Timeout;
let selectedProfileTimer: NodeJS.Timeout;

interface IProps {}
// Check cookies then remove or set token, user and selectedProfile
const CookieControl: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const dispatch: Dispatch = useDispatch();

  const isLoginRequesting = useSelector((state: IStore) =>
    selectRequesting(state, [
      AuthAction.REQUEST_LOGIN,
      AuthAction.REQUEST_GET_CURRENT_USER,
      AuthAction.REQUEST_GET_USER_ME,
    ]),
  );
  const isLogoutRequesting = useSelector((state: IStore) => selectRequesting(state, [LogoutAction.REQUEST_LOGOUT]));
  const token = useSelector((state: IStore) => state.auth.token);

  // İf user is logged in, then do not show the cookie control
  useEffect(() => {
    // first load check and set the cookie and set timer
    if (getCurrentCookieToken()) {
      dispatch(AuthAction.setCurrentUser());
      dispatch(AuthAction.setToken());
      dispatch(AuthAction.getCurrentUser(getCurrentCookieToken()));
      startUserTimer();

      const selectedProfile: string | undefined = getSelectedUserProfile();
      if (selectedProfile) {
        dispatch(AuthAction.setSelectedProfile(selectedProfile));
        //startSelectedProfileTimer();
      }
    }

    return () => {
      clearUserTimer("componentWillUnmount");
      clearSelectedProfileTimer("componentWillUnmount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoginRequesting && token) {
      checkUserCookie();
      startUserTimer();

      const selectedProfile: string | undefined = getSelectedUserProfile();
      if (selectedProfile) {
        dispatch(AuthAction.setSelectedProfile(selectedProfile));
        startSelectedProfileTimer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginRequesting]);

  useEffect(() => {
    if (isLogoutRequesting) {
      //clearUserTimer("isLogoutRequesting");
      //clearSelectedProfileTimer("isLogoutRequesting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogoutRequesting]);

  // Check if user is logged in
  const checkUserCookie = () => {
    //if token and user is exits, then do not show the cookie control
    if (!getCurrentCookieToken()) {
      removeCookieData(CookieEnum.User);
      removeCookieData(CookieEnum.Token);
      dispatch(AuthAction.removeUser());
      dispatch(AuthAction.removeToken());
      clearUserTimer(undefined);
    }
  };

  const startSelectedProfileTimer = () => {
    clearSelectedProfileTimer("startSelectedProfileTimer is starting");
    selectedProfileTimer = setInterval(() => {
      checkSelectedProfile();
    }, DELAY * 1000);
  };

  const startUserTimer = () => {
    clearUserTimer("clear user timer for  startUserTimer is starting");
    userTimer = setInterval(() => {
      checkUserCookie();
    }, DELAY * 1000);
  };

  const clearUserTimer = (message: string | undefined) => {
    clearInterval(userTimer);
  };

  const clearSelectedProfileTimer = (message: string | undefined) => {
    clearInterval(selectedProfileTimer);
  };

  const checkSelectedProfile = () => {
    const selectedProfile: string | undefined = getSelectedUserProfile();
    if (!selectedProfile) {
      clearSelectedProfileTimer("clearSelectedProfileTimer is deleting..");
      dispatch(AuthAction.removeSelectedProfile());
    }
  };

  return null;
};

export default CookieControl;
