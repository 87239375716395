import * as ProductionsEffect from "./ProductionsEffect";
import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import { ReduxDispatch } from "models/ReduxProps";
import ProductionModel from "./models/ProductionModel";
import ProductionOptionsModel from "./models/ProductionOptionsModel";
import IPagination from "models/IPagination";
import IAction from "models/IAction";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | IPagination<ProductionModel[]>
  | ProductionModel
  | ProductionOptionsModel
  | string;

export const REQUEST_PRODUCTION_OPTIONS: string = "ProductionsAction.REQUEST_PRODUCTION_OPTIONS";
export const REQUEST_PRODUCTION_OPTIONS_FINISHED: string = "ProductionsAction.REQUEST_PRODUCTION_OPTIONS_FINISHED";

export const requestProductionOptions = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ProductionOptionsModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PRODUCTION_OPTIONS,
      ProductionsEffect.requestProductionOptions,
    );
  };
};

export const REQUEST_PRODUCTION_COMPANIES: string = "ProductionsAction.REQUEST_PRODUCTION_COMPANIES";
export const REQUEST_PRODUCTION_COMPANIES_FINISHED: string = "ProductionsAction.REQUEST_PRODUCTION_COMPANIES_FINISHED";
//SEARCH PRODUCTION COMPANIES WITH SEARCH AND PAGE
export const requestProductionCompanies = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<ProductionModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PRODUCTION_COMPANIES,
      ProductionsEffect.requestProductionCompanies,
      search,
      page,
    );
  };
};

export const REQUEST_PRODUCTION_COMPANY: string = "ProductionsAction.REQUEST_PRODUCTION_COMPANY";
export const REQUEST_PRODUCTION_COMPANY_FINISHED: string = "ProductionsAction.REQUEST_PRODUCTION_COMPANY_FINISHED";
//REQUEST PRODUCTION COMPANY
export const requestProductionCompany = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ProductionModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PRODUCTION_COMPANY,
      ProductionsEffect.requestProductionCompany,
      slug,
    );
  };
};

export const CLEAR_PRODUCTION_COMPANY_DETAIL: string = "ProductionsAction.CLEAR_PRODUCTION_COMPANY_DETAIL";
//SET UNDEFINED CAST DETAIL
export const clearProductionCompanyDetail = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_PRODUCTION_COMPANY_DETAIL);
};

export const REQUEST_UPDATE_PRODUCTION_COMPANY: string = "ProductionsAction.REQUEST_UPDATE_PRODUCTION_COMPANY";
export const REQUEST_UPDATE_PRODUCTION_COMPANY_FINISHED: string =
  "ProductionsAction.REQUEST_UPDATE_PRODUCTION_COMPANY_FINISHED";
//REQUWST UPDATE PRODUCTION COMPANY
export const requestUpdateProductionCompany = (formData: any, slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_PRODUCTION_COMPANY,
      ProductionsEffect.requestUpdateProductionCompany,
      formData,
      slug,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_PRODUCTION_COMPANY: string =
  "ProductionsAction.CLEAR_REQUEST_UPDATE_PRODUCTION_COMPANY";
//SET UNDEFINED REQUEST UPDATE CAST AGENCY
export const clearRequestUpdateProductionCompany = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_PRODUCTION_COMPANY);
};

export const REQUEST_CREATE_PRODUCTION_COMPANY: string = "ProductionsAction.REQUEST_CREATE_PRODUCTION_COMPANY";
export const REQUEST_CREATE_PRODUCTION_COMPANY_FINISHED: string =
  "ProductionsAction.REQUEST_CREATE_PRODUCTION_COMPANY_FINISHED";
// REQUEST CREATE PRODUCTION COMPANY
export const requestCreateProductionCompany = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_PRODUCTION_COMPANY,
      ProductionsEffect.requestCreateProductionCompany,
      formData,
    );
  };
};

export const CLEAR_REQUEST_CREATE_PRODUCTION_COMPANY: string =
  "ProductionsAction.CLEAR_REQUEST_CREATE_PRODUCTION_COMPANY";

export const clearRequestCreateProductionCompany = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_PRODUCTION_COMPANY);
};
