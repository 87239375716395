import RouteEnum from "constants/RouteEnum";
import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  redirectToPath?: string;
  height?: number;
  width?: number;
  dark?: boolean;
}

const CastelectLogo: React.FC<IProps> = (props: IProps) => {
  const { redirectToPath, height, width, dark } = props;
  return (
    <Link to={redirectToPath || RouteEnum.Home}>
      <svg xmlns="http://www.w3.org/2000/svg" height={height || 30} width={width || 134} viewBox="0 0 134.34 28.25">
        <defs>
          <style>
            {`.cls-1 {
                fill: #529dd6;
                mix-blend-mode: multiply;
              }

              .cls-1, .cls-2, .cls-3, .cls-4 {
                stroke-width: 0px;
              }

              .cls-5 {
                isolation: isolate;
              }

              .cls-2 {
                fill: #5ecae7;
              }

              .cls-3 {
                fill: #d2edf3;
              }

              .cls-4 {
                fill: ${dark ? "#000" : "#fff"};
              }
              `}
          </style>
        </defs>
        <g className="cls-5">
          <g id="Layer_1" data-name="Layer 1">
            <g>
              <g>
                <path
                  className="cls-4"
                  d="m42.43,16.71c-.43.2-.85.3-1.26.3-.52,0-1-.13-1.43-.39-.43-.26-.77-.61-1.02-1.06-.25-.44-.37-.94-.37-1.47s.12-1.03.37-1.47c.25-.44.59-.79,1.02-1.04.43-.26.91-.38,1.43-.38.43,0,.86.11,1.29.32.43.22.8.51,1.12.89l1.22-1.49c-.46-.48-1.01-.85-1.66-1.13-.65-.28-1.31-.42-1.98-.42-.92,0-1.75.21-2.5.62-.75.41-1.35.98-1.78,1.7-.43.72-.65,1.53-.65,2.42s.21,1.71.63,2.44c.42.73,1,1.31,1.74,1.72.74.42,1.56.63,2.47.63.67,0,1.34-.15,2-.45.66-.3,1.24-.7,1.74-1.22l-1.23-1.33c-.33.33-.72.6-1.14.8Z"
                />
                <polygon
                  className="cls-4"
                  points="51.55 9.52 47.54 18.76 49.69 18.76 50.42 16.97 50.42 16.97 52.57 11.69 54.8 17.22 54.8 17.22 55.42 18.76 57.65 18.76 53.71 9.52 51.55 9.52"
                />
                <path
                  className="cls-4"
                  d="m66.67,13.76c-.41-.18-.93-.37-1.57-.55s-1.1-.36-1.41-.54c-.3-.17-.46-.42-.46-.75,0-.26.11-.45.32-.58s.5-.2.86-.2.83.09,1.37.26c.53.17,1.04.4,1.51.69l.81-1.73c-.48-.31-1.02-.55-1.64-.74-.62-.18-1.24-.28-1.88-.28-.71,0-1.34.11-1.88.34-.54.22-.96.55-1.25.96-.3.42-.45.9-.45,1.46,0,.6.15,1.08.44,1.44.29.36.64.63,1.04.81.4.18.92.35,1.55.53.44.12.79.23,1.04.33.25.1.46.22.62.38.16.15.24.35.24.59,0,.3-.12.53-.36.69-.24.16-.58.24-1.01.24-.5,0-1.06-.13-1.66-.38-.61-.26-1.14-.59-1.61-1.02l-.83,1.7c.53.46,1.15.82,1.88,1.08.72.26,1.46.4,2.21.4.7,0,1.32-.11,1.86-.33.55-.22.98-.54,1.29-.97.31-.43.47-.94.47-1.53s-.15-1.1-.44-1.47c-.29-.37-.64-.65-1.05-.83Z"
                />
                <polygon
                  className="cls-4"
                  points="71.35 11.29 74.17 11.29 74.17 18.76 76.25 18.76 76.25 11.29 79.08 11.29 79.08 9.52 71.35 9.52 71.35 11.29"
                />
                <polygon
                  className="cls-4"
                  points="84.93 14.98 88.99 14.98 88.99 13.25 84.93 13.25 84.93 11.25 89.89 11.25 89.89 9.52 82.85 9.52 82.85 18.76 90.03 18.76 90.03 17.03 84.93 17.03 84.93 14.98"
                />
                <polygon
                  className="cls-4"
                  points="96.4 9.52 94.31 9.52 94.31 18.76 100.36 18.76 100.36 16.94 96.4 16.94 96.4 9.52"
                />
                <polygon
                  className="cls-4"
                  points="106.33 14.98 110.39 14.98 110.39 13.25 106.33 13.25 106.33 11.25 111.29 11.25 111.29 9.52 104.25 9.52 104.25 18.76 111.43 18.76 111.43 17.03 106.33 17.03 106.33 14.98"
                />
                <path
                  className="cls-4"
                  d="m121.27,16.71c-.43.2-.85.3-1.26.3-.52,0-1-.13-1.43-.39-.43-.26-.77-.61-1.02-1.06-.25-.44-.37-.94-.37-1.47s.12-1.03.37-1.47c.25-.44.59-.79,1.02-1.04.43-.26.91-.38,1.43-.38.43,0,.86.11,1.29.32.43.22.8.51,1.12.89l1.22-1.49c-.46-.48-1.01-.85-1.66-1.13-.65-.28-1.31-.42-1.98-.42-.92,0-1.75.21-2.5.62-.75.41-1.35.98-1.78,1.7-.43.72-.65,1.53-.65,2.42s.21,1.71.63,2.44c.42.73,1,1.31,1.74,1.72.74.42,1.56.63,2.47.63.67,0,1.34-.15,2-.45.66-.3,1.24-.7,1.74-1.22l-1.23-1.33c-.33.33-.72.6-1.14.8Z"
                />
                <polygon
                  className="cls-4"
                  points="126.61 9.52 126.61 11.29 129.42 11.29 129.42 18.76 131.51 18.76 131.51 11.29 134.34 11.29 134.34 9.52 126.61 9.52"
                />
              </g>
              <path
                className="cls-3"
                d="m28.55,20.42l-5.16-2.91c-1.18,2.11-3.45,3.54-6.04,3.54-3.83,0-6.92-3.1-6.92-6.93s3.1-6.93,6.92-6.93c2.6,0,4.86,1.43,6.04,3.54l5.16-2.91,1.13-.64C27.26,2.9,22.65,0,17.38,0,9.59,0,3.25,6.34,3.25,14.13s6.34,14.13,14.13,14.13c5.27,0,9.88-2.9,12.3-7.19l-1.13-.64Z"
              />
              <path
                className="cls-2"
                d="m20.05,14.13c0-.99-.53-1.84-1.32-2.31h.02S6.29,4.87,6.29,4.87l-2.26-1.26h0s0,0,0,0l-.04-.02s0,0,0,0c-.38-.21-.82-.33-1.28-.33-1.49,0-2.7,1.21-2.7,2.7v16.32c0,1.49,1.21,2.7,2.7,2.7.47,0,.91-.12,1.29-.33h0s10.1-5.63,10.1-5.63h0s4.59-2.55,4.59-2.55h-.01c.07-.04.14-.07.2-.12h0s0,0,0,0c.71-.49,1.18-1.3,1.18-2.23Z"
              />
              <circle className="cls-1" cx="17.35" cy="14.13" r="2.7" />
              <path
                className="cls-1"
                d="m10.43,14.13c0-2.22,1.05-4.19,2.67-5.46l-6.52-3.64c-2.07,2.46-3.33,5.63-3.33,9.09s1.25,6.63,3.33,9.09l6.52-3.64c-1.62-1.27-2.67-3.24-2.67-5.46Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Link>
  );
};

export default CastelectLogo;
