import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import IStore from "models/IStore";
import { ReduxDispatch } from "models/ReduxProps";
import AuditionVideoModel from "models/api-models/audition-video/AuditionVideoModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import RoleOfferModel from "models/api-models/role-offer/RoleOfferModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as ActorRoleApplicationsEffect from "./ActorRoleApplicationsEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | RoleInvitationModel
  | RoleApplicationModel
  | RoleApplicationModel[]
  | AuditionVideoModel[]
  | RoleOfferModel[];

export const REQUEST_ROLE_APPLICATION: string = "ActorRoleApplicationsAction.REQUEST_ROLE_APPLICATION";
export const REQUEST_ROLE_APPLICATION_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_ROLE_APPLICATION_FINISHED";

export const requestRoleApplication = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATION,
      ActorRoleApplicationsEffect.requestRoleApplication,
      uuid,
    );
  };
};

export const CLEAR_ROLE_APPLICATION: string = "ActorRoleApplicationsAction.CLEAR_ROLE_APPLICATION";

export const clearRoleApplication = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_ROLE_APPLICATION);
};

export const REQUEST_CREATE_ROLE_APPLICATION: string = "ActorRoleApplicationsAction.REQUEST_CREATE_ROLE_APPLICATION";
export const REQUEST_CREATE_ROLE_APPLICATION_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_CREATE_ROLE_APPLICATION_FINISHED";

export const requestCreateRoleApplication = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ROLE_APPLICATION,
      ActorRoleApplicationsEffect.requestCreateRoleApplication,
      formData,
    );
  };
};

export const CLEAR_REQUEST_CREATE_ROLE_APPLICATION: string =
  "ActorRoleApplicationsAction.CLEAR_REQUEST_CREATE_ROLE_APPLICATION";

export const clearRequestAcceptRoleApplication = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_ROLE_APPLICATION);
};

export const REQUEST_UPDATE_ROLE_APPLICATION: string = "ActorRoleApplicationsAction.REQUEST_UPDATE_ROLE_APPLICATION";
export const REQUEST_UPDATE_ROLE_APPLICATION_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_UPDATE_ROLE_APPLICATION_FINISHED";

export const requestUpdateRoleApplication = (formData: any, uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ROLE_APPLICATION,
      ActorRoleApplicationsEffect.requestUpdateRoleApplication,
      formData,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ROLE_APPLICATION: string =
  "ActorRoleApplicationsAction.CLEAR_REQUEST_UPDATE_ROLE_APPLICATION";

export const clearRequestUpdateRoleApplication = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ROLE_APPLICATION);
};

export const REQUEST_ROLE_INVITATION: string = "ActorRoleApplicationsAction.REQUEST_ROLE_INVITATION";
export const REQUEST_ROLE_INVITATION_FINISHED: string = "ActorRoleApplicationsAction.REQUEST_ROLE_INVITATION_FINISHED";

export const requestRoleInvitation = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleInvitationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_INVITATION,
      ActorRoleApplicationsEffect.requestRoleInvitation,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_ROLE_INVITATION: string = "ActorRoleApplicationsAction.CLEAR_REQUEST_ROLE_INVITATION";

export const clearRequestRoleInvitation = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ROLE_INVITATION);
};

export const REQUEST_ROLE_APPLICATIONS: string = "ActorRoleApplicationsAction.REQUEST_ROLE_APPLICATIONS";
export const REQUEST_ROLE_APPLICATIONS_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_ROLE_APPLICATIONS_FINISHED";

export const requestRoleApplications = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATIONS,
      ActorRoleApplicationsEffect.requestRoleApplications,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_ROLE_APPLICATIONS: string = "ActorRoleApplicationsAction.CLEAR_REQUEST_ROLE_APPLICATIONS";

export const clearRequestRoleApplications = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ROLE_APPLICATIONS);
};

export const REQUEST_CREATE_AUDITION_VIDEO: string = "ActorRoleApplicationsAction.REQUEST_CREATE_AUDITION_VIDEO";
export const REQUEST_CREATE_AUDITION_VIDEO_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_CREATE_AUDITION_VIDEO_FINISHED";

export const requestCreateAuditionVideo = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const auditionVideos: AuditionVideoModel[] | undefined =
      getState().projects.actorRoleApplicationState.applicationDetail?.audition_videos;
    await ActionUtility.createThunkEffect<AuditionVideoModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_AUDITION_VIDEO,
      ActorRoleApplicationsEffect.requestCreateAuditionVideo,
      formData,
      auditionVideos,
    );
  };
};

export const CLEAR_REQUEST_CREATE_AUDITION_VIDEO: string =
  "ActorRoleApplicationsAction.CLEAR_REQUEST_CREATE_AUDITION_VIDEO";

export const clearCreateRequestAuditionVideo = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_AUDITION_VIDEO);
};

export const REQUEST_DELETE_AUDITION_VIDEO: string = "ActorRoleApplicationsAction.REQUEST_DELETE_AUDITION_VIDEO";
export const REQUEST_DELETE_AUDITION_VIDEO_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_DELETE_AUDITION_VIDEO_FINISHED";

export const requestDeleteAuditionVideo = (uuid: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const auditionVideos: AuditionVideoModel[] | undefined =
      getState().projects.actorRoleApplicationState.applicationDetail?.audition_videos;
    await ActionUtility.createThunkEffect<AuditionVideoModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DELETE_AUDITION_VIDEO,
      ActorRoleApplicationsEffect.requestDeleteAuditionVideo,
      uuid,
      auditionVideos,
    );
  };
};

export const CLEAR_REQUEST_DELETE_AUDITION_VIDEO: string =
  "ActorRoleApplicationsAction.CLEAR_REQUEST_DELETE_AUDITION_VIDEO";

export const clearRequestDeleteAuditionVideo = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DELETE_AUDITION_VIDEO);
};

export const REQUEST_UPDATE_AUDITION_VIDEO: string = "ActorRoleApplicationsAction.REQUEST_UPDATE_AUDITION_VIDEO";
export const REQUEST_UPDATE_AUDITION_VIDEO_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_UPDATE_AUDITION_VIDEO_FINISHED";

export const requestUpdateAuditionVideo = (uuid: string, formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const auditionVideos: AuditionVideoModel[] | undefined =
      getState().projects.actorRoleApplicationState.applicationDetail?.audition_videos;
    await ActionUtility.createThunkEffect<AuditionVideoModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_AUDITION_VIDEO,
      ActorRoleApplicationsEffect.requestUpdateAuditionVideo,
      uuid,
      formData,
      auditionVideos,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_AUDITION_VIDEO: string =
  "ActorRoleApplicationsAction.CLEAR_REQUEST_UPDATE_AUDITION_VIDEO";

export const clearUpdateRequestAuditionVideo = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_AUDITION_VIDEO);
};

export const SHOW_AUDITION_EDIT_MODAL: string = "ActorRoleApplicationsAction.SHOW_AUDITION_EDIT_MODAL";

export const showAuditionEditModal = (audition: AuditionVideoModel): IAction<AuditionVideoModel> => {
  return ActionUtility.createAction(SHOW_AUDITION_EDIT_MODAL, audition);
};

export const CLOSE_AUDITION_EDIT_MODAL: string = "ActorRoleApplicationsAction.CLOSE_AUDITION_EDIT_MODAL";

export const closeAuditionEditModal = (): IAction<undefined> => {
  return ActionUtility.createAction(CLOSE_AUDITION_EDIT_MODAL);
};

export const REQUEST_ACCEPT_ROLEOFFER: string = "ActorRoleApplicationsAction.REQUEST_ACCEPT_ROLEOFFER";
export const REQUEST_ACCEPT_ROLEOFFER_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_ACCEPT_ROLEOFFER_FINISHED";

export const requestAcceptRoleOffer = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACCEPT_ROLEOFFER,
      ActorRoleApplicationsEffect.requestAcceptRoleOffer,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_ACCEPT_ROLEOFFER: string = "ActorRoleApplicationsAction.CLEAR_REQUEST_ACCEPT_ROLEOFFER";

export const clearRequestAcceptRoleOffer = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ACCEPT_ROLEOFFER);
};

export const REQUEST_DECLINE_ROLEOFFER: string = "ActorRoleApplicationsAction.REQUEST_DECLINE_ROLEOFFER";
export const REQUEST_DECLINE_ROLEOFFER_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_DECLINE_ROLEOFFER_FINISHED";

export const requestDeclineRoleOffer = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const offers: RoleOfferModel[] | undefined =
      getState().projects.actorRoleApplicationState.applicationDetail?.offers;
    await ActionUtility.createThunkEffect<RoleOfferModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DECLINE_ROLEOFFER,
      ActorRoleApplicationsEffect.requestDeclineRoleOffer,
      uuid,
      offers,
    );
  };
};

export const CLEAR_REQUEST_DECLINE_ROLEOFFER: string = "ActorRoleApplicationsAction.CLEAR_REQUEST_DECLINE_ROLEOFFER";

export const clearRequestDeclineRoleOffer = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DECLINE_ROLEOFFER);
};

export const REQUEST_FIND_ROLE_APPLICATION: string = "ActorRoleApplicationsAction.REQUEST_FIND_ROLE_APPLICATION";
export const REQUEST_FIND_ROLE_APPLICATION_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_FIND_ROLE_APPLICATION_FINISHED";

export const requestFindRoleApplication = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid = getState().projects.actorRoleApplicationState.applicationDetail?.uuid;
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_FIND_ROLE_APPLICATION,
      ActorRoleApplicationsEffect.requestRoleApplication,
      uuid,
    );
  };
};

export const REQUEST_CANCEL_ROLEOFFER: string = "ActorRoleApplicationsAction.REQUEST_CANCEL_ROLEOFFER";
export const REQUEST_CANCEL_ROLEOFFER_FINISHED: string =
  "ActorRoleApplicationsAction.REQUEST_CANCEL_ROLEOFFER_FINISHED";

export const requestCancelRoleOffer = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CANCEL_ROLEOFFER,
      ActorRoleApplicationsEffect.requestCancelRoleOffer,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_CANCEL_ROLEOFFER: string = "ActorRoleApplicationsAction.CLEAR_REQUEST_CANCEL_ROLEOFFER";

export const clearRequestCancelRoleOffer = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CANCEL_ROLEOFFER);
};
