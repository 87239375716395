import { BaseModel } from "sjs-base-model";

export default class GeneralBudgetAndCashModel extends BaseModel {
  public readonly production_id: number = 0;
  public readonly total_budget: number = 0;
  public readonly pay_to_actor: number = 0;
  public readonly pay_to_cast: number = 0;

  constructor(data: Partial<GeneralBudgetAndCashModel>) {
    super();

    this.update(data);
  }
}
