import CastRoleOfferModel from "models/api-models/cast-role-offer/CastRoleOfferModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import ISimpleProjectModel from "selectors/projects/models/ISimpleProjectModel";

export default interface ICastProjects {
  readonly roleInvitations: RoleInvitationModel[];
  readonly roleApplications: RoleApplicationModel[];
  readonly roleOffers: CastRoleOfferModel[];
  readonly projects: ISimpleProjectModel[];

  readonly selectedRole: string | undefined;
}

export const initialState: ICastProjects = {
  roleInvitations: [],
  roleApplications: [],
  roleOffers: [],
  projects: [],
  selectedRole: undefined,
};
