import LightActorModel from "../LightActorModel";
import RoleOfferModel from "../role-offer/RoleOfferModel";

export default class CastRoleOfferModel extends RoleOfferModel {
  public readonly actor: LightActorModel = LightActorModel as any;

  constructor(data: Partial<CastRoleOfferModel>) {
    super(data);

    this.update(data);
  }
}
