import IStore from "models/IStore";
import IAction from "models/IAction";
import ProfileModel from "./models/ProfileModel";
import { ReduxDispatch } from "models/ReduxProps";
import * as ProfilesEffect from "./ProfilesEffect";
import * as ActionUtility from "utilities/ActionUtility";
import ActorModel from "stores/actors/models/actors/ActorModel";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import GraduationModel from "./models/graduation-model/GraduationModel";
import ProductionModel from "stores/companies/productions/models/ProductionModel";
import CastAgencyModel from "stores/companies/cast-agencies/models/CastAgencyModel";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | GraduationModel
  | GraduationModel[]
  | ProfileModel
  | string
  | ActorModel
  | CastAgencyModel
  | ProductionModel
  | ActorModel[];

export const REQUEST_USER: string = "ProfilesAction.REQUEST_USER";
export const REQUEST_USER_FINISHED: string = "ProfilesAction.REQUEST_USER_FINISHED";

export const requestProfile = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ProfileModel>(dispatch, REQUEST_USER, ProfilesEffect.requestProfile);
  };
};

export const EMPTY_PROFILE: string = "ProfilesAction.EMPTY_PROFILE";

export const emptyProfile = (): IAction<undefined> => {
  return ActionUtility.createAction(EMPTY_PROFILE);
};

export const REQUEST_ACTOR_LIKED_CASTS: string = "ProfilesAction.REQUEST_ACTOR_LIKED_CASTS";
export const REQUEST_ACTOR_LIKED_CASTS_FINISHED: string = "ProfilesAction.REQUEST_ACTOR_LIKED_CASTS_FINISHED";

export const requestActorLikedCasts = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_ACTOR_LIKED_CASTS,
      ProfilesEffect.requestActorLikedCasts,
      uuid,
    );
  };
};

export const REQUEST_ACTOR_PROFILE: string = "ProfilesAction.REQUEST_ACTOR_PROFILE";
export const REQUEST_ACTOR_PROFILE_FINISHED: string = "ProfilesAction.REQUEST_ACTOR_PROFILE_FINISHED";

export const getActorProfile = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_PROFILE,
      ProfilesEffect.getActorProfile,
    );
  };
};

export const REQUEST_CAST_PROFILE: string = "ProfilesAction.REQUEST_CAST_PROFILE";
export const REQUEST_CAST_PROFILE_FINISHED: string = "ProfilesAction.REQUEST_CAST_PROFILE_FINISHED";

export const getCastProfile = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CastAgencyModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_PROFILE,
      ProfilesEffect.getCastProfile,
      slug,
    );
  };
};

export const REQUEST_CAST_LIKED_ACTORS: string = "ProfilesAction.REQUEST_CAST_LIKED_ACTORS";
export const REQUEST_CAST_LIKED_ACTORS_FINISHED: string = "ProfilesAction.REQUEST_CAST_LIKED_ACTORS_FINISHED";

export const requestCastLikedActor = (cast_slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorModel[]>(
      dispatch,
      REQUEST_CAST_LIKED_ACTORS,
      ProfilesEffect.requestCastLikedActor,
      cast_slug,
    );
  };
};

export const CLEAR_MESSAGE: string = "ProfilesAction.CLEAR_MESSAGE";

export const clearMessage = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_MESSAGE);
};

export const REQUEST_PROFILE_UPDATE: string = "ProfilesAction.REQUEST_PROFILE_UPDATE";
export const REQUEST_PROFILE_UPDATE_FINISHED: string = "ProfilesAction.REQUEST_PROFILE_UPDATE_FINISHED";

export const requestUpdateProfile = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const user_name: string | undefined = getState().profiles.profile?.username;
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_PROFILE_UPDATE,
      ProfilesEffect.requestUpdateProfile,
      formData,
      user_name,
    );
  };
};

export const EMPTY_PROFILE_UPDATE_MESSAGE: string = "ProfilesAction.EMPTY_PROFILE_UPDATE_MESSAGE";

export const emptyProfileUpdateMessage = (): IAction<undefined> => {
  return ActionUtility.createAction(EMPTY_PROFILE_UPDATE_MESSAGE);
};

export const REQUEST_ADD_GRADUATION: string = "ProfilesAction.REQUEST_ADD_GRADUATION";
export const REQUEST_ADD_GRADUATION_FINISHED: string = "ProfilesAction.REQUEST_ADD_GRADUATION_FINISHED";

export const requestAddGraduation = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<GraduationModel>(
      dispatch,
      REQUEST_ADD_GRADUATION,
      ProfilesEffect.requestAddGraduation,
      formData,
    );
  };
};

export const CLEAR_REQUEST_ADD_GRADUATION: string = "ProfilesAction.CLEAR_REQUEST_ADD_GRADUATION";

export const clearRequestAddGraduation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ADD_GRADUATION);
};

export const REQUEST_UPDATE_GRADUATION: string = "ProfilesAction.REQUEST_UPDATE_GRADUATION";
export const REQUEST_UPDATE_GRADUATION_FINISHED: string = "ProfilesAction.REQUEST_UPDATE_GRADUATION_FINISHED";

export const requestUpdateGraduation = (formData: any, uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const graduations: GraduationModel[] | undefined = getState().profiles.profile?.graduations;
    await ActionUtility.createThunkEffect<GraduationModel[]>(
      dispatch,
      REQUEST_UPDATE_GRADUATION,
      ProfilesEffect.requestUpdateGraduation,
      formData,
      uuid,
      graduations,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_GRADUATION: string = "ProfilesAction.CLEAR_REQUEST_UPDATE_GRADUATION";

export const clearRequestUpdateGraduation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_GRADUATION);
};

export const REQUEST_DELETE_GRADUATION: string = "ProfilesAction.REQUEST_DELETE_GRADUATION";
export const REQUEST_DELETE_GRADUATION_FINISHED: string = "ProfilesAction.REQUEST_DELETE_GRADUATION_FINISHED";

export const requestDeleteGraduation = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const graduations: GraduationModel[] | undefined = getState().profiles.profile?.graduations;
    await ActionUtility.createThunkEffect<GraduationModel[]>(
      dispatch,
      REQUEST_DELETE_GRADUATION,
      ProfilesEffect.requestDeleteGraduation,
      uuid,
      graduations,
    );
  };
};

export const CLEAR_REQUEST_DELETE_GRADUATION: string = "ProfilesAction.CLEAR_REQUEST_DELETE_GRADUATION";

export const clearRequestDeleteGraduation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DELETE_GRADUATION);
};
