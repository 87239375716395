import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import IPagination from "models/IPagination";
import IStore from "models/IStore";
import { ReduxDispatch } from "models/ReduxProps";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import IResponseExperienceModel from "models/form/actor/IExperienceModel";
import IResponseActorEducationModel from "models/form/actor/IResponseActorEducationModel";
import IResponseAvailabilityModel from "models/form/actor/IResponseAvailabilityModel";
import CastAgencyModel from "stores/companies/cast-agencies/models/CastAgencyModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as ActorsEffect from "./ActorsEffect";
import ActorModel from "./models/actors/ActorModel";
import ActorProfileModel from "./models/actors/ActorProfileModel";
import ActorReferenceModel from "./models/actors/models/ActorReferenceModel";
import ActorChildModel from "./models/actors/models/actor-child/ActorChildModel";

type ActionUnion =
  | undefined
  | string
  | HttpErrorResponseModel
  | IPagination<ActorModel[]>
  | RoleInvitationModel[]
  | ActorModel
  | ActorProfileModel
  | IResponseAvailabilityModel
  | IResponseExperienceModel
  | IResponseActorEducationModel
  | ActorChildModel
  | ActorChildModel[]
  | CastAgencyModel
  | CastAgencyModel[]
  | ActorReferenceModel
  | ActorReferenceModel[];

export const REQUEST_SEARCH_ACTORS: string = "ActorsAction.REQUEST_SEARCH_ACTORS";
export const REQUEST_SEARCH_ACTORS_FINISHED: string = "ActorsAction.REQUEST_SEARCH_ACTORS_FINISHED";

export const requestFilterActors = (params: string, search?: string | null, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<ActorModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SEARCH_ACTORS,
      ActorsEffect.requestFilterActors,
      params,
      search,
      page,
    );
  };
};

export const requestSearchActors = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<ActorModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SEARCH_ACTORS,
      ActorsEffect.requestSearchActors,
      search,
      page,
    );
  };
};

export const CLEAR_ACTORS: string = "ActorsAction.CLEAR_ACTORS";

export const clearActors = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_ACTORS);
};

export const REQUEST_CREATE_ACTOR_PROFILE: string = "ActorsAction.REQUEST_CREATE_ACTOR_PROFILE";
export const REQUEST_CREATE_ACTOR_PROFILE_FINISHED: string = "ActorsAction.REQUEST_CREATE_ACTOR_PROFILE_FINISHED";

export const requestCreateActorProfile = (actor: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ACTOR_PROFILE,
      ActorsEffect.requestCreateActorProfile,
      actor,
    );
  };
};

export const CLEAR_REQUEST_CREATE_ACTOR_PROFILE: string = "ActorsAction.CLEAR_REQUEST_CREATE_ACTOR_PROFILE";

export const clearRequestCreateActorProfile = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_ACTOR_PROFILE);
};

export const REQUEST_UPDATE_ACTOR_PROFILE: string = "ActorsAction.REQUEST_UPDATE_ACTOR_PROFILE";
export const REQUEST_UPDATE_ACTOR_PROFILE_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_PROFILE_FINISHED";

export const requestUpdateActor = (actor: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_PROFILE,
      ActorsEffect.requestUpdateActor,
      actor,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_PROFILE: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_PROFILE";

export const clearRequestUpdateActor = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_PROFILE);
};

export const REQUEST_ACTOR: string = "ActorsAction.REQUEST_ACTOR";
export const REQUEST_ACTOR_FINISHED: string = "ActorsAction.REQUEST_ACTOR_FINISHED";

export const getActor = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR,
      ActorsEffect.getActor,
    );
  };
};

export const REQUEST_FIND: string = "ActorsAction.REQUEST_FIND";
export const REQUEST_FIND_FINISHED: string = "ActorsAction.REQUEST_FIND_FINISHED";

export const find = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_FIND,
      ActorsEffect.find,
      uuid,
    );
  };
};

export const ACTOR_DETAIL_EMPTY: string = "ActorsAction.ACTOR_DETAIL_EMPTY";

export const emptyActorDetail = (): IAction<undefined> => {
  return ActionUtility.createAction(ACTOR_DETAIL_EMPTY);
};

export const REQUEST_ACTOR_CASTS: string = "ActorsAction.REQUEST_ACTOR_CASTS";
export const REQUEST_ACTOR_CASTS_FINISHED: string = "ActorsAction.REQUEST_ACTOR_CASTS_FINISHED";

export const requestActorCasts = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CastAgencyModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_CASTS,
      ActorsEffect.requestActorCasts,
      uuid,
    );
  };
};

export const ACTOR_CAST_EMPTY: string = "ActorsAction.ACTOR_CAST_EMPTY";

export const emptyActorCast = (): IAction<undefined> => {
  return ActionUtility.createAction(ACTOR_CAST_EMPTY);
};

export const REQUEST_ACTOR_PROFILE: string = "ActorsAction.REQUEST_ACTOR_PROFILE";
export const REQUEST_ACTOR_PROFILE_FINISHED: string = "ActorsAction.REQUEST_ACTOR_PROFILE_FINISHED";

export const requestActorProfile = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorProfileModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_PROFILE,
      ActorsEffect.requestActorProfile,
      uuid,
    );
  };
};

export const REQUEST_ACTOR_LIKED_BY_ME: string = "ActorsAction.REQUEST_ACTOR_LIKED_BY_ME";
export const REQUEST_ACTOR_LIKED_BY_ME_FINISHED: string = "ActorsAction.REQUEST_ACTOR_LIKED_BY_ME_FINISHED";

export const requestActorLikedByMe = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<CastAgencyModel | undefined | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_LIKED_BY_ME,
      ActorsEffect.requestActorLikedByMe,
      uuid,
    );
  };
};

export const REQUEST_CAST_LIKE_TO_ACTOR: string = "ActorsAction.REQUEST_CAST_LIKE_TO_ACTOR";
export const REQUEST_CAST_LIKE_TO_ACTOR_FINISHED: string = "ActorsAction.REQUEST_CAST_LIKE_TO_ACTOR_FINISHED";

export const requestCastLikeToActor = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_LIKE_TO_ACTOR,
      ActorsEffect.requestCastLikeToActor,
      uuid,
    );
  };
};

export const REQUEST_CAST_UNLIKE_TO_ACTOR: string = "ActorsAction.REQUEST_CAST_UNLIKE_TO_ACTOR";
export const REQUEST_CAST_UNLIKE_TO_ACTOR_FINISHED: string = "ActorsAction.REQUEST_CAST_UNLIKE_TO_ACTOR_FINISHED";

export const requestCastUnLikeToActor = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_UNLIKE_TO_ACTOR,
      ActorsEffect.requestCastUnLikeToActor,
      uuid,
    );
  };
};

export const REQUEST_DELETE_ACTOR_VIDEO: string = "ActorsAction.REQUEST_DELETE_ACTOR_VIDEO";
export const REQUEST_DELETE_ACTOR_VIDEO_FINISHED: string = "ActorsAction.REQUEST_DELETE_ACTOR_VIDEO_FINISHED";

export const requestDeleteActorVideo = (video_uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DELETE_ACTOR_VIDEO,
      ActorsEffect.requestDeleteActorVideo,
      video_uuid,
    );
  };
};

export const EMPTY_DELETE_ACTOR_VIDEO: string = "ActorsAction.EMPTY_DELETE_ACTOR_VIDEO";

export const emptyDeleteActorVideo = (): IAction<undefined> => {
  return ActionUtility.createAction(EMPTY_DELETE_ACTOR_VIDEO);
};

export const REQUEST_UPLOAD_ACTOR_VIDEO: string = "ActorsAction.REQUEST_UPLOAD_ACTOR_VIDEO";
export const REQUEST_UPLOAD_ACTOR_VIDEO_FINISHED: string = "ActorsAction.REQUEST_UPLOAD_ACTOR_VIDEO_FINISHED";

export const requestUploadActorVideo = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPLOAD_ACTOR_VIDEO,
      ActorsEffect.requestUploadActorVideo,
      formData,
    );
  };
};

export const EMPTY_UPLOAD_ACTOR_VIDEO: string = "ActorsAction.EMPTY_UPLOAD_ACTOR_VIDEO";

export const emptyUploadActorVideo = (): IAction<undefined> => {
  return ActionUtility.createAction(EMPTY_UPLOAD_ACTOR_VIDEO);
};

export const REQUEST_UPLOAD_ACTOR_PHOTO: string = "ActorsAction.REQUEST_UPLOAD_ACTOR_PHOTO";
export const REQUEST_UPLOAD_ACTOR_PHOTO_FINISHED: string = "ActorsAction.REQUEST_UPLOAD_ACTOR_PHOTO_FINISHED";

export const requestUploadActorPhoto = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPLOAD_ACTOR_PHOTO,
      ActorsEffect.requestUploadActorPhoto,
      formData,
    );
  };
};

export const CLEAR_REQUEST_UPLOADED_ACTOR_PHOTO_FINISHED: string =
  "ActorsAction.CLEAR_REQUEST_UPLOAD_ACTOR_PHOTO_FINISHED";

export const clearUploadedActorPhoto = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPLOADED_ACTOR_PHOTO_FINISHED);
};

export const REQUEST_ACTOR_EVALUATION: string = "ActorsAction.REQUEST_ACTOR_EVALUATION";
export const REQUEST_ACTOR_EVALUATION_FINISHED: string = "ActorsAction.REQUEST_ACTOR_EVALUATION_FINISHED";

export const requestActorEvaluation = (actorEvaluationData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    actorEvaluationData = { ...actorEvaluationData, actor: uuid };
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_EVALUATION,
      ActorsEffect.requestActorEvaluation,
      actorEvaluationData,
    );
  };
};

export const REQUEST_UPDATE_ACTOR_EVALUATION: string = "ActorsAction.REQUEST_UPDATE_ACTOR_EVALUATION";
export const REQUEST_UPDATE_ACTOR_EVALUATION_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_EVALUATION_FINISHED";

export const requestUpdateActorEvaluation = (formData: any, uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const actor_uuid: string | undefined = getState().actors.actorDetail?.uuid;
    formData = { ...formData, actor: actor_uuid };
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_EVALUATION,
      ActorsEffect.requestUpdateActorEvaluation,
      formData,
      uuid,
    );
  };
};

export const EMPTY_ACTOR_EVALUATION: string = "ActorsAction.EMPTY_ACTOR_EVALUATION";

export const emptyActorEvaluation = (): IAction<undefined> => {
  return ActionUtility.createAction(EMPTY_ACTOR_EVALUATION);
};

export const REQUEST_ACTOR_INVITATIONS: string = "ActorsAction.REQUEST_ACTOR_INVITATIONS";
export const REQUEST_ACTOR_INVITATIONS_FINISHED: string = "ActorsAction.REQUEST_ACTOR_INVITATIONS_FINISHED";

export const requestActorInvitations = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACTOR_INVITATIONS,
      ActorsEffect.requestActorInvitations,
      uuid,
    );
  };
};

export const CLEAR_ACTOR_INVITATIONS: string = "ActorsAction.CLEAR_ACTOR_INVITATIONS";

export const clearActorInvitations = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_ACTOR_INVITATIONS);
};

export const REQUEST_ACCEPT_ROLE_INVITATION: string = "ActorsAction.REQUEST_ACCEPT_ROLE_INVITATION";
export const REQUEST_ACCEPT_ROLE_INVITATION_FINISHED: string = "ActorsAction.REQUEST_ACCEPT_ROLE_INVITATION_FINISHED";

export const requestAcceptRoleInvitation = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleInvitations: RoleInvitationModel[] = getState().actors.roleInvitation;

    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACCEPT_ROLE_INVITATION,
      ActorsEffect.requestAcceptRoleInvitation,
      uuid,
      roleInvitations,
    );
  };
};

export const REQUEST_DECLINE_ROLE_INVITATION: string = "ActorsAction.REQUEST_DECLINE_ROLE_INVITATION";
export const REQUEST_DECLINE_ROLE_INVITATION_FINISHED: string = "ActorsAction.REQUEST_DECLINE_ROLE_INVITATION_FINISHED";

export const requestDeclineRoleInvitation = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleInvitations: RoleInvitationModel[] = getState().actors.roleInvitation;
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DECLINE_ROLE_INVITATION,
      ActorsEffect.requestDeclineRoleInvitation,
      uuid,
      roleInvitations,
    );
  };
};

export const REQUEST_UPDATE_ACTOR_AVAILABILITY: string = "ActorsAction.REQUEST_UPDATE_ACTOR_AVAILABILITY";
export const REQUEST_UPDATE_ACTOR_AVAILABILITY_FINISHED: string =
  "ActorsAction.REQUEST_UPDATE_ACTOR_AVAILABILITY_FINISHED";

export const requestUpdateActorAvailability = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<IResponseAvailabilityModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_AVAILABILITY,
      ActorsEffect.requestUpdateActorAvailability,
      formData,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_AVAILABILITY: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_AVAILABILITY";

export const clearRequestUpdateActorAvailability = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_AVAILABILITY);
};

export const REQUEST_UPDATE_ACTOR_EXPERIENCE: string = "ActorsAction.REQUEST_UPDATE_ACTOR_EXPERIENCE";
export const REQUEST_UPDATE_ACTOR_EXPERIENCE_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_EXPERIENCE_FINISHED";

export const requestUpdateActorExperience = (key: string, value: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<IResponseExperienceModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_EXPERIENCE,
      ActorsEffect.requestUpdateActorExperience,
      key,
      value,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_EXPERIENCE: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_EXPERIENCE";

export const clearRequestUpdateActorExperience = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_EXPERIENCE);
};

export const REQUEST_UPDATE_ACTOR_ABOUT: string = "ActorsAction.REQUEST_UPDATE_ACTOR_ABOUT";
export const REQUEST_UPDATE_ACTOR_ABOUT_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_ABOUT_FINISHED";

export const requestUpdateActorAbout = (value: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_ABOUT,
      ActorsEffect.requestUpdateActorAbout,
      value,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_ABOUT: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_ABOUT";

export const clearRequestUpdateActorAbout = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_ABOUT);
};

// BEGIN CHILDREN ACTIONS
export const REQUEST_CREATE_ACTOR_CHILDREN: string = "ActorsAction.REQUEST_CREATE_ACTOR_CHILDREN";
export const REQUEST_CREATE_ACTOR_CHILDREN_FINISHED: string = "ActorsAction.REQUEST_CREATE_ACTOR_CHILDREN_FINISHED";

export const requestCreateActorChildren = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorChildModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ACTOR_CHILDREN,
      ActorsEffect.requestCreateActorChildren,
      formData,
    );
  };
};

export const CLEAR_REQUEST_CREATE_ACTOR_CHILDREN: string = "ActorsAction.CLEAR_REQUEST_CREATE_ACTOR_CHILDREN";

export const clearRequestCreateActorChildren = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_ACTOR_CHILDREN);
};

export const REQUEST_UPDATE_ACTOR_CHILDREN: string = "ActorsAction.REQUEST_UPDATE_ACTOR_CHILDREN";
export const REQUEST_UPDATE_ACTOR_CHILDREN_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_CHILDREN_FINISHED";

export const requestUpdateActorChildren = (formData: any, uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const children: ActorChildModel[] | undefined = getState().actors.actorDetail?.children;
    await ActionUtility.createThunkEffect<ActorChildModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_CHILDREN,
      ActorsEffect.requestUpdateActorChildren,
      formData,
      uuid,
      children,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_CHILDREN: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_CHILDREN";

export const clearRequestUpdateActorChildren = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_CHILDREN);
};

export const REQUEST_DELETE_ACTOR_CHILDREN: string = "ActorsAction.REQUEST_DELETE_ACTOR_CHILDREN";
export const REQUEST_DELETE_ACTOR_CHILDREN_FINISHED: string = "ActorsAction.REQUEST_DELETE_ACTOR_CHILDREN_FINISHED";

export const requestDeleteActorChildren = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const children: ActorChildModel[] | undefined = getState().actors.actorDetail?.children;
    await ActionUtility.createThunkEffect<ActorChildModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DELETE_ACTOR_CHILDREN,
      ActorsEffect.requestDeleteActorChildren,
      uuid,
      children,
    );
  };
};

export const CLEAR_REQUEST_DELETE_ACTOR_CHILDREN: string = "ActorsAction.CLEAR_REQUEST_DELETE_ACTOR_CHILDREN";

export const clearRequestDeleteActorChildren = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DELETE_ACTOR_CHILDREN);
};
// CHILDREN ACTIONS END

export const REQUEST_UPDATE_ACTOR_HOBBIES: string = "ActorsAction.REQUEST_UPDATE_ACTOR_HOBBIES";
export const REQUEST_UPDATE_ACTOR_HOBBIES_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_HOBBIES_FINISHED";

export const requestUpdateActorHobbies = (value: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_HOBBIES,
      ActorsEffect.requestUpdateActorHobbies,
      value,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_HOBBIES: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_HOBBIES";

export const clearRequestUpdateActorHobbies = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_HOBBIES);
};

export const REQUEST_UPDATE_ACTOR_EDUCATION: string = "ActorsAction.REQUEST_UPDATE_ACTOR_EDUCATION";
export const REQUEST_UPDATE_ACTOR_EDUCATION_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_EDUCATION_FINISHED";

export const requestUpdateActorEducation = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<IResponseActorEducationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_EDUCATION,
      ActorsEffect.requestUpdateActorEducation,
      formData,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_EDUCATION: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_EDUCATION";

export const clearRequestUpdateActorEducation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_EDUCATION);
};

export const REQUEST_UPDATE_ACTOR_SKILLS: string = "ActorsAction.REQUEST_UPDATE_ACTOR_SKILLS";
export const REQUEST_UPDATE_ACTOR_SKILLS_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_SKILLS_FINISHED";

export const requestUpdateActorSkills = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const uuid: string | undefined = getState().actors.actorDetail?.uuid;
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_SKILLS,
      ActorsEffect.requestUpdateActorSkills,
      formData,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_SKILLS: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_SKILLS";

export const clearRequestUpdateActorSkills = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_SKILLS);
};

// BEGIN REFERENCES ACTIONS
export const REQUEST_CREATE_ACTOR_REFERENCE: string = "ActorsAction.REQUEST_CREATE_ACTOR_REFERENCE";
export const REQUEST_CREATE_ACTOR_REFERENCE_FINISHED: string = "ActorsAction.REQUEST_CREATE_ACTOR_REFERENCE_FINISHED";

export const requestCreateActorReference = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorReferenceModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ACTOR_REFERENCE,
      ActorsEffect.requestCreateActorReference,
      formData,
    );
  };
};

export const CLEAR_REQUEST_CREATE_ACTOR_REFERENCE: string = "ActorsAction.CLEAR_REQUEST_CREATE_ACTOR_REFERENCE";

export const clearRequestCreateActorReference = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_ACTOR_REFERENCE);
};

export const REQUEST_UPDATE_ACTOR_REFERENCE: string = "ActorsAction.REQUEST_UPDATE_ACTOR_REFERENCE";
export const REQUEST_UPDATE_ACTOR_REFERENCE_FINISHED: string = "ActorsAction.REQUEST_UPDATE_ACTOR_REFERENCE_FINISHED";

export const requestUpdateActorReference = (formData: any, uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const references: ActorReferenceModel[] | undefined = getState().actors.actorDetail?.references;
    await ActionUtility.createThunkEffect<ActorReferenceModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_ACTOR_REFERENCE,
      ActorsEffect.requestUpdateActorReference,
      formData,
      uuid,
      references,
    );
  };
};

export const CLEAR_REQUEST_UPDATE_ACTOR_REFERENCE: string = "ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_REFERENCE";

export const clearRequestUpdateActorReference = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_UPDATE_ACTOR_REFERENCE);
};

export const REQUEST_DELETE_ACTOR_REFERENCE: string = "ActorsAction.REQUEST_DELETE_ACTOR_REFERENCE";
export const REQUEST_DELETE_ACTOR_REFERENCE_FINISHED: string = "ActorsAction.REQUEST_DELETE_ACTOR_REFERENCE_FINISHED";

export const requestDeleteActorReference = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const references: ActorReferenceModel[] | undefined = getState().actors.actorDetail?.references;
    await ActionUtility.createThunkEffect<ActorReferenceModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DELETE_ACTOR_REFERENCE,
      ActorsEffect.requestDeleteActorReference,
      uuid,
      references,
    );
  };
};

export const CLEAR_REQUEST_DELETE_ACTOR_REFERENCE: string = "ActorsAction.CLEAR_REQUEST_DELETE_ACTOR_REFERENCE";

export const clearRequestDeleteActorReference = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DELETE_ACTOR_REFERENCE);
};

export const REQUEST_DELETE_ACTOR_PHOTOS: string = "ActorsAction.REQUEST_DELETE_ACTOR_PHOTOS";
export const REQUEST_DELETE_ACTOR_PHOTOS_FINISHED: string = "ActorsAction.REQUEST_DELETE_ACTOR_PHOTOS_FINISHED";

export const requestDeleteActorPhotos = (photo_uuids: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DELETE_ACTOR_PHOTOS,
      ActorsEffect.requestDeleteActorPhotos,
      photo_uuids,
    );
  };
};

export const CLEAR_REQUEST_DELETE_ACTOR_PHOTOS: string = "ActorsAction.CLEAR_REQUEST_DELETE_ACTOR_PHOTOS";

export const clearDeleteActorPhotos = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DELETE_ACTOR_PHOTOS);
};
