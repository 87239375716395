import { AxiosResponse } from "axios";
import environment from "environment";
import { EvaluationRequest } from "interfaces";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import EvaluationCriterionModel from "models/api-models/EvaluationCriterionModel";
import MeetingLocation from "models/api-models/MeetingLocation";
import RoleApplicationEvaluationModel from "models/api-models/RoleApplicationEvaluationModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleOfferModel from "models/api-models/role-offer/RoleOfferModel";
import ActorModel from "stores/actors/models/actors/ActorModel";
import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";

export const requestRoleApplications = async (
  uuid: string,
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.applications.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data.map((json: Partial<RoleApplicationModel>) => new RoleApplicationModel(json));
};

export const requestActorMeetingLocation = async (
  role_uuid: string,
  actor_uuid: string,
): Promise<MeetingLocation | undefined | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.roleapplications.replace(":uuid", actor_uuid) + "?role=" + role_uuid;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  if (response.data && response.data.length > 0) {
    // sadece invited olduğunda aktör e gösteriyoruz. Bu yüzden arayüzde tekrar kontrol etmeye gerek yok
    if (response.data[0].status !== "invited") {
      return undefined;
    }
    // ilk elemanın meeting location olduğunu varsayıyoruz
    const meeting_location = response.data[0].meeting_location;
    if (meeting_location) {
      return new MeetingLocation(meeting_location);
    }
  }

  return undefined;
};

export const requestActor = async (uuid: string): Promise<ActorModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.actor.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new ActorModel(response.data);
};

export const requestPresent = async (
  uuid: string,
  roleApplications: RoleApplicationModel[],
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.present.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(
    roleApplications,
    new RoleApplicationModel(response.data),
  );

  return newRoleApplications;
};

export const requestInviteToCallBack = async (
  uuid: string,
  data: any,
  roleApplications: RoleApplicationModel[],
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.invite_to_callback.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(
    roleApplications,
    new RoleApplicationModel(response.data),
  );

  return newRoleApplications;
};

export const requestUpdateMeetingLocation = async (
  uuid: string,
  data: any,
  roleApplications: RoleApplicationModel[],
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.update_meeting_location.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(
    roleApplications,
    new RoleApplicationModel(response.data),
  );

  return newRoleApplications;
};

export const requestShortList = async (
  uuid: string,
  roleApplications: RoleApplicationModel[],
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.shortlist.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(
    roleApplications,
    new RoleApplicationModel(response.data),
  );

  return newRoleApplications;
};

export const requestDecline = async (
  uuid: string,
  roleApplications: RoleApplicationModel[],
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.decline.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(
    roleApplications,
    new RoleApplicationModel(response.data),
  );

  return newRoleApplications;
};

const updateRoleApplications = (
  roleApplications: RoleApplicationModel[],
  roleApplication: RoleApplicationModel,
): RoleApplicationModel[] => {
  const updatedRoleApplication: RoleApplicationModel | undefined = roleApplications.find(
    (model: RoleApplicationModel) => model.uuid === roleApplication.uuid,
  );

  if (!updatedRoleApplication) {
    roleApplications.push(roleApplication);
    return roleApplications;
  }

  const objIndex = roleApplications.findIndex((obj) => obj.uuid === roleApplication.uuid);

  const newRoleApplications = roleApplications.filter((object) => {
    return object.uuid !== roleApplication.uuid;
  });

  newRoleApplications.splice(objIndex, 0, roleApplication);

  return newRoleApplications;
};

export const requestMakeOffer = async (
  uuid: string,
  formData: any,
): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.make_offer.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new RoleApplicationModel(response.data);
};

export const requestWithdrawRoleOffer = async (
  uuid: string,
  roleApplicationDetail: RoleApplicationModel,
): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleoffers.withdraw.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const withdrawnedOffer: RoleOfferModel = new RoleOfferModel(response.data);

  const offers = roleApplicationDetail.offers.map((item: RoleOfferModel) => {
    if (item.uuid === withdrawnedOffer.uuid) {
      return withdrawnedOffer;
    } else {
      return item;
    }
  });

  return new RoleApplicationModel({
    ...roleApplicationDetail,
    offers: offers,
  });
};

/* eskisi
export const requestOption = async (uuid: string, roleApplications: RoleApplicationModel[]): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
    const endpoint: string = environment.api.projects.roleapplications.option.replace(':uuid', uuid);

    const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

    if (response instanceof HttpErrorResponseModel) {
        return response;
    }

    const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(roleApplications, new RoleApplicationModel(response.data));

    return newRoleApplications;
}*/

export const requestOptionRoleOffer = async (
  uuid: string,
  roleApplicationDetail: RoleApplicationModel,
): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.option.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const upadatedOffer: RoleOfferModel = new RoleOfferModel(response.data);

  const offers = roleApplicationDetail.offers.map((item: RoleOfferModel) => {
    if (item.uuid === upadatedOffer.uuid) {
      return upadatedOffer;
    } else {
      return item;
    }
  });

  return new RoleApplicationModel({
    ...roleApplicationDetail,
    offers: offers,
  });
};

export const requestRoleApplication = async (uuid: string): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.roleapplication.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return new RoleApplicationModel(response.data);
};

export const requestElectRoleOffer = async (
  uuid: string,
  roleApplications: RoleApplicationModel[],
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.elect.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(
    roleApplications,
    new RoleApplicationModel(response.data),
  );

  return newRoleApplications;
};

export const requestFinalize = async (
  uuid: string,
  formData: any,
  roleApplications: RoleApplicationModel[],
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.finalize.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const newRoleApplications: RoleApplicationModel[] = updateRoleApplications(
    roleApplications,
    new RoleApplicationModel(response.data),
  );

  return newRoleApplications;
};

export const requestRoleApplicationEvaluations = async (
  role_application: string,
): Promise<RoleApplicationEvaluationModel[] | HttpErrorResponseModel> => {
  const endpoint: string =
    environment.api.projects.roleapplication_evaluations.index + "?role_application=" + role_application;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map((json: Partial<RoleApplicationEvaluationModel>) => new RoleApplicationEvaluationModel(json));
};

export const requestRoleApplicationEvaluationCriteria = async (): Promise<
  EvaluationCriterionModel[] | HttpErrorResponseModel
> => {
  const endpoint: string = environment.api.projects.roleapplication_evaluation_criteria.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map((json: Partial<EvaluationCriterionModel>) => new EvaluationCriterionModel(json));
};

export const requestCreateEvaluation = async (
  data: EvaluationRequest,
): Promise<RoleApplicationEvaluationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplication_evaluations.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new RoleApplicationEvaluationModel(response.data);
};

export const requestUpdateEvaluation = async (
  uuid: string,
  data: EvaluationRequest,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplication_evaluations.index + uuid + "/";

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return "success";
};

export const requestRoleApplicationEvaluationDetail = async (
  uuid: string,
): Promise<RoleApplicationEvaluationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplication_evaluations.index.concat(uuid + "/");

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new RoleApplicationEvaluationModel(response.data);
};

export const requestCreateMeetingEvaluationLink = async (
  role_uuid: string,
  profile: string,
  isMulti: boolean,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.generate_meeting_evaluation_link;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, {
    is_multiple: isMulti,
    role: role_uuid,
    profile: profile,
  });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data;
};
