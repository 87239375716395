import { BaseModel } from "sjs-base-model";

export default class LightModel extends BaseModel {
  public readonly id: number = 0;
  public readonly display_name: string = "";
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<LightModel>) {
    super();

    this.update(data);
  }
}
