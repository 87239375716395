import IAction from "models/IAction";
import ProfileModel from "../models/ProfileModel";
import IProfileState from "../models/IProfilesState";
import * as LanguageAction from "./LanguageAction";
import LanguageModel from "../models/language-model/LanguageModel";

const LanguageReducer = {
  [LanguageAction.REQUEST_CREATE_LANGUAGE_FINISHED](
    state: IProfileState,
    action: IAction<LanguageModel>,
  ): IProfileState {
    if (state.profile instanceof ProfileModel) {
      return {
        ...state,
        requestCreateLanguage: "success",
        profile: new ProfileModel({
          ...state.profile,
          languages: [...state.profile.languages, action.payload!],
        }),
      };
    }
    return {
      ...state,
    };
  },

  //CLEAR_REQUEST_CREATE_LANGUAGE_MESSAGE
  [LanguageAction.CLEAR_REQUEST_CREATE_LANGUAGE_MESSAGE](state: IProfileState): IProfileState {
    return {
      ...state,
      requestCreateLanguage: undefined,
    };
  },

  //Language update action
  [LanguageAction.REQUEST_UPDATE_LANGUAGE_FINISHED](
    state: IProfileState,
    action: IAction<LanguageModel[]>,
  ): IProfileState {
    if (state.profile instanceof ProfileModel) {
      return {
        ...state,
        requestUpdateLanguage: "success",
        profile: new ProfileModel({
          ...state.profile,
          languages: action.payload!,
        }),
      };
    }
    return state;
  },

  //CLEAR_REQUEST_UPDATE_LANGUAGE_MESSAGE
  [LanguageAction.CLEAR_REQUEST_UPDATE_LANGUAGE_MESSAGE](state: IProfileState): IProfileState {
    return {
      ...state,
      requestUpdateLanguage: undefined,
    };
  },

  // REQUEST_DELETE_LANGUAGE_FINISHED
  [LanguageAction.REQUEST_DELETE_LANGUAGE_FINISHED](
    state: IProfileState,
    action: IAction<LanguageModel[]>,
  ): IProfileState {
    if (state.profile instanceof ProfileModel) {
      return {
        ...state,
        requestDeleteLanguage: "success",
        profile: new ProfileModel({
          ...state.profile,
          languages: action.payload!,
        }),
      };
    }
    return state;
  },

  /// CLEAR_REQUEST_DELETE_LANGUAGE_MESSAGE
  [LanguageAction.CLEAR_REQUEST_DELETE_LANGUAGE_MESSAGE](state: IProfileState): IProfileState {
    return {
      ...state,
      requestDeleteLanguage: undefined,
    };
  },
};

export default LanguageReducer;
