import * as PlatformsEffect from "./PlatformsEffect";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as ActionUtility from "utilities/ActionUtility";
import PlatformModel from "./models/PlatformModel";
import { ReduxDispatch } from "models/ReduxProps";

type ActionUnion = undefined | HttpErrorResponseModel | PlatformModel[] | string;

export const REQUEST_PLATFORMS: string = "PlaformsAction.REQUEST_PLATFORMS";
export const REQUEST_PLATFORMS_FINISHED: string = "PlaformsAction.REQUEST_PLATFORMS_FINISHED";

export const requestPlatforms = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<PlatformModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PLATFORMS,
      PlatformsEffect.requestPlatforms,
    );
  };
};
