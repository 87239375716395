import RouteEnum from "constants/RouteEnum";
import ProjectBaseModel from "stores/projects/models/ProjectBaseModel";
import ProjectPhotoChannelModel from "../../models/ProjectPhotoChannelModel";
import IProjectProductionCompanyModel from "../../models/IProjectClientCompanyModel";

type Nullable<T> = T | null;

export default class CommercialProjectModel extends ProjectBaseModel {
  public readonly client_name: string = "";
  public readonly brand_name: string = "";
  public readonly pre_ppm_date: Nullable<string> = null;
  public readonly ppm_date: Nullable<string> = null;
  public readonly copyright_period: string = "";
  public readonly num_main_film: Nullable<number> = null;
  public readonly num_short_version: Nullable<number> = null;
  public readonly commercial_script: string = "";
  public readonly has_photo_shooting: boolean = false;
  public readonly client: Nullable<IProjectProductionCompanyModel> = null;
  public readonly photo_channels: ProjectPhotoChannelModel[] = [ProjectPhotoChannelModel as any];
  public readonly get_create_path: string = "";
  public readonly get_update_path: string = "";

  constructor(commercial_project_model: Partial<CommercialProjectModel>) {
    super(commercial_project_model);
    this.get_create_path = get_create_path();
    this.get_update_path = get_update_path(commercial_project_model?.slug!);
    this.update(commercial_project_model);
  }
}

const get_create_path = (): string => {
  return RouteEnum.CommercialCreate;
};

const get_update_path = (slug: string): string => {
  return RouteEnum.CommercialUpdate.replace(":slug", slug);
};
