import { BaseModel } from "sjs-base-model";

export default class ProductionProjectModel extends BaseModel {
  public readonly slug: string = "";
  public readonly title: string = "";
  public readonly created: string = "";
  public readonly logo: string | null = null;
  public readonly type: string = "";
  public readonly status: string = "";

  constructor(data: Partial<ProductionProjectModel>) {
    super();

    this.update(data);
  }
}
