import { BaseModel } from "sjs-base-model";

export default class LanguageModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly level: string = "";
  public readonly language: string = "";
  public readonly scores: string = "";
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<LanguageModel>) {
    super();

    this.update(data);
  }
}
