import * as RoleInvitationsAction from "./RoleInvitationsAction";
import IProjectState from "../../models/IProjectsState";
import IAction from "models/IAction";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";

const RoleInvitationsReducer = {
  [RoleInvitationsAction.FILTER_INVITE_ACTORS_FOR_ROLE_SHOW](
    state: IProjectState,
    action: IAction<boolean>,
  ): IProjectState {
    return {
      ...state,
      projectsFilter: {
        ...state.projectsFilter,
        filtersShow: action.payload!,
      },
    };
  },

  [RoleInvitationsAction.REQUEST_CREATE_ROLE_INVITATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleInvitations: [...state.projectRoles.roleInvitations, action.payload!],
        createdRoleInvitation: "Done",
      },
    };
  },

  [RoleInvitationsAction.CLEAR_CREATE_ROLE_INVITATION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        createdRoleInvitation: undefined,
      },
    };
  },

  [RoleInvitationsAction.REQUEST_WITHDRAW_ROLE_INVITATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleInvitations: action.payload!,
        withdrawRoleInvitation: "Done",
      },
    };
  },

  [RoleInvitationsAction.CLEAR_CREATE_WITHDRAW_ROLE_INVITATION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        withdrawRoleInvitation: undefined,
      },
    };
  },

  [RoleInvitationsAction.REQUEST_RESEND_ROLE_INVITATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleInvitations: action.payload!,
        resendRoleInvitation: "Done",
      },
    };
  },

  [RoleInvitationsAction.CLEAR_CREATE_RESEND_ROLE_INVITATION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        resendRoleInvitation: undefined,
      },
    };
  },

  [RoleInvitationsAction.REQUEST_ROLE_INVITATIONS_FINISHED](state: IProjectState, action: IAction<any>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleInvitations: action.payload!,
      },
    };
  },

  [RoleInvitationsAction.CLEAR_REQUEST_ROLE_INVITATIONS](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleInvitations: [],
      },
    };
  },
};

export default RoleInvitationsReducer;
