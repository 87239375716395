import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import { ReduxDispatch } from "models/ReduxProps";
import LightRoleModel from "models/api-models/LightRoleModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import * as ActionUtility from "utilities/ActionUtility";
import RoleModel from "../roles/models/RoleModel";
import * as RolePaymentsEffect from "./RolePaymentsEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | RoleApplicationModel
  | RoleModel
  | RoleApplicationModel[]
  | LightRoleModel[];

export const REQUEST_PRODUCTION_APPLICATIONS: string = "RolePaymentsAction.REQUEST_PRODUCTION_APPLICATIONS";
export const REQUEST_PRODUCTION_APPLICATIONS_FINISHED: string =
  "RolePaymentsAction.REQUEST_PRODUCTION_APPLICATIONS_FINISHED";

export const requestProductionApplications = (slug: string, role_uuid?: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PRODUCTION_APPLICATIONS,
      RolePaymentsEffect.requestProductionApplications,
      slug,
      role_uuid,
    );
  };
};

export const REQUEST_CAST_APPLICATIONS: string = "RolePaymentsAction.REQUEST_CAST_APPLICATIONS";
export const REQUEST_CAST_APPLICATIONS_FINISHED: string = "RolePaymentsAction.REQUEST_CAST_APPLICATIONS_FINISHED";

export const requestCastApplications = (slug: string, role_uuid?: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_APPLICATIONS,
      RolePaymentsEffect.requestCastApplications,
      slug,
      role_uuid,
    );
  };
};

export const CLEAR_APPLICATIONS: string = "RolePaymentsAction.CLEAR_APPLICATIONS";

export const clearApplications = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_APPLICATIONS);
};

export const SHOW_PAYMENT_MODAL: string = "RolePaymentsAction.SHOW_PAYMENT_MODAL";

export const showPaymentModal = (): IAction<undefined> => {
  return ActionUtility.createAction(SHOW_PAYMENT_MODAL);
};

export const HIDE_PAYMENT_MODAL: string = "RolePaymentsAction.HIDE_PAYMENT_MODAL";

export const hidePaymentModal = (): IAction<undefined> => {
  return ActionUtility.createAction(HIDE_PAYMENT_MODAL);
};

export const REQUEST_ROLE_APPLICATION: string = "RolePaymentsAction.REQUEST_ROLE_APPLICATION";
export const REQUEST_ROLE_APPLICATION_FINISHED: string = "RolePaymentsAction.REQUEST_ROLE_APPLICATION_FINISHED";

export const requestRoleApplication = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATION,
      RolePaymentsEffect.requestRoleApplication,
      uuid,
    );
  };
};

export const CLEAR_ROLE_APPLICATION: string = "RolePaymentsAction.CLEAR_ROLE_APPLICATION";

export const clearApplication = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_ROLE_APPLICATION);
};

export const REQUEST_ROLE_DETAIL: string = "RolePaymentsAction.REQUEST_ROLE_DETAIL";
export const REQUEST_ROLE_DETAIL_FINISHED: string = "RolePaymentsAction.REQUEST_ROLE_DETAIL_FINISHED";

export const requestRoleDetail = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_DETAIL,
      RolePaymentsEffect.requestRoleDetail,
      uuid,
    );
  };
};

export const CLEAR_ROLE_DETAIL: string = "RolePaymentsAction.CLEAR_ROLE_DETAIL";

export const clearRoleDetail = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_ROLE_DETAIL);
};

export const REQUEST_PAY_TO_CAST_AGENCY: string = "RolePaymentsAction.REQUEST_PAY_TO_CAST_AGENCY";
export const REQUEST_PAY_TO_CAST_AGENCY_FINISHED: string = "RolePaymentsAction.REQUEST_PAY_TO_CAST_AGENCY_FINISHED";

export const requestPayToCastAgency = (uuid: string, formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PAY_TO_CAST_AGENCY,
      RolePaymentsEffect.requestPayToCastAgency,
      uuid,
      formData,
    );
  };
};

export const CLEAR_REQUEST_PAY_TO_CAST_AGENCY: string = "RolePaymentsAction.CLEAR_REQUEST_PAY_TO_CAST_AGENCY";

export const clearRequestPayToCastAgency = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_PAY_TO_CAST_AGENCY);
};

export const REQUEST_PAY_TO_ACTOR: string = "RolePaymentsAction.REQUEST_PAY_TO_ACTOR";
export const REQUEST_PAY_TO_ACTOR_FINISHED: string = "RolePaymentsAction.REQUEST_PAY_TO_ACTOR_FINISHED";

export const requestPayToActor = (uuid: string, formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PAY_TO_ACTOR,
      RolePaymentsEffect.requestPayToActor,
      uuid,
      formData,
    );
  };
};

export const CLEAR_REQUEST_PAY_TO_ACTOR: string = "RolePaymentsAction.CLEAR_REQUEST_PAY_TO_ACTOR";

export const clearRequestPayToActor = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_PAY_TO_ACTOR);
};

export const REQUEST_CAST_PAYMENTS_ROLES: string = "RolePaymentsAction.REQUEST_CAST_PAYMENTS_ROLES";
export const REQUEST_CAST_PAYMENTS_ROLES_FINISHED: string = "RolePaymentsAction.REQUEST_CAST_PAYMENTS_ROLES_FINISHED";

export const requestCastPaymentsRoles = (slug: string, project_slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<LightRoleModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CAST_PAYMENTS_ROLES,
      RolePaymentsEffect.requestCastPaymentsRoles,
      slug,
      project_slug,
    );
  };
};

export const CLEAR_PAYMENTS_ROLES: string = "RolePaymentsAction.CLEAR_PAYMENTS_ROLES";

export const clearPaymentsRoles = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_PAYMENTS_ROLES);
};

export const REQUEST_PRODUCTION_PAYMENTS_ROLES: string = "RolePaymentsAction.REQUEST_PRODUCTION_PAYMENTS_ROLES";
export const REQUEST_PRODUCTION_PAYMENTS_ROLES_FINISHED: string =
  "RolePaymentsAction.REQUEST_PRODUCTION_PAYMENTS_ROLES_FINISHED";

export const requestProductionPaymentsRoles = (slug: string, project_slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<LightRoleModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PRODUCTION_PAYMENTS_ROLES,
      RolePaymentsEffect.requestProductionPaymentsRoles,
      slug,
      project_slug,
    );
  };
};
