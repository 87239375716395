import { AxiosResponse } from "axios";
import RoleApplicationStatusEnum from "constants/RoleApplicationStatusEnum";
import environment from "environment";
import { groupBy } from "lodash";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import LightRoleModel from "models/api-models/LightRoleModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import * as EffectUtility from "utilities/EffectUtility";
import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";
import RoleModel from "../roles/models/RoleModel";

export const requestProductionApplications = async (
  slug: string,
  role_uuid?: string,
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  let endpoint: string =
    environment.api.companies.productions.roleapplications.replace(":slug", slug) +
    "?status=" +
    RoleApplicationStatusEnum.Finalized;

  if (role_uuid) {
    endpoint += "&role=" + role_uuid;
  }

  return EffectUtility.getToModel<RoleApplicationModel[]>(RoleApplicationModel, endpoint);
};

export const requestCastApplications = async (
  cast_slug: string,
  role_uuid?: string,
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  let endpoint: string =
    environment.api.companies.casts.roleapplications.replace(":slug", cast_slug) +
    "?status=" +
    RoleApplicationStatusEnum.Finalized;

  if (role_uuid) {
    endpoint += "&role=" + role_uuid;
  }

  return EffectUtility.getToModel<RoleApplicationModel[]>(RoleApplicationModel, endpoint);
};

export const requestRoleApplication = async (uuid: string): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.roleapplication.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return new RoleApplicationModel(response.data);
};

export const requestRoleDetail = async (role_uuid: string): Promise<RoleModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.index + `roles/${role_uuid}/`;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new RoleModel(response.data);
};

export const requestPayToCastAgency = async (
  uuid: string,
  formData: any,
): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.pay_to_cast_agency.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new RoleApplicationModel(response.data);
};

export const requestPayToActor = async (
  uuid: string,
  formData: any,
): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.pay_to_actor.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new RoleApplicationModel(response.data);
};

export const requestCastPaymentsRoles = async (
  cast_slug: string,
  project_slug: string,
): Promise<LightRoleModel[] | HttpErrorResponseModel> => {
  let endpoint: string =
    environment.api.companies.casts.roleapplications.replace(":slug", cast_slug) +
    "?status=" +
    RoleApplicationStatusEnum.Finalized;

  if (project_slug) {
    endpoint += "&project_slug=" + project_slug;
  }

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const roleApplications: RoleApplicationModel[] = response.data.map((item: any) => new RoleApplicationModel(item));

  const allRoles: LightRoleModel[] = roleApplications.map((item: RoleApplicationModel) => item.role);

  // allRoles leri uuid leri aynı olanları tek e düşür
  const roles: LightRoleModel[] = Object.values(groupBy(allRoles, (item: LightRoleModel) => item.uuid)).map(
    (group: LightRoleModel[]) => group[0],
  );

  return roles;
};

export const requestProductionPaymentsRoles = async (
  slug: string,
  project_slug?: string,
): Promise<LightRoleModel[] | HttpErrorResponseModel> => {
  let endpoint: string =
    environment.api.companies.productions.roleapplications.replace(":slug", slug) +
    "?status=" +
    RoleApplicationStatusEnum.Finalized;

  if (project_slug) {
    endpoint += "&project_slug=" + project_slug;
  }

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const roleApplications: RoleApplicationModel[] = response.data.map((item: any) => new RoleApplicationModel(item));

  const allRoles: LightRoleModel[] = roleApplications.map((item: RoleApplicationModel) => item.role);

  // allRoles leri uuid leri aynı olanları tek e düşür
  const roles: LightRoleModel[] = Object.values(groupBy(allRoles, (item: LightRoleModel) => item.uuid)).map(
    (group: LightRoleModel[]) => group[0],
  );

  return roles;
};
