import IAction from "models/IAction";
import IPagination from "models/IPagination";
import CastAgencyModel from "./models/CastAgencyModel";
import ICompaniesState from "../models/ICompainesState";
import * as CastAgenciesAction from "./CastAgenciesAction";
import CastAgencyActorModel from "./models/actor/CastAgencyActorModel";

const CastAgenciesReducer = {
  [CastAgenciesAction.REQUEST_CAST_AGENCIES_FINISHED](
    state: ICompaniesState,
    action: IAction<IPagination<CastAgencyModel[]>>,
  ): ICompaniesState {
    return {
      ...state,
      castAgencies: action.payload!,
    };
  },

  [CastAgenciesAction.CLEAR_CAST_AGENCIES](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      castAgencies: undefined,
    };
  },

  [CastAgenciesAction.REQUEST_CAST_AGENCY_FINISHED](
    state: ICompaniesState,
    action: IAction<CastAgencyModel>,
  ): ICompaniesState {
    return {
      ...state,
      castAgencyDetail: action.payload!,
    };
  },

  [CastAgenciesAction.CLEAR_CAST_AGENCY_DETAIL](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      castAgencyDetail: undefined,
    };
  },

  [CastAgenciesAction.REQUEST_CREATE_CAST_AGENCY_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestCreateCastAgency: action.payload,
    };
  },

  [CastAgenciesAction.CLEAR_REQUEST_CREATE_CAST_AGENCY](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      requestCreateCastAgency: undefined,
    };
  },

  [CastAgenciesAction.REQUEST_UPDATE_CAST_AGENCY_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestUpdateCastAgency: action.payload,
    };
  },

  [CastAgenciesAction.CLEAR_REQUEST_UPDATE_CAST_AGENCY](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      requestUpdateCastAgency: undefined,
    };
  },

  [CastAgenciesAction.REQUEST_CAST_AGENCY_ACTORS_FINISHED](
    state: ICompaniesState,
    action: IAction<CastAgencyActorModel[]>,
  ): ICompaniesState {
    return {
      ...state,
      castAgencyActors: action.payload!,
    };
  },

  [CastAgenciesAction.REQUEST_CAST_LIKED_BY_ME_FINISHED](
    state: ICompaniesState,
    action: IAction<CastAgencyActorModel | undefined>,
  ): ICompaniesState {
    return {
      ...state,
      castAgencyLikedByMe: action.payload,
    };
  },

  [CastAgenciesAction.REQUEST_ACTOR_LIKE_TO_CAST_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestActorLikeCast: action.payload,
    };
  },

  [CastAgenciesAction.REQUEST_ACTOR_UNLIKE_TO_CAST_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestActorLikeCast: action.payload,
    };
  },
};

export default CastAgenciesReducer;
