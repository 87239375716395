import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import * as HttpUtility from "../../utilities/HttpUtility";
import { AxiosResponse } from "axios";
import { SelectErrors as SelectErrorHelper } from "helper/ResponseErrorHelper";

export const requestRegister = async (
  userName: string,
  email: string,
  password1: string,
  password2: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.register.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, {
    userName,
    email,
    password1,
    password2,
  });

  if (response instanceof HttpErrorResponseModel) {
    response.message = SelectErrorHelper(response);
    return response;
  }

  return response.data.detail;
};

export const requestVerifyEmail = async (key: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.register.verify_email;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, { key: key });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.detail;
};

export const requestResetPassword = async (email: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.password.reset;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, { email: email });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.detail;
};

export const requestResendEmail = async (email: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.register.resend_email;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, { email: email });

  if (response instanceof HttpErrorResponseModel) {
    response.message = response.raw.data[0];
    return response;
  }
  return response.data.detail;
};

export const confirmResetPassword = async (
  uid: string,
  token: string,
  new_password1: string,
  new_password2: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.password.confirm;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, {
    new_password1: new_password1,
    new_password2: new_password2,
    uid: uid,
    token: token,
  });

  if (response instanceof HttpErrorResponseModel) {
    let errorsArray: any = Object.values(response.raw.data);
    response.message = errorsArray[0][0];
    return response;
  }

  return response.data.detail;
};

/**
 * This is only to trigger an error api response so we can use it for an example in the AboutPage
 */
export const requestError = async (): Promise<any | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.errorExamplePage;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data;
};
