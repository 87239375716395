import React from "react";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import IToast from "stores/toasts/models/IToast";
import * as ToastsAction from "stores/toasts/ToastsAction";

interface IProps {
  readonly item: IToast;
}

const ToastCard: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const dispatch: Dispatch = useDispatch();

  const RemoveNotification = () => {
    dispatch(ToastsAction.removeById(props.item.id));
  };

  const addToast = () => {
    toast(props.item.message, {
      toastId: props.item.id,
      onClose: () => RemoveNotification(),
      type: props.item.type,
    });
  };

  if (!toast.isActive(props.item.id)) {
    addToast();
  }

  return null;
};

export default ToastCard;
