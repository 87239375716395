import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import { AxiosResponse } from "axios";
import PlatformModel from "./models/PlatformModel";

export const requestPlatforms = async (): Promise<PlatformModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.platforms;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.results.map((json: Partial<PlatformModel>) => new PlatformModel(json));
};
