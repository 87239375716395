import { AxiosRequestConfig } from "axios";
import { getCookieLang, getCurrentCookieToken } from "./CookieUtility";

const _getTokenConfig = (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
  const token: string | Object | null = getCurrentCookieToken();
  if (token === null) {
    return requestConfig;
  }
  //if token exist
  if (token) {
    //if requestConfig exist
    if (!requestConfig?.headers) {
      return { ...requestConfig, headers: { Authorization: `Token ${token}` } };
    } else {
      //requestConfig does not exist
      return requestConfig;
    }
  }
  return requestConfig;
};

const _getLanguageConfig = (headers: any): Object => {
  const lang: string | null = getCookieLang();
  if (lang) {
    return { ...headers, ...{ "Accept-Language": `${lang}` } };
  } else {
    return headers;
  }
};

export const SetConfig = (requestConfig: AxiosRequestConfig | undefined): AxiosRequestConfig => {
  if (requestConfig === undefined) {
    requestConfig = {};
  }
  requestConfig = _getTokenConfig(requestConfig);

  requestConfig.headers = _getLanguageConfig(requestConfig.headers);

  return requestConfig;
};
