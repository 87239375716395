import environment from "environment";
import { AxiosResponse } from "axios";

import IPagination from "models/IPagination";
import ClientModel from "./models/ClientModel";
import * as HttpUtility from "utilities/HttpUtility";
import * as EffectUtility from "utilities/EffectUtility";
import ClientOptionsModel from "./models/ClientOptionsModel";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";

export const requestClientOptions = async (): Promise<ClientOptionsModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.clients.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.options(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new ClientOptionsModel(response.data);
};

export const requestClientCompanies = async (
  search: string,
  page?: number,
): Promise<IPagination<ClientModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.companies.clients.index.concat("?search=", search);

  if (page) {
    endpoint = endpoint.concat("&page=", page.toString());
  }

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const result: IPagination<ClientModel[]> = {
    currentPage: page || 1,
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<ClientModel>) => new ClientModel(json)),
  };

  return result;
};

export const requestClientCompany = async (slug: string): Promise<ClientModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.clients.client.replace(":slug", slug);

  return EffectUtility.getToModel<ClientModel>(ClientModel, endpoint);
};

export const requestCreateClientCompany = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.clients.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.slug;
};

export const requestUpdateClientCompany = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.clients.client.replace(":slug", slug);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.slug;
};
