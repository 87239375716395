import { Reducer } from "redux";

import CookieEnum from "constants/CookieEnum";
import IAction from "models/IAction";
import IPagination from "models/IPagination";
import NotificationModel from "models/api-models/NotificationModel";
import IUserCookieModel from "models/common-models/IUserCookieModel";
import baseReducer from "utilities/BaseReducer";
import { getCurrentCookieToken, getCurrentCookieUser, storeDataToCookie } from "utilities/CookieUtility";
import { storeLastLoggedUser } from "utilities/UserProfileRoleUtility";
import * as AuthAction from "./AuthAction";
import * as LogoutAction from "./logout/LogoutAction";
import IAuthState from "./models/IAuthState";
import * as PasswordAction from "./password/PasswordAction";

export const initialState: IAuthState = {
  user: null,
  token: null,
  selectedProfile: undefined,
  redirectPath: undefined,
  refreshToken: undefined,
  switchProfileModal: false,
  redirectComponent: undefined,
  loginSuccess: undefined,
  inviteMemberMessage: undefined,
  invitedEmail: undefined,
  checkInvitedSuccessMessage: undefined,
  changePasswordSuccess: undefined,
  redirectMessage: undefined,
  changedUserLanguageSuccess: undefined,
};

const authReducer: Reducer = baseReducer(initialState, {
  [AuthAction.REQUEST_LOGIN_FINISHED](state: IAuthState, action: IAction<string>): IAuthState {
    storeDataToCookie(CookieEnum.Token, action.payload!);
    return {
      ...state,
      token: action.payload!,
    };
  },

  [AuthAction.REQUEST_LOGIN_GOOGLE_FINISHED](state: IAuthState, action: IAction<string>): IAuthState {
    storeDataToCookie(CookieEnum.Token, action.payload!);
    return {
      ...state,
      token: action.payload!,
    };
  },

  [AuthAction.REQUEST_GET_CURRENT_USER_FINISHED](state: IAuthState, action: IAction<IUserCookieModel>): IAuthState {
    storeDataToCookie(CookieEnum.User, action.payload!);
    storeLastLoggedUser(action.payload!.username);
    return {
      ...state,
      user: action.payload!,
    };
  },

  [AuthAction.REQUEST_GET_USER_ME_FINISHED](state: IAuthState, action: IAction<IUserCookieModel>): IAuthState {
    storeDataToCookie(CookieEnum.User, action.payload!);
    storeLastLoggedUser(action.payload!.username);
    return {
      ...state,
      user: action.payload!,
      loginSuccess: true,
    };
  },

  [PasswordAction.REQUEST_PASSWORD_CHANGE_FINISHED](
    state: IAuthState,
    action: IAction<{ status: boolean; message: string }>,
  ): IAuthState {
    return {
      ...state,
      changePasswordSuccess: action.payload,
    };
  },

  [PasswordAction.CLEAR_CHANGE_PASSWORD_SUCCESS](state: IAuthState): IAuthState {
    return {
      ...state,
      changePasswordSuccess: undefined,
    };
  },

  [AuthAction.CLEAR_LOGIN_SUCCESS](state: IAuthState): IAuthState {
    return {
      ...state,
      loginSuccess: undefined,
    };
  },

  //todo - this model set but not look like IUserCookieModel, check it
  [AuthAction.SET_CURRENT_USER](state: IAuthState): IAuthState {
    let cookieUser = getCurrentCookieUser();
    return {
      ...state,
      user: cookieUser,
    };
  },

  [AuthAction.SET_TOKEN](state: IAuthState): IAuthState {
    let cookieToken = getCurrentCookieToken();
    return {
      ...state,
      token: cookieToken,
    };
  },

  // Logout Action
  [LogoutAction.REQUEST_LOGOUT_FINISHED](state: IAuthState, action: IAction<string>): IAuthState {
    return {
      ...state,
      token: null,
      user: null,
      selectedProfile: undefined,
    };
  },

  //SET_SELECTED_PROFILE_FINISHED
  [AuthAction.SET_SELECTED_PROFILE_FINISHED](state: IAuthState, action: IAction<string>): IAuthState {
    return {
      ...state,
      selectedProfile: action.payload!,
    };
  },

  [AuthAction.REMOVE_SELECTED_PROFILE_FINISHED](state: IAuthState, action: IAction<undefined>): IAuthState {
    return {
      ...state,
      selectedProfile: undefined,
    };
  },

  [AuthAction.SET_REDIRECT_PATH](state: IAuthState, action: IAction<string>): IAuthState {
    return {
      ...state,
      redirectPath: action.payload!,
    };
  },

  [AuthAction.REMOVE_REDIRECT_PATH](state: IAuthState): IAuthState {
    return {
      ...state,
      redirectPath: undefined,
    };
  },

  //REMOVE_USER
  [AuthAction.REMOVE_USER](state: IAuthState): IAuthState {
    return {
      ...state,
      user: null,
    };
  },

  [AuthAction.SET_REDIRECT_COMPONENT](state: IAuthState, action: IAction<any>): IAuthState {
    return {
      ...state,
      redirectComponent: action.payload!,
    };
  },

  [AuthAction.REMOVE_REDIRECT_COMPONENT](state: IAuthState): IAuthState {
    return {
      ...state,
      redirectComponent: undefined,
    };
  },

  [AuthAction.REMOVE_TOKEN](state: IAuthState): IAuthState {
    return {
      ...state,
      token: null,
    };
  },

  [AuthAction.SHOW_PROFILE_SWITCH_MODAL](state: IAuthState): IAuthState {
    return {
      ...state,
      switchProfileModal: true,
    };
  },

  [AuthAction.CLOSE_PROFILE_SWITCH_MODAL](state: IAuthState): IAuthState {
    return {
      ...state,
      switchProfileModal: false,
    };
  },

  [AuthAction.REQUEST_INVITE_MEMBER_FINISHED](state: IAuthState, action: IAction<any>): IAuthState {
    return {
      ...state,
      inviteMemberMessage: action.payload!,
    };
  },

  [AuthAction.CLEAR_REQUEST_INVITE_MEMBER_MESSAGE](state: IAuthState): IAuthState {
    return {
      ...state,
      inviteMemberMessage: undefined,
    };
  },

  [AuthAction.REQUEST_GET_INVITE_CODE_FINISHED](state: IAuthState, action: IAction<string>): IAuthState {
    return {
      ...state,
      invitedEmail: action.payload!,
      checkInvitedSuccessMessage: "success",
    };
  },

  [AuthAction.CLEAR_REQUEST_GET_INVITE_CODE_MESSAGE](state: IAuthState): IAuthState {
    return {
      ...state,
      checkInvitedSuccessMessage: undefined,
    };
  },

  [AuthAction.CLEAR_INVITE_EMAIL](state: IAuthState): IAuthState {
    return {
      ...state,
      invitedEmail: undefined,
    };
  },

  [AuthAction.REQUEST_UPDATE_LANGUAGE_FINISHED](state: IAuthState, action: IAction<string>): IAuthState {
    return {
      ...state,
      changedUserLanguageSuccess: action.payload!,
      user: {
        ...state.user,
        language: action.payload!,
      } as IUserCookieModel,
    };
  },

  [AuthAction.SET_REDIRECT_MESSAGE](state: IAuthState, action: IAction<string | undefined>): IAuthState {
    return {
      ...state,
      redirectMessage: action.payload!,
    };
  },

  [AuthAction.REQUEST_GET_NOTIFICATION_FINISHED](
    state: IAuthState,
    action: IAction<IPagination<NotificationModel[]>>,
  ): IAuthState {
    return {
      ...state,
      notifications: action.payload!,
    };
  },

  [AuthAction.REQUEST_GET_UNSEEN_NOTIFICATION_FINISHED](
    state: IAuthState,
    action: IAction<IPagination<NotificationModel[]>>,
  ): IAuthState {
    return {
      ...state,
      unSeennotifications: action.payload!,
    };
  },

  [AuthAction.REQUEST_SEEN_ALL_NOTIFICATION_FINISHED](state: IAuthState): IAuthState {
    return {
      ...state,
      unSeennotifications: {
        ...state.unSeennotifications,
        count: 0,
        results: [],
      },
    };
  },
});

export default authReducer;
