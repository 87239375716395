import CastAgencyLightModel from "models/api-models/CastAgencyLightModel";
import LightActorModel from "models/api-models/LightActorModel";
import LightProjectModel from "models/api-models/LightProjectModel";
import LightRoleModel from "models/api-models/LightRoleModel";
import { BaseModel } from "sjs-base-model";
import MeetingLocation from "../MeetingLocation";
import RoleApplicationPaymentModel from "../RoleApplicationPaymentModel";
import AuditionVideoModel from "../audition-video/AuditionVideoModel";
import RoleOfferModel from "../role-offer/RoleOfferModel";

export default class RoleApplicationModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly cast_agency: CastAgencyLightModel = CastAgencyLightModel as any;
  public readonly actor: LightActorModel = LightActorModel as any;
  public readonly project: LightProjectModel = LightProjectModel as any;
  public readonly role: LightRoleModel = LightRoleModel as any;
  public readonly audition_videos: AuditionVideoModel[] = [AuditionVideoModel as any];
  public readonly cover_letter: string = "";
  public readonly status: string = "";
  public readonly offers: RoleOfferModel[] = [RoleOfferModel as any];
  public readonly payment: RoleApplicationPaymentModel | null = null;
  public readonly meeting_location: MeetingLocation | null = null;

  constructor(data: Partial<RoleApplicationModel>) {
    super();

    this.update(data);
  }
}
