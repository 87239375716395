import IAction from "models/IAction";
import * as RolePaymentsAction from "./RolePaymentsAction";

import LightRoleModel from "models/api-models/LightRoleModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import IProjectState from "../models/IProjectsState";
import RoleModel from "../roles/models/RoleModel";

const RolePaymentsReducer = {
  [RolePaymentsAction.REQUEST_PRODUCTION_APPLICATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        applications: action.payload!,
      },
    };
  },

  [RolePaymentsAction.REQUEST_CAST_PAYMENTS_ROLES_FINISHED](
    state: IProjectState,
    action: IAction<LightRoleModel[]>,
  ): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        roles: action.payload!,
      },
    };
  },

  [RolePaymentsAction.REQUEST_PRODUCTION_PAYMENTS_ROLES_FINISHED](
    state: IProjectState,
    action: IAction<LightRoleModel[]>,
  ): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        roles: action.payload!,
      },
    };
  },

  [RolePaymentsAction.CLEAR_PAYMENTS_ROLES](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        roles: [],
      },
    };
  },

  [RolePaymentsAction.REQUEST_CAST_APPLICATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        applications: action.payload!,
      },
    };
  },

  [RolePaymentsAction.CLEAR_APPLICATIONS](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        applications: [],
      },
    };
  },

  [RolePaymentsAction.SHOW_PAYMENT_MODAL](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        showPaymentModal: true,
      },
    };
  },

  [RolePaymentsAction.HIDE_PAYMENT_MODAL](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        showPaymentModal: false,
      },
    };
  },

  [RolePaymentsAction.REQUEST_ROLE_APPLICATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        applicationDetail: action.payload!,
      },
    };
  },

  [RolePaymentsAction.CLEAR_ROLE_APPLICATION](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        applicationDetail: undefined,
      },
    };
  },

  [RolePaymentsAction.REQUEST_ROLE_DETAIL_FINISHED](state: IProjectState, action: IAction<RoleModel>): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        roleDetail: action.payload!,
      },
    };
  },

  [RolePaymentsAction.CLEAR_ROLE_DETAIL](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        roleDetail: undefined,
      },
    };
  },

  [RolePaymentsAction.REQUEST_PAY_TO_ACTOR_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        applications: state.rolePaymentsState.applications!.map((application: RoleApplicationModel) => {
          if (application.uuid === action.payload!.uuid) {
            return action.payload!;
          }
          return application;
        }),
        applicationDetail: action.payload!,
        requestPayToActor: "success",
      },
    };
  },

  [RolePaymentsAction.REQUEST_PAY_TO_CAST_AGENCY_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        applications: state.rolePaymentsState.applications!.map((application: RoleApplicationModel) => {
          if (application.uuid === action.payload!.uuid) {
            return action.payload!;
          }
          return application;
        }),
        applicationDetail: action.payload!,
        requestPayToCast: "success",
      },
    };
  },

  [RolePaymentsAction.CLEAR_REQUEST_PAY_TO_ACTOR](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        requestPayToActor: undefined,
      },
    };
  },

  [RolePaymentsAction.CLEAR_REQUEST_PAY_TO_CAST_AGENCY](state: IProjectState): IProjectState {
    return {
      ...state,
      rolePaymentsState: {
        ...state.rolePaymentsState,
        requestPayToCast: undefined,
      },
    };
  },
};

export default RolePaymentsReducer;
