import React from "react";
import { useHistory } from "react-router-dom";
import CastelectLogo from "views/components/castelect-logo/CastelectLogo";

interface IProps {
  redirectToPath?: string;
}

const NavItemComponent: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { redirectToPath } = props;

  const handleRedirect = (event: React.MouseEvent) => {
    event.preventDefault();
    if (redirectToPath) {
      history.push(redirectToPath);
    }
  };

  return (
    <div
      className="nav-item d-none d-sm-block"
      onClick={handleRedirect}
      style={redirectToPath ? { cursor: "pointer" } : {}}
    >
      <div className="header-logo">
        <CastelectLogo height={28} />
      </div>
    </div>
  );
};

export default NavItemComponent;
