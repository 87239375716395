import IAction from "models/IAction";
import IPagination from "models/IPagination";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import IResponseExperienceModel from "models/form/actor/IExperienceModel";
import IResponseActorEducationModel from "models/form/actor/IResponseActorEducationModel";
import IResponseAvailabilityModel from "models/form/actor/IResponseAvailabilityModel";
import { Reducer } from "redux";
import CastAgencyModel from "stores/companies/cast-agencies/models/CastAgencyModel";
import baseReducer from "utilities/BaseReducer";
import * as ActorsAction from "./ActorsAction";
import IActorsState from "./models/IActorsState";
import ActorModel from "./models/actors/ActorModel";
import ActorReferenceModel from "./models/actors/models/ActorReferenceModel";
import ActorChildModel from "./models/actors/models/actor-child/ActorChildModel";
import * as SkillsAction from "./skills/SkillsAction";
import SkillModel from "./skills/models/SkillModel";
import * as TagsAction from "./tags/TagsAction";
import TagModel from "./tags/models/TagModel";

export const initialState: IActorsState = {
  actors: null,
  tags: [],
  skills: [],
  actorDetail: null,
  actorCastAgencies: [],
  roleInvitation: [],

  actorLikedByMe: undefined,
  requestCastLikeActor: undefined,
  requestCreateActorProfile: undefined,
  requestDeletedActorVideo: undefined,
  requestUploadActorVideo: undefined,
  requestActorEvaluation: undefined,
  requestUpdateActor: undefined,
  requestUpdateActorAvailability: undefined,
  requestUpdateActorExperience: undefined,
  requestUpdateActorAbout: undefined,

  requestCreateActorChildren: undefined,
  requestUpdateActorChildren: undefined,
  requestDeleteActorChildren: undefined,

  requestUpdateActorHobbies: undefined,
  requestUpdateActorEducation: undefined,
  requestUpdateActorSkills: undefined,

  requestCreateActorReference: undefined,
  requestUpdateActorReference: undefined,
  requestDeleteActorReference: undefined,

  requestUploadedActorPhoto: undefined,
  requestDeletedActorPhotos: undefined,
};

const actorsReducer: Reducer = baseReducer(initialState, {
  [ActorsAction.REQUEST_SEARCH_ACTORS_FINISHED](
    state: IActorsState,
    action: IAction<IPagination<ActorModel[]>>,
  ): IActorsState {
    return {
      ...state,
      actors: action.payload!,
    };
  },

  [ActorsAction.CLEAR_ACTORS](state: IActorsState): IActorsState {
    return {
      ...state,
      actors: null,
    };
  },

  [ActorsAction.REQUEST_CREATE_ACTOR_PROFILE_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestCreateActorProfile: action.payload!,
    };
  },

  [ActorsAction.CLEAR_REQUEST_CREATE_ACTOR_PROFILE](state: IActorsState): IActorsState {
    return {
      ...state,
      requestCreateActorProfile: undefined,
    };
  },

  [ActorsAction.REQUEST_ACTOR_FINISHED](state: IActorsState, action: IAction<ActorModel>): IActorsState {
    return {
      ...state,
      actorDetail: action.payload!,
    };
  },

  [ActorsAction.REQUEST_FIND_FINISHED](state: IActorsState, action: IAction<ActorModel>): IActorsState {
    return {
      ...state,
      actorDetail: action.payload!,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_PROFILE_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestUpdateActor: action.payload!,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_PROFILE](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActor: undefined,
    };
  },

  [ActorsAction.ACTOR_DETAIL_EMPTY](state: IActorsState): IActorsState {
    return {
      ...state,
      actorDetail: null,
    };
  },

  [ActorsAction.REQUEST_ACTOR_CASTS_FINISHED](state: IActorsState, action: IAction<CastAgencyModel[]>): IActorsState {
    return {
      ...state,
      actorCastAgencies: action.payload!,
    };
  },

  [ActorsAction.ACTOR_CAST_EMPTY](state: IActorsState): IActorsState {
    return {
      ...state,
      actorCastAgencies: [],
    };
  },

  [ActorsAction.REQUEST_ACTOR_LIKED_BY_ME_FINISHED](
    state: IActorsState,
    action: IAction<CastAgencyModel | undefined>,
  ): IActorsState {
    return {
      ...state,
      actorLikedByMe: action.payload,
    };
  },

  [ActorsAction.REQUEST_CAST_LIKE_TO_ACTOR_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestCastLikeActor: action.payload,
    };
  },

  [ActorsAction.REQUEST_CAST_UNLIKE_TO_ACTOR_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestCastLikeActor: action.payload,
    };
  },

  [ActorsAction.REQUEST_DELETE_ACTOR_VIDEO_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestDeletedActorVideo: action.payload,
    };
  },

  [ActorsAction.EMPTY_DELETE_ACTOR_VIDEO](state: IActorsState): IActorsState {
    return {
      ...state,
      requestDeletedActorVideo: undefined,
    };
  },

  [ActorsAction.REQUEST_UPLOAD_ACTOR_VIDEO_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestUploadActorVideo: action.payload,
    };
  },

  [ActorsAction.EMPTY_UPLOAD_ACTOR_VIDEO](state: IActorsState): IActorsState {
    return {
      ...state,
      requestDeletedActorVideo: undefined,
      requestUploadActorVideo: undefined,
    };
  },

  [ActorsAction.REQUEST_ACTOR_EVALUATION_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestActorEvaluation: action.payload,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_EVALUATION_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestActorEvaluation: action.payload,
    };
  },

  [ActorsAction.EMPTY_ACTOR_EVALUATION](state: IActorsState): IActorsState {
    return {
      ...state,
      requestActorEvaluation: undefined,
    };
  },

  [ActorsAction.REQUEST_ACTOR_INVITATIONS_FINISHED](
    state: IActorsState,
    action: IAction<RoleInvitationModel[]>,
  ): IActorsState {
    return {
      ...state,
      roleInvitation: action.payload!,
    };
  },

  [ActorsAction.CLEAR_ACTOR_INVITATIONS](state: IActorsState): IActorsState {
    return {
      ...state,
      roleInvitation: [],
    };
  },

  [ActorsAction.REQUEST_ACCEPT_ROLE_INVITATION_FINISHED](
    state: IActorsState,
    action: IAction<RoleInvitationModel[]>,
  ): IActorsState {
    return {
      ...state,
      roleInvitation: action.payload!,
    };
  },

  [ActorsAction.REQUEST_DECLINE_ROLE_INVITATION_FINISHED](
    state: IActorsState,
    action: IAction<RoleInvitationModel[]>,
  ): IActorsState {
    return {
      ...state,
      roleInvitation: action.payload!,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_AVAILABILITY_FINISHED](
    state: IActorsState,
    action: IAction<IResponseAvailabilityModel>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestUpdateActorAvailability: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          ...action.payload!,
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_AVAILABILITY](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorAvailability: undefined,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_EXPERIENCE_FINISHED](
    state: IActorsState,
    action: IAction<IResponseExperienceModel>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestUpdateActorExperience: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          ...action.payload!,
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_EXPERIENCE](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorExperience: undefined,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_ABOUT_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestUpdateActorAbout: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          ...{ description: action.payload! },
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_ABOUT](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorAbout: undefined,
    };
  },

  [ActorsAction.REQUEST_CREATE_ACTOR_CHILDREN_FINISHED](
    state: IActorsState,
    action: IAction<ActorChildModel>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestCreateActorChildren: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          children: [...state.actorDetail.children, action.payload!],
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.REQUEST_UPLOAD_ACTOR_PHOTO_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestUploadedActorPhoto: action.payload!,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPLOADED_ACTOR_PHOTO_FINISHED](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUploadedActorPhoto: undefined,
    };
  },

  // BEGIN CHILDREN ACTIONS
  [ActorsAction.CLEAR_REQUEST_CREATE_ACTOR_CHILDREN](state: IActorsState): IActorsState {
    return {
      ...state,
      requestCreateActorChildren: undefined,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_CHILDREN_FINISHED](
    state: IActorsState,
    action: IAction<ActorChildModel[]>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestUpdateActorChildren: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          children: action.payload!,
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_CHILDREN](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorChildren: undefined,
    };
  },

  [ActorsAction.REQUEST_DELETE_ACTOR_CHILDREN_FINISHED](
    state: IActorsState,
    action: IAction<ActorChildModel[]>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestDeleteActorChildren: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          children: action.payload!,
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_DELETE_ACTOR_CHILDREN](state: IActorsState): IActorsState {
    return {
      ...state,
      requestDeleteActorChildren: undefined,
    };
  },
  // END CHILDREN ACTIONS

  [ActorsAction.REQUEST_UPDATE_ACTOR_HOBBIES_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestUpdateActorHobbies: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          ...{ hobbies: action.payload! },
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_HOBBIES](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorHobbies: undefined,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_EDUCATION_FINISHED](
    state: IActorsState,
    action: IAction<IResponseActorEducationModel>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestUpdateActorEducation: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          ...action.payload!,
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_EDUCATION](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorEducation: undefined,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_SKILLS_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestUpdateActorSkills: action.payload!,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_SKILLS](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorSkills: undefined,
    };
  },

  // BEGIN CHILDREN REFERENCE ACTIONS
  [ActorsAction.REQUEST_CREATE_ACTOR_REFERENCE_FINISHED](
    state: IActorsState,
    action: IAction<ActorReferenceModel>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestCreateActorReference: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          references: [...state.actorDetail.references, action.payload!],
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_CREATE_ACTOR_REFERENCE](state: IActorsState): IActorsState {
    return {
      ...state,
      requestCreateActorReference: undefined,
    };
  },

  [ActorsAction.REQUEST_UPDATE_ACTOR_REFERENCE_FINISHED](
    state: IActorsState,
    action: IAction<ActorReferenceModel[]>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestUpdateActorReference: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          references: action.payload!,
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_UPDATE_ACTOR_REFERENCE](state: IActorsState): IActorsState {
    return {
      ...state,
      requestUpdateActorReference: undefined,
    };
  },

  [ActorsAction.REQUEST_DELETE_ACTOR_REFERENCE_FINISHED](
    state: IActorsState,
    action: IAction<ActorReferenceModel[]>,
  ): IActorsState {
    if (state.actorDetail instanceof ActorModel) {
      return {
        ...state,
        requestDeleteActorReference: "success",
        actorDetail: new ActorModel({
          ...state.actorDetail,
          references: action.payload!,
        }),
      };
    }
    return {
      ...state,
    };
  },

  [ActorsAction.CLEAR_REQUEST_DELETE_ACTOR_REFERENCE](state: IActorsState): IActorsState {
    return {
      ...state,
      requestDeleteActorReference: undefined,
    };
  },

  // END CHILDREN REFERENCE

  //Tags Action

  [TagsAction.REQUEST_SEARCH_ACTOR_TAGS_FINISHED](state: IActorsState, action: IAction<TagModel[]>): IActorsState {
    return {
      ...state,
      tags: action.payload!,
    };
  },

  [SkillsAction.REQUEST_SEARCH_ACTOR_SKILLS_FINISHED](
    state: IActorsState,
    action: IAction<SkillModel[]>,
  ): IActorsState {
    return {
      ...state,
      skills: action.payload!,
    };
  },

  [ActorsAction.REQUEST_DELETE_ACTOR_PHOTOS_FINISHED](state: IActorsState, action: IAction<string>): IActorsState {
    return {
      ...state,
      requestDeletedActorPhotos: action.payload!,
    };
  },

  [ActorsAction.CLEAR_REQUEST_DELETE_ACTOR_PHOTOS](state: IActorsState): IActorsState {
    return {
      ...state,
      requestDeletedActorPhotos: undefined,
    };
  },
});

export default actorsReducer;
