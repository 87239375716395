import { BaseModel } from "sjs-base-model";
import { getCookieLang } from "utilities/CookieUtility";

export default class RoleModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly status: string = "";
  public readonly status_changed: string = "";
  public readonly modified: string = "";
  public readonly name: string = "";
  public readonly slug: string = "";
  public readonly character_name: string = "";
  public readonly description: string = "";
  public readonly audition_text: string = "";
  public readonly cast_type: string = "";
  public readonly num_positions: number = 0;
  public readonly audition_required: boolean = true;
  public readonly num_shooting_days: number = 0;
  public readonly shooting_date_start: string | null = null;
  public readonly shooting_date_end: string | null = null;
  public readonly budget: string = "";
  public readonly budget_type: number = 0;
  public readonly budget_currency: string = "";
  public readonly budget_description: string = "";
  public readonly agency_commission_currency: string = "";
  public readonly agency_commission: string = "";
  public readonly agency_payment_due: number | null = null;
  public readonly agency_payment_method: string = "";
  public readonly agency_payment_description: string = "";
  public readonly aged_from: number | null = null;
  public readonly aged_to: number | null = null;
  public readonly acting_level: number = 0;
  public readonly special_conditions: string = "";
  public readonly num_episodes: number = 1;
  public readonly project: string = "";
  public readonly gender: string = "";
  public readonly skills: Array<object> = [];
  public readonly tags: Array<object> = [];
  public readonly pretentious_at: Array<number> = [];
  public readonly get_display_budget: string = "";
  public readonly get_display_agency_commission: string = "";

  constructor(data: Partial<RoleModel>) {
    super();
    this.get_display_budget = get_display_budget(data.budget, data.budget_currency);
    this.get_display_agency_commission = get_display_budget(data.agency_commission, data.agency_commission_currency);
    this.update(data);
  }

  update(data: Partial<RoleModel>): void {
    super.update(data);
  }
}

const get_display_budget = (budget: string | undefined, budgetCurrency: string | undefined): string => {
  if (budget === undefined || budgetCurrency === undefined) {
    return "";
  } else {
    return Number(budget).toLocaleString(localeLang(), {
      style: "currency",
      currency: budgetCurrency,
    });
  }
};

const localeLang = () => {
  let lang: string | null = getCookieLang();

  return lang === null ? "tr-TR" : lang;
};
