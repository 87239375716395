import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import IStore from "models/IStore";
import { ReduxDispatch } from "models/ReduxProps";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as ActorRoleInvitationsEffect from "./ActorRoleInvitationsEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | RoleInvitationModel
  | RoleInvitationModel[]
  | RoleApplicationModel[];

export const REQUEST_ACCEPT_ROLE_INVITATION: string = "ActorRoleInvitationAction.REQUEST_ACCEPT_ROLE_INVITATION";
export const REQUEST_ACCEPT_ROLE_INVITATION_FINISHED: string =
  "ActorRoleInvitationAction.REQUEST_ACCEPT_ROLE_INVITATION_FINISHED";

export const requestAcceptRoleInvitation = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleInvitationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACCEPT_ROLE_INVITATION,
      ActorRoleInvitationsEffect.requestAcceptRoleInvitation,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_ACCEPT_ROLE_INVITATION_MESSAGE: string =
  "ActorRoleInvitationAction.CLEAR_REQUEST_ACCEPT_ROLE_INVITATION_MESSAGE";

export const clearRequestAcceptRoleInvitationMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ACCEPT_ROLE_INVITATION_MESSAGE);
};

export const CLEAR_REQUEST_ROLE_INVITATION_DETAIL: string =
  "ActorRoleInvitationAction.CLEAR_REQUEST_ROLE_INVITATION_DETAIL";

export const clearRoleInvitationDetail = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ROLE_INVITATION_DETAIL);
};

export const CLEAR_CREATE_ROLE_INVITATION: string = "ActorRoleInvitationAction.CLEAR_CREATE_ROLE_INVITATION";

export const clearCreatedRoleInvitationMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_CREATE_ROLE_INVITATION);
};

export const REQUEST_DECLINE_ROLE_INVITATION: string = "ActorRoleInvitationAction.REQUEST_DECLINE_ROLE_INVITATION";
export const REQUEST_DECLINE_ROLE_INVITATION_FINISHED: string =
  "ActorRoleInvitationAction.REQUEST_DECLINE_ROLE_INVITATION_FINISHED";

export const requestDeclineRoleInvitation = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DECLINE_ROLE_INVITATION,
      ActorRoleInvitationsEffect.requestDeclineRoleInvitation,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_DECLINE_ROLE_INVITATION: string =
  "ActorRoleInvitationAction.CLEAR_REQUEST_DECLINE_ROLE_INVITATION";

export const clearRequestDeclineRoleInvitation = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_DECLINE_ROLE_INVITATION);
};

export const REQUEST_ROLE_INVITATIONS: string = "ActorRoleInvitationAction.REQUEST_ROLE_INVITATIONS";
export const REQUEST_ROLE_INVITATIONS_FINISHED: string = "ActorRoleInvitationAction.REQUEST_ROLE_INVITATIONS_FINISHED";

export const requestRoleInvitations = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_INVITATIONS,
      ActorRoleInvitationsEffect.requestRoleInvitations,
      uuid,
    );
  };
};

//clear role invitations
export const CLEAR_ROLE_INVITATIONS: string = "ActorRoleInvitationAction.CLEAR_ROLE_INVITATIONS";

export const clearRoleInvitations = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_ROLE_INVITATIONS);
};

export const REQUEST_ACCEPT_ROLE_INVITATION_CARD: string =
  "ActorRoleInvitationAction.REQUEST_ACCEPT_ROLE_INVITATION_CARD";
export const REQUEST_ACCEPT_ROLE_INVITATION_CARD_FINISHED: string =
  "ActorRoleInvitationAction.REQUEST_ACCEPT_ROLE_INVITATION_CARD_FINISHED";

export const requestAcceptRoleInvitationCard = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ACCEPT_ROLE_INVITATION_CARD,
      ActorRoleInvitationsEffect.requestAcceptRoleInvitationCard,
      uuid,
    );
  };
};

export const REQUEST_DECLINE_ROLE_INVITATION_CARD: string =
  "ActorRoleInvitationAction.REQUEST_DECLINE_ROLE_INVITATION_CARD";
export const REQUEST_DECLINE_ROLE_INVITATION_CARD_FINISHED: string =
  "ActorRoleInvitationAction.REQUEST_DECLINE_ROLE_INVITATION_CARD_FINISHED";

export const requestDeclineRoleInvitationCard = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleInvitations = getState().projects.actorRoleInvitationState.roleInvitations;
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_DECLINE_ROLE_INVITATION_CARD,
      ActorRoleInvitationsEffect.requestDeclineRoleInvitationCard,
      uuid,
      roleInvitations,
    );
  };
};

export const REQUEST_ROLE_APPLICATIONS: string = "ActorRoleInvitationAction.REQUEST_ROLE_APPLICATIONS";
export const REQUEST_ROLE_APPLICATIONS_FINISHED: string =
  "ActorRoleInvitationAction.REQUEST_ROLE_APPLICATIONS_FINISHED";

export const requestRoleApplications = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATIONS,
      ActorRoleInvitationsEffect.requestRoleApplications,
      uuid,
    );
  };
};

//clear role invitations
export const CLEAR_ROLE_APPLICATIONS: string = "ActorRoleInvitationAction.CLEAR_ROLE_APPLICATIONS";

export const clearRoleApplications = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_ROLE_INVITATIONS);
};
