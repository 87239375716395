import { AxiosResponse } from "axios";
import environment from "environment";
import { SelectErrorsRawData } from "helper/ResponseErrorHelper";
import { CreateEvaluationFromLinkRequest } from "interfaces/CreateEvaluationFromLinkRequest";
import { MeetingEvaluationLink } from "interfaces/MeetingEvaluationLink";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import EvaluationCriterionModel from "models/api-models/EvaluationCriterionModel";
import RoleApplicationEvaluationModel from "models/api-models/RoleApplicationEvaluationModel";
import SimpleAuditionVideoModel from "models/api-models/audition-video/SimpleAuditionModel";
import ActorModel from "stores/actors/models/actors/ActorModel";
import * as HttpUtility from "utilities/HttpUtility";

export const requestSendContactMessage = async (
  first_name: string,
  last_name: string,
  email: string,
  message: string,
  captcha: string,
  phone?: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.pages.contact;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, {
    first_name,
    last_name,
    email,
    message,
    recaptcha: captcha,
    phone,
  });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return "success";
};

export const requestMeetingEvaluationLink = async (
  uuid: string,
): Promise<MeetingEvaluationLink | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.meeting_evaluation_link.concat(uuid + "/");

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data;
};

export const requestMeetingEvaluationActors = async (uuid: string): Promise<ActorModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.meeting_evaluation_actors.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map((json: Partial<ActorModel>) => new ActorModel(json));
};

export const requestRoleApplicationEvaluationCriteria = async (): Promise<
  EvaluationCriterionModel[] | HttpErrorResponseModel
> => {
  const endpoint: string = environment.api.projects.roleapplication_evaluation_criteria.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map((json: Partial<EvaluationCriterionModel>) => new EvaluationCriterionModel(json));
};

export const requestCreateEvaluation = async (
  uuid: string,
  data: CreateEvaluationFromLinkRequest,
): Promise<RoleApplicationEvaluationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.create_role_application_evaluation.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    response.message = SelectErrorsRawData(response);
    return response;
  }

  return new RoleApplicationEvaluationModel(response.data);
};

export const requestRoleApplicationActorAuditions = async (
  uuid: string,
  actor_uuid: string,
): Promise<SimpleAuditionVideoModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.get_actor_audition_videos
    .replace(":uuid", uuid)
    .concat("?actor_uuid=" + actor_uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = SelectErrorsRawData(response);
    return response;
  }

  return response.data.map((json: Partial<SimpleAuditionVideoModel>) => new SimpleAuditionVideoModel(json));
};
