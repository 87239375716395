import * as PhotoChannels from "./PhotoChannelsEffect";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as ActionUtility from "utilities/ActionUtility";
import PhotoChannelModel from "./models/PhotoChannelModel";
import { ReduxDispatch } from "models/ReduxProps";

type ActionUnion = undefined | HttpErrorResponseModel | PhotoChannelModel[] | string;

export const REQUEST_PHOTO_CHANNELS: string = "REQUEST_PHOTO_CHANNELS.REQUEST_PHOTO_CHANNELS";
export const REQUEST_PHOTO_CHANNELS_FINISHED: string = "REQUEST_PHOTO_CHANNELS.REQUEST_PHOTO_CHANNELS_FINISHED";

export const requestPhotoChannels = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<PhotoChannelModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PHOTO_CHANNELS,
      PhotoChannels.requestPhotoChannels,
    );
  };
};
