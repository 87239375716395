import { BaseModel } from "sjs-base-model";

export default class ResourceDistributionByProjectCastModel extends BaseModel {
  public readonly name: string = "";
  public readonly total_earn: number = 0;
  public readonly earning_actor: number = 0;
  public readonly earning_of_actor: number = 0;

  constructor(data: Partial<ResourceDistributionByProjectCastModel>) {
    super();

    this.update(data);
  }
}
