import environment from "environment";
import { AxiosResponse } from "axios";

import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";

export const requestCreateRoleInvitation = async (data: any): Promise<RoleInvitationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    try {
      response.message = response.raw.data["detail"];
    } catch {}
    return response;
  }

  return new RoleInvitationModel(response.data);
};

export const requestRoleInvitations = async (uuid: string): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.invitations.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data;
};

export const requestWithdrawRoleInvitation = async (
  data: any,
  role_uuid: string,
  invitations: RoleInvitationModel[],
): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.withdraw.replace(":uuid", role_uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  const newInvitations = invitations.filter((object) => {
    return object.uuid !== role_uuid;
  });

  newInvitations.push(new RoleInvitationModel(response.data));

  return newInvitations;

  // return response.data.uuid;
};

export const requestResendRoleInvitation = async (
  data: any,
  role_uuid: string,
  invitations: RoleInvitationModel[],
): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.resend.replace(":uuid", role_uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, data);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  const newInvitations = invitations.filter((object) => {
    return object.uuid !== role_uuid;
  });

  newInvitations.push(new RoleInvitationModel(response.data));

  return newInvitations;
};
