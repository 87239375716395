import { AxiosResponse } from "axios";

import environment from "environment";
import IPagination from "models/IPagination";
import * as HttpUtility from "utilities/HttpUtility";
import CastAgencyModel from "./models/CastAgencyModel";
import * as EffectUtility from "utilities/EffectUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import CastAgencyActorModel from "./models/actor/CastAgencyActorModel";

export const requestCastAgencies = async (
  search: string,
  page?: number,
): Promise<IPagination<CastAgencyModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.companies.casts.index.concat("?search=", search);

  endpoint = page ? endpoint.concat("&page=", page.toString()) : endpoint;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const result: IPagination<CastAgencyModel[]> = {
    currentPage: page || 1,
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<CastAgencyModel>) => new CastAgencyModel(json)),
  };

  return result;
};

export const requestCastAgency = async (slug: string): Promise<CastAgencyModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.index.concat(slug);

  return EffectUtility.getToModel<CastAgencyModel>(CastAgencyModel, endpoint);
};

export const requestCreateCastAgency = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.slug;
};

export const requestCastLikedByMe = async (
  slug: string,
): Promise<CastAgencyActorModel | undefined | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.liked_by.replace(":slug", slug).concat("?me");
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (response.data && response.data.length > 0) {
    return new CastAgencyActorModel(response.data[0]);
  } else {
    return undefined;
  }
};

export const requestUpdateCastAgency = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.cast.replace(":slug", slug);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.slug;
};

export const requestCastAgencyActors = async (
  slug: string,
): Promise<CastAgencyActorModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.actors.replace(":slug", slug);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<CastAgencyActorModel>) => new CastAgencyActorModel(json));
};

export const requestActorLikeToCast = async (cast_slug: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.like.replace(":slug", cast_slug);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Liked";
};

export const requestActorUnLikeToCast = async (cast_slug: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.unlike.replace(":slug", cast_slug);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "UnLiked";
};
