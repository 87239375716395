import ProjectBaseModel from "stores/projects/models/ProjectBaseModel";
import RouteEnum from "../../../../constants/RouteEnum";
import ProjectGenresModel from "../../models/ProjectGenresModel";

export default class SeriesProjectModel extends ProjectBaseModel {
  public readonly scriptwriter: string = "";
  public readonly music: string = "";
  public readonly genres: ProjectGenresModel[] = [ProjectGenresModel as any];
  public readonly get_create_path: string = "";
  public readonly get_update_path: string = "";

  constructor(series_project_model: Partial<SeriesProjectModel>) {
    super(series_project_model);
    this.get_create_path = get_create_path();
    this.get_update_path = get_update_path(series_project_model?.slug!);
    this.update(series_project_model);
  }
}

const get_create_path = (): string => {
  return RouteEnum.SeriesCreate;
};

const get_update_path = (slug: string): string => {
  return RouteEnum.SeriesUpdate.replace(":slug", slug);
};
