import { combineReducers, Reducer, ReducersMapObject } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import IStore from "../models/IStore";
import actorsReducer from "./actors/ActorsReducer";
import projectsReducer from "./projects/ProjectsReducer";
import reportsReducer from "./reports/ReportsReducer";
import requestingReducer from "./requesting/RequestingReducer";
import errorReducer from "./error/ErrorReducer";
import toastsReducer from "./toasts/ToastsReducer";
import authReducer from "./auth/AuthReducer";
import registerReducer from "./register/RegisterReducer";
import companiesReducer from "./companies/CompaniesReducer";
import profilesReducer from "./profiles/ProfilesReducer";
import pagesReducer from "./pages/PagesReducer";

const rootReducer = (history: History): Reducer<IStore> => {
  const reducerMap: ReducersMapObject<IStore> = {
    error: errorReducer,
    requesting: requestingReducer,
    router: connectRouter(history) as any,
    actors: actorsReducer,
    projects: projectsReducer,
    reports: reportsReducer,
    companies: companiesReducer,
    toasts: toastsReducer,
    auth: authReducer,
    register: registerReducer,
    profiles: profilesReducer,
    pages: pagesReducer,
  };

  return combineReducers(reducerMap);
};

export default rootReducer;
