import { EvaluationCategory } from "interfaces";
import { BaseModel } from "sjs-base-model";

export default class EvaluationCriterionModel extends BaseModel {
  public readonly title: string = "";
  public readonly uuid: string = "";
  public readonly category: EvaluationCategory = { title: "", uuid: "" };

  constructor(data: Partial<EvaluationCriterionModel>) {
    super();

    this.update(data);
  }
}
