import RouteEnum from "constants/RouteEnum";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SetLanguage from "views/components/headers/components/language-control/LanguageControl";
import NavTopMenu from "views/components/headers/components/nav-top-menu/NavTopMenu";
import NavItemComponent from "../components/nav-item-component/NavItemComponent";
import HeaderControl from "./components/header-control/HeaderControl";
import NavTopMenuContent from "./components/nav-top-menu-content/NavTopMenuContent";

const ALLOW_URL: RegExp[] = [
  RegExp(`^${RouteEnum.Home}`),
  RegExp(`^${RouteEnum.AboutPage}`),
  RegExp(`^${RouteEnum.ContactPage}`),
];

const PublicHeader: React.FC = () => {
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const checkPath = (path: string): boolean => {
    return ALLOW_URL.some((url) => url.test(path));
  };

  return (
    <header className="fixed-header">
      <div
        className="header-menu"
        style={
          checkPath(location.pathname) && scrollPosition < 40
            ? {
                backgroundColor: "transparent",
                borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              }
            : {}
        }
      >
        <div className="navbar">
          <NavItemComponent redirectToPath={RouteEnum.Home} />

          <NavTopMenu>
            <NavTopMenuContent />
          </NavTopMenu>

          <HeaderControl />

          <SetLanguage />
        </div>
      </div>
    </header>
  );
};

export default PublicHeader;
