import { BaseModel } from "sjs-base-model";

/*
    // Returned Api Data Sample
    {
      "medium": "http://static.tvmaze.com/uploads/images/medium_portrait/0/526.jpg",
      "original": "http://static.tvmaze.com/uploads/images/original_untouched/0/526.jpg"
    }
 */
export default class ActorVideoModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly actor: string = "";
  public readonly created: string = "";
  public readonly modified: string = "";
  public readonly is_active: boolean = true;
  public readonly title: string = "";
  public readonly hidden: boolean = true;
  public readonly video: string = "";
  public readonly video_url: string = "";
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<ActorVideoModel>) {
    super();

    this.update(data);
  }
}
