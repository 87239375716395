import * as TagsEffect from "./TagsEffect";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as ActionUtility from "utilities/ActionUtility";
import { ReduxDispatch } from "models/ReduxProps";
import TagModel from "./models/TagModel";

type ActionUnion = undefined | HttpErrorResponseModel | TagModel[] | TagModel | string;

export const REQUEST_SEARCH_ACTOR_TAGS: string = "TagsAction.REQUEST_SEARCH_ACTOR_TAGS";
export const REQUEST_SEARCH_ACTOR_TAGS_FINISHED: string = "TagsAction.REQUEST_SEARCH_ACTOR_TAGS_FINISHED";

export const requestSearchActorTags = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<TagModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SEARCH_ACTOR_TAGS,
      TagsEffect.requestActorTags,
    );
  };
};
