import CookieEnum from "constants/CookieEnum";
import IStore from "models/IStore";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dispatch } from "redux";
import { selectRequesting } from "selectors/requesting/RequestingSelector";
import * as AuthAction from "stores/auth/AuthAction";
import { storeDataToCookie } from "utilities/CookieUtility";
import LoadingIndicator from "views/components/loading-indicator/LoadingIndicator";
import "./LanguageControl.css";

const LanguageControl: React.FC = () => {
  const { t, i18n } = useTranslation();
  const langs = i18n.options.resources;
  const token: string | null = useSelector((state: IStore) => state?.auth?.token);

  const dispatch: Dispatch = useDispatch();
  const changedUserLanguageSuccess: string | undefined = useSelector(
    (state: IStore) => state.auth.changedUserLanguageSuccess,
  );
  const isRequesting: boolean = useSelector((state: IStore) =>
    selectRequesting(state, [AuthAction.REQUEST_UPDATE_LANGUAGE]),
  );

  useEffect(() => {
    if (changedUserLanguageSuccess) {
      i18n.changeLanguage(changedUserLanguageSuccess);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changedUserLanguageSuccess]);

  const changeLanguage = (lng: string) => {
    if (token) {
      dispatch(AuthAction.requestUpdateLanguage(lng));
    } else {
      storeDataToCookie(CookieEnum.Lang, lng);
      i18n.changeLanguage(lng);
      window.location.reload();
    }
  };

  return (
    <nav className="template-main-menu">
      <ul className="menu-content">
        <li className="header-nav-item">
          {isRequesting ? (
            <LoadingIndicator
              loadingType="Oval"
              isActive={true}
              loadingColor="white"
              loadingWidth={20}
              loadingHeight={20}
            />
          ) : (
            <>
              <NavLink className="menu-link have-sub world-lang" to="#">
                {t(`${i18n.language.slice(0, 2)}.name`)}
              </NavLink>
              <ul className="sub-menu sub-menu-language">
                {Object.keys(langs!).map((lng) => (
                  <li
                    key={lng}
                    onClick={(e) => {
                      e.preventDefault();
                      changeLanguage(lng);
                    }}
                  >
                    <p className="menu-link">{t(`${lng.slice(0, 2)}.name`)}</p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default LanguageControl;
