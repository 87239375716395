import RouteEnum from "constants/RouteEnum";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import CastelectLogo from "views/components/castelect-logo/CastelectLogo";
import "./PublicSideBar.css";

const PublicSideBar: React.FC = () => {
  const [sideBarMod, setSidebar] = useState<boolean>(true);
  const setSideBarMod = () => setSidebar(!sideBarMod);
  const { t } = useTranslation();

  return (
    <div className={sideBarMod ? "fixed-sidebar public-sidebar" : "fixed-sidebar public-sidebar lg-menu-open"}>
      <div className="fixed-sidebar-left small-sidebar">
        <div className="sidebar-toggle" style={{ backgroundColor: "transparent" }}>
          <button className="toggle-btn toggler-open" onClick={setSideBarMod}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
      <div className="fixed-sidebar-left large-sidebar">
        <div className="sidebar-toggle">
          <div className="sidebar-logo mb-2">
            <CastelectLogo height={28} redirectToPath={RouteEnum.Home} />
          </div>
          <button className="toggle-btn toggler-close" onClick={setSideBarMod}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div className="sidebar-menu-wrap">
          <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
            <ul className="side-menu">
              <li className="menu-label">
                <NavLink exact={true} className="menu-link menu-title" to={RouteEnum.ContactPage}>
                  {t("general.contact")}
                </NavLink>
              </li>
              <li className="menu-label">
                <NavLink exact={true} className="menu-link menu-title" to={RouteEnum.AboutPage}>
                  {t("general.about")}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicSideBar;

/*

 <li className="header-nav-item">
                <NavLink exact={true} activeClassName='is-active' className="menu-link" to={RouteEnum.ContactPage}>{t("general.contact")}</NavLink>
            </li>
            <li className="header-nav-item">
                <NavLink exact={true} activeClassName='is-active' className="menu-link" to={RouteEnum.AboutPage}>{t("general.about")}</NavLink>
            </li>
      <NavTopMenu>
                        <NavTopMenuContent/>
                    //</NavTopMenu>
*/
