import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import PublicHeader from "views/components/headers/public-header/PublicHeader";
import PublicSideBar from "./components/PublicSideBar";

interface IProps {
  readonly path: string;
  readonly component: any;
  readonly header?: boolean;
}

const PublicRoute: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const { component: Component, ...rest } = props;

  return (
    <Fragment>
      <div className="wrapper">
        <PublicHeader />

        <PublicSideBar />
      </div>

      <Route exact={true} {...rest} render={(props) => <Component {...props} />} />
    </Fragment>
  );
};

export default PublicRoute;
