import { BaseModel } from "sjs-base-model";
import ICompaniesModel from "./models/ICompaniesModel";

/*
    // Returned Api Data Sample
    {
      "medium": "http://static.tvmaze.com/uploads/images/medium_portrait/0/526.jpg",
      "original": "http://static.tvmaze.com/uploads/images/original_untouched/0/526.jpg"
    }
 */
export default class IUserCookieModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly id: number = 0;
  public readonly username: string = "";
  public readonly url: string = "";
  public readonly groups: string[] = [] as any; //user role
  public readonly language: string = "";
  public readonly full_name: string = "";
  public readonly first_name: string = "";
  public readonly last_name: string = "";
  public readonly avatar: string = "";
  public readonly token: string = "";
  public readonly is_pending: boolean = false;
  public readonly actor_slug: string = "";
  public readonly actor_uuid: string = "";
  public readonly companies: ICompaniesModel = ICompaniesModel as any;
  public readonly actor_approved: boolean = false;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<IUserCookieModel>) {
    super();

    this.update(data);
  }
}
