import IAction from "models/IAction";
import { ReduxDispatch } from "models/ReduxProps";
import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as RoleInvitationsEffect from "./RoleInvitationsEffect";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import IStore from "models/IStore";

type ActionUnion = undefined | HttpErrorResponseModel | string | RoleInvitationModel | RoleInvitationModel[];

export const FILTER_INVITE_ACTORS_FOR_ROLE_SHOW: string = "RoleInvitationAction.FILTER_INVITE_ACTORS_FOR_ROLE_SHOW";

export const filterProjectsShow = (filter: boolean): IAction<boolean> => {
  return ActionUtility.createAction(FILTER_INVITE_ACTORS_FOR_ROLE_SHOW, filter);
};

export const REQUEST_CREATE_ROLE_INVITATION: string = "RoleInvitationAction.REQUEST_CREATE_ROLE_INVITATION";
export const REQUEST_CREATE_ROLE_INVITATION_FINISHED: string =
  "RoleInvitationAction.REQUEST_CREATE_ROLE_INVITATION_FINISHED";

export const requestCreateRoleInvitation = (data: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleInvitationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ROLE_INVITATION,
      RoleInvitationsEffect.requestCreateRoleInvitation,
      data,
    );
  };
};

export const CLEAR_CREATE_ROLE_INVITATION: string = "RoleInvitationAction.CLEAR_CREATE_ROLE_INVITATION";

export const clearCreatedRoleInvitationMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_CREATE_ROLE_INVITATION);
};

export const REQUEST_ROLE_INVITATIONS: string = "RoleInvitationAction.REQUEST_ROLE_INVITATIONS";
export const REQUEST_ROLE_INVITATIONS_FINISHED: string = "RoleInvitationAction.REQUEST_ROLE_INVITATIONS_FINISHED";

export const requestRoleInvitations = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_INVITATIONS,
      RoleInvitationsEffect.requestRoleInvitations,
      uuid,
    );
  };
};

export const CLEAR_REQUEST_ROLE_INVITATIONS: string = "RoleInvitationAction.CLEAR_REQUEST_ROLE_INVITATIONS";

export const clearRequestRoleInvitations = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_REQUEST_ROLE_INVITATIONS);
};

export const REQUEST_WITHDRAW_ROLE_INVITATION: string = "RoleInvitationAction.REQUEST_WITHDRAW_ROLE_INVITATION";
export const REQUEST_WITHDRAW_ROLE_INVITATION_FINISHED: string =
  "RoleInvitationAction.REQUEST_WITHDRAW_ROLE_INVITATION_FINISHED";

export const requestWithdrawRoleInvitation = (data: any, roleUUID: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleInvitations: RoleInvitationModel[] = getState().projects.projectRoles.roleInvitations;
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_WITHDRAW_ROLE_INVITATION,
      RoleInvitationsEffect.requestWithdrawRoleInvitation,
      data,
      roleUUID,
      roleInvitations,
    );
  };
};

export const CLEAR_CREATE_WITHDRAW_ROLE_INVITATION: string =
  "RoleInvitationAction.CLEAR_CREATE_WITHDRAW_ROLE_INVITATION";

export const clearCreatedWithdrawRoleInvitationMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_CREATE_WITHDRAW_ROLE_INVITATION);
};

export const REQUEST_RESEND_ROLE_INVITATION: string = "RoleInvitationAction.REQUEST_RESEND_ROLE_INVITATION";
export const REQUEST_RESEND_ROLE_INVITATION_FINISHED: string =
  "RoleInvitationAction.REQUEST_RESEND_ROLE_INVITATION_FINISHED";

export const requestResendRoleInvitation = (data: any, role_uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const roleInvitations: RoleInvitationModel[] = getState().projects.projectRoles.roleInvitations;
    await ActionUtility.createThunkEffect<RoleInvitationModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_RESEND_ROLE_INVITATION,
      RoleInvitationsEffect.requestResendRoleInvitation,
      data,
      role_uuid,
      roleInvitations,
    );
  };
};

export const CLEAR_CREATE_RESEND_ROLE_INVITATION: string = "RoleInvitationAction.CLEAR_CREATE_RESEND_ROLE_INVITATION";

export const clearCreatedResendRoleInvitationMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_CREATE_RESEND_ROLE_INVITATION);
};
