import CookieEnum from "constants/CookieEnum";
import RouteEnum from "constants/RouteEnum";
import IStore from "models/IStore";
import IUserCookieModel from "models/common-models/IUserCookieModel";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { getCookie } from "utilities/CookieUtility";

interface IProps {
  readonly path: string;
  readonly component: any;
}

const AuthRoute: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const history = useHistory();
  const { component: Component, ...rest } = props;
  const token: string | null = useSelector((state: IStore) => state.auth.token);
  const user: IUserCookieModel | null = useSelector((state: IStore) => state.auth.user);
  const redirectPath: string | undefined = useSelector((state: IStore) => state.auth.redirectPath);

  useEffect(() => {
    if (token && user) {
      setTimeout(() => {
        if (redirectPath) {
          history.replace(redirectPath);
        } else {
          history.replace(RouteEnum.Profile);
        }
      }, 1000);
    } else {
      const cookieToken = getCookie(CookieEnum.Token);
      if (cookieToken) {
        history.replace(RouteEnum.Profile);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user, redirectPath]);

  return (
    <Fragment>
      <Route exact={true} {...rest} render={(props) => <Component {...props} />} />
    </Fragment>
  );
};

export default AuthRoute;
