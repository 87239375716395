enum RouteEnum {
  Home = "/",
  AboutPage = "/about",
  ContactPage = "/contact",

  Projects = "/projects",
  ProjectDetail = "/projects/:type/:slug",
  ProjectCreate = "/projects/create",

  Commercials = "/projects/commercials",
  Commercial = "/projects/commercials/:slug",
  CommercialCreate = "/projects/commercials/create",
  CommercialUpdate = "/projects/commercials/:slug/update",

  Movies = "/projects/movies",
  Movie = "/projects/movies/:slug",
  MovieCreate = "/projects/movies/create",
  MovieUpdate = "/projects/movies/:slug/update",

  Series = "/projects/series",
  SeriesDetail = "/projects/series/:slug",
  SeriesCreate = "/projects/series/create",
  SeriesUpdate = "/projects/series/:slug/update",

  Login = "/login",
  Logout = "/logout",
  Register = "/register",
  VerifyEmail = "/registration/verify/:key",
  InvitationMember = "/auth/invites/:code",
  ResendEmail = "/registration/resend-email/",
  ResetPassword = "/password/reset/",
  ResetPasswordConfirm = "/auth/password/reset/confirm/:uid/:token/",

  Reports = "/reports",

  Profile = "/profile",
  ProfileEdit = "/profile/edit",
  ProfileCreate = "/profile/create",
  ProfileSettings = "/profile/settings",
  ProfileChangePasswordSettings = "/profile/settings/change_password",
  ProfileNotificationSettings = "/profile/settings/notification_settings",

  ProjectRoles = "/projects/:project_slug/roles",
  ProjectRoleCreate = "/projects/:project_slug/roles/create",
  ProjectRole = "/projects/:project_slug/roles/:role_uuid",
  ProjectRoleUpdate = "/projects/:project_slug/roles/:role_uuid/update",

  //for projects
  ProjectRoleInvitations = "/projects/:project_slug/roles/:role_uuid/invitations",
  InviteActorForRole = "/projects/:project_slug/roles/:role_uuid/invitations/invite_actors",
  InvitedActorsForRole = "/projects/:project_slug/roles/:role_uuid/invitations/invited_actors",

  ProjectRoleApplications = "/projects/:project_slug/roles/:role_uuid/applications",

  ProjectRoleApplicationsEvaluations = "/projects/:project_slug/roles/:role_uuid/applications/:application_id/evaluation",
  ProjectRoleApplicationsEvaluationDetail = "/projects/:project_slug/roles/:role_uuid/applications/:application_id/evaluation/:evaluation_id",
  ProjectRoleApplicationsEvaluationCreate = "/projects/:project_slug/roles/:role_uuid/applications/:application_id/evaluation/create",
  //for actors
  RoleInvitationAccept = "/projects/roleinvitations/:uuid/accept/",
  RoleInvitationDecline = "/projects/roleinvitations/:uuid/decline/",
  RoleInvitations = "/projects/roleinvitations",

  RoleApplicationCreate = "/projects/roleapplications/create",
  RoleApplication = "/projects/roleapplications/:uuid",
  RoleApplications = "/projects/roleapplications",

  // public
  RoleApplicationMeetingEvaluation = "/roleapplications-meeting-evaluation/:uuid",

  //for cast agency
  ProjectCastProjects = "/projects/cast-projects",
  ProjectCastProject = "/projects/cast-projects/:slug",
  CastPayments = "/projects/cast-payments",
  CastPayment = "/projects/cast-payments/:slug",

  //for productions
  ProductionPayments = "/projects/production-payments",
  ProductionPayment = "/projects/production-payments/:slug",

  Actor = "/actors/:uuid",
  ActorProfile = "/actors/:uuid/profile",
  ActorPhotos = "/actors/:uuid/photos",
  ActorVideos = "/actors/:uuid/videos",
  ActorCastAgencies = "/actors/:uuid/cast-agencies",
  ActorEvaluation = "/actors/:uuid/evaluation",

  ActorEdit = "/actors/:uuid/edit",
  ActorEditPhysicalAppearance = "/actors/:uuid/edit/physical-appearance",
  ActorCreate = "/actors/create",
  Actors = "/actors",

  Companies = "/companies",

  Cast = "/companies/casts/:slug",
  Casts = "/companies/casts",
  CastCreate = "/companies/casts/create",
  CastEdit = "/companies/casts/:slug/edit",
  CastActors = "/companies/casts/:slug/actors",
  CastProfile = "/companies/casts/:slug/profile",
  CastProjects = "/companies/casts/:slug/projects",

  Client = "/companies/clients/:slug",
  ClientProfile = "/companies/clients/:slug/profile",
  ClientCreate = "/companies/clients/create",
  ClientEdit = "/companies/clients/:slug/edit",
  Clients = "/companies/clients",
  ClientProjects = "/companies/clients/:slug/projects",

  Production = "/companies/productions/:slug",
  ProductionCreate = "/companies/productions/create",
  ProductionEdit = "/companies/productions/:slug/edit",
  Productions = "/companies/productions",
  ProductionProfile = "/companies/productions/:slug/profile",
  ProductionProjects = "/companies/productions/:slug/projects",

  Notifications = "/notifications",
}

export default RouteEnum;
