import { AxiosResponse } from "axios";
import environment from "environment";

import * as HttpUtility from "utilities/HttpUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import LanguageModel from "../models/language-model/LanguageModel";

export const requestCreateLanguage = async (formData: any): Promise<LanguageModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.languages.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new LanguageModel(response.data);
};

export const requestUpdateLanguage = async (
  uuid: string,
  formData: any,
  languages: LanguageModel[],
): Promise<LanguageModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.languages.language.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (languages) {
    const updatedLang: LanguageModel = new LanguageModel(response.data);
    const objIndex = languages.findIndex((obj) => obj.uuid === uuid);

    let newLanguages = languages.filter((object) => {
      return object.uuid !== uuid;
    });

    newLanguages.splice(objIndex, 0, updatedLang);
    return newLanguages;
  }
  return [];
};

// Delete language by uuid
export const requestDeleteLanguage = async (
  uuid: string,
  languages: LanguageModel[],
): Promise<LanguageModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.languages.language.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.del(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (languages) {
    const newLanguages = languages.filter((object) => {
      return object.uuid !== uuid;
    });
    return newLanguages;
  }
  return [];
};
