import environment from "environment";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import * as HttpUtility from "../../../utilities/HttpUtility";
import { AxiosResponse } from "axios";

export const requestChangePassword = async (
  new_password1: string,
  new_password2: string,
): Promise<{ status: boolean; message: string } | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.password.change;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, {
    new_password1: new_password1,
    new_password2: new_password2,
  });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return { status: true, message: response.data.detail };
};

export const requestResetPassword = async (
  email: string,
): Promise<{ status: boolean; message: string } | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.password.change;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, { email: email });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return { status: true, message: response.data.detail };
};

export const confirmResetPassword = async (
  uid: string,
  token: string,
  new_password1: string,
  new_password2: string,
): Promise<Boolean | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.password.confirm;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, {
    new_password1: new_password1,
    new_password2: new_password2,
    uid: uid,
    token: token,
  });

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return true;
};

/**
 * This is only to trigger an error api response so we can use it for an example in the AboutPage
 */
export const requestError = async (): Promise<any | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.errorExamplePage;
  //const endpoint: string = "https://httpstat.us/520";
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data;
};
