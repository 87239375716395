import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IStore from "../../../models/IStore";
import IToast from "../../../stores/toasts/models/IToast";
import ToastCard from "./toast-card/ToastCard";

const Toasts: React.FC = () => {
  const toasts: IToast[] = useSelector((state: IStore) => state.toasts.items);

  if (toasts.length === 0) {
    return null;
  }

  return (
    <div>
      <ToastContainer />
      {toasts.map((model: IToast) => (
        <ToastCard key={model.id} item={model} />
      ))}
    </div>
  );
};

export default Toasts;
