import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import { AxiosResponse } from "axios";
import GenreModel from "./models/GenreModel";

//çalışan get
export const requestGenres = async (): Promise<GenreModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.genres;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.results.map((json: Partial<GenreModel>) => new GenreModel(json));
};
