import { BaseModel } from "sjs-base-model";
import PassportModel from "./passport/PassportModel";
import LightCountryModel from "./passport/LightCountryModel";
import GraduationModel from "./graduation-model/GraduationModel";
import LightModel from "./light-model/LightModel";
import LanguageModel from "./language-model/LanguageModel";
import AvatarModel from "./avatar-model/AvatarModel";
import ICompaniesModel from "models/common-models/models/ICompaniesModel";

export default class ProfileModel extends BaseModel {
  public readonly username: string = "";
  public readonly avatar: string | null = null;
  public readonly avatar_thumbnails: AvatarModel = AvatarModel as any;
  public readonly actor_slug: string | null = null;
  public readonly actor_approved: boolean = false;
  public readonly actor_uuid: string | null = null;
  public readonly birth_city: LightModel | null = LightModel as any;
  public readonly birth_country: LightCountryModel | null = LightCountryModel as any;
  public readonly birth_date: string | null = null;
  public readonly birth_region: LightModel | null = LightModel as any;
  public readonly birth_subregion: LightModel | null = LightModel as any;
  public readonly created: string = "";
  public readonly driving_license_codes: string[] = [];
  public readonly education_level: string = "";
  public readonly facebook_url: string = "";
  public readonly first_name: string = "";
  public readonly full_name: string = "";
  public readonly gender: string = "";
  public readonly graduations: GraduationModel[] = [GraduationModel as any]; //https://www.npmjs.com/package/sjs-base-model#typescript-usage
  public readonly groups: string[] = [];
  public readonly has_passport: boolean = false;
  public readonly instagram_url: string = "";
  public readonly is_pending: boolean = false;
  public readonly is_removed: boolean = false;
  public readonly is_student: boolean = false;
  public readonly languages: LanguageModel[] = [LanguageModel as any];
  public readonly last_graduation: string | null = "";
  public readonly last_name: string = "";
  public readonly linkedin_url: string = "";
  public readonly living_city: LightModel | null = LightModel as any;
  public readonly living_country: LightCountryModel | null = LightCountryModel as any;
  public readonly living_region: LightModel | null = LightModel as any;
  public readonly living_subregion: LightModel | null = LightModel as any;
  public readonly marital_status: string | null = "";
  public readonly modified: string = "";
  public readonly passport_validity: number = 0;
  public readonly passports: PassportModel[] = [PassportModel as any];
  public readonly phone_number: string = "";
  public readonly twitter_url: string = "";
  public readonly website: string = "";
  public readonly zip_code: string = "";
  public readonly companies: ICompaniesModel = ICompaniesModel as any;

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<ProfileModel>) {
    super();

    this.update(data);
  }
}
