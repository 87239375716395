import { ReduxDispatch } from "models/ReduxProps";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import IPagination from "models/IPagination";
import IStore from "models/IStore";
import NotificationModel from "models/api-models/NotificationModel";
import IUserCookieModel from "models/common-models/IUserCookieModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as AuthEffect from "./AuthEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | IUserCookieModel
  | NotificationModel[]
  | IPagination<NotificationModel[]>;

export const REQUEST_LOGIN: string = "AuthAction.REQUEST_LOGIN";
export const REQUEST_LOGIN_FINISHED: string = "AuthAction.REQUEST_LOGIN_FINISHED";

export const login = (email: string, password: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_LOGIN,
      AuthEffect.requestLogin,
      email,
      password,
    );
  };
};

export const REQUEST_LOGIN_GOOGLE: string = "AuthAction.REQUEST_LOGIN_GOOGLE";
export const REQUEST_LOGIN_GOOGLE_FINISHED: string = "AuthAction.REQUEST_LOGIN_GOOGLE_FINISHED";

export const loginGoogle = (token: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_LOGIN_GOOGLE,
      AuthEffect.loginGoogle,
      token,
    );
  };
};

export const REQUEST_REGISTER_GOOGLE: string = "AuthAction.REQUEST_REGISTER_GOOGLE";
export const REQUEST_REGISTER_GOOGLE_FINISHED: string = "AuthAction.REQUEST_REGISTER_GOOGLE_FINISHED";

export const registerGoogle = (token: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_LOGIN_GOOGLE,
      AuthEffect.registerGoogle,
      token,
    );
  };
};

export const SET_CURRENT_USER: string = "AuthAction.SET_CURRENT_USER";

export const setCurrentUser = (): any => {
  return ActionUtility.createAction(SET_CURRENT_USER);
};

export const SET_TOKEN: string = "AuthAction.SET_TOKEN";

export const setToken = (): any => {
  return ActionUtility.createAction(SET_TOKEN);
};

export const REQUEST_GET_CURRENT_USER: string = "AuthAction.REQUEST_GET_CURRENT_USER";
export const REQUEST_GET_CURRENT_USER_FINISHED: string = "AuthAction.REQUEST_GET_CURRENT_USER_FINISHED";

export const getCurrentUser = (token: string | null): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IUserCookieModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_GET_CURRENT_USER,
      AuthEffect.requestCurrentUser,
      token,
    );
  };
};

export const REQUEST_GET_USER_ME: string = "AuthAction.REQUEST_GET_USER_ME";
export const REQUEST_GET_USER_ME_FINISHED: string = "AuthAction.REQUEST_GET_USER_ME_FINISHED";

export const getUserMe = (token: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IUserCookieModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_GET_USER_ME,
      AuthEffect.requestCurrentUser,
      token,
    );
  };
};

export const CLEAR_LOGIN_SUCCESS: string = "AuthAction.CLEAR_LOGIN_SUCCESS";

export const clearLoginSuccess = (): any => {
  return ActionUtility.createAction(CLEAR_LOGIN_SUCCESS);
};

export const SET_SELECTED_PROFILE: string = "AuthAction.SET_SELECTED_PROFILE";
export const SET_SELECTED_PROFILE_FINISHED: string = "AuthAction.SET_SELECTED_PROFILE_FINISHED";

export const setSelectedProfile = (profile: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | undefined>(
      dispatch,
      SET_SELECTED_PROFILE,
      AuthEffect.setSelectedProfile,
      profile,
    );
  };
};

export const REMOVE_SELECTED_PROFILE: string = "AuthAction.REMOVE_SELECTED_PROFILE";
export const REMOVE_SELECTED_PROFILE_FINISHED: string = "AuthAction.REMOVE_SELECTED_PROFILE_FINISHED";

export const removeSelectedProfile = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | undefined>(
      dispatch,
      REMOVE_SELECTED_PROFILE,
      AuthEffect.removeSelectedProfile,
    );
  };
};

export const SET_REDIRECT_PATH: string = "AuthAction.SET_REDIRECT_PATH";

export const setRedirectPath = (path: string): any => {
  return ActionUtility.createAction(SET_REDIRECT_PATH, path);
};

export const REMOVE_REDIRECT_PATH: string = "AuthAction.REMOVE_REDIRECT_PATH";

export const removeRedirectPath = (): any => {
  return ActionUtility.createAction(REMOVE_REDIRECT_PATH);
};

export const SET_REDIRECT_COMPONENT: string = "AuthAction.SET_REDIRECT_COMPONENT";

export const setRedirectComponent = (component: any): any => {
  return ActionUtility.createAction(SET_REDIRECT_COMPONENT, component);
};

export const REMOVE_REDIRECT_COMPONENT: string = "AuthAction.REMOVE_REDIRECT_COMPONENT";

export const removeRedirectComponent = (): any => {
  return ActionUtility.createAction(REMOVE_REDIRECT_COMPONENT);
};

export const REMOVE_USER: string = "AuthAction.REMOVE_USER";

export const removeUser = (): any => {
  return ActionUtility.createAction(REMOVE_USER);
};

export const REMOVE_TOKEN: string = "AuthAction.REMOVE_TOKEN";

export const removeToken = (): any => {
  return ActionUtility.createAction(REMOVE_TOKEN);
};

export const SHOW_PROFILE_SWITCH_MODAL: string = "AuthAction.SHOW_PROFILE_SWITCH_MODAL";

export const showProfileSwitchModal = (): any => {
  return ActionUtility.createAction(SHOW_PROFILE_SWITCH_MODAL);
};

export const CLOSE_PROFILE_SWITCH_MODAL: string = "AuthAction.CLOSE_PROFILE_SWITCH_MODAL";

export const closeProfileSwitchModal = (): any => {
  return ActionUtility.createAction(CLOSE_PROFILE_SWITCH_MODAL);
};

export const REQUEST_INVITE_MEMBER: string = "AuthAction.REQUEST_INVITE_MEMBER";
export const REQUEST_INVITE_MEMBER_FINISHED: string = "AuthAction.REQUEST_INVITE_MEMBER_FINISHED";

export const requestInviteMember = (formData: any): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_INVITE_MEMBER,
      AuthEffect.requestInviteMember,
      formData,
    );
  };
};

export const CLEAR_REQUEST_INVITE_MEMBER_MESSAGE: string = "AuthAction.CLEAR_REQUEST_INVITE_MEMBER_MESSAGE";

export const clearRequestInviteMemberMessage = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_INVITE_MEMBER_MESSAGE);
};

export const REQUEST_GET_INVITE_CODE: string = "AuthAction.REQUEST_GET_INVITE_CODE";
export const REQUEST_GET_INVITE_CODE_FINISHED: string = "AuthAction.REQUEST_GET_INVITE_CODE_FINISHED";

export const requestGetInviteCode = (code: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_GET_INVITE_CODE,
      AuthEffect.requestGetInviteCode,
      code,
    );
  };
};

export const REQUEST_UPDATE_LANGUAGE: string = "AuthAction.REQUEST_UPDATE_LANGUAGE";
export const REQUEST_UPDATE_LANGUAGE_FINISHED: string = "AuthAction.REQUEST_UPDATE_LANGUAGE_FINISHED";

export const requestUpdateLanguage = (language: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const user: IUserCookieModel | null = getState().auth.user;

    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_UPDATE_LANGUAGE,
      AuthEffect.requestUpdateLanguage,
      language,
      user,
    );
  };
};

export const CLEAR_REQUEST_GET_INVITE_CODE_MESSAGE: string = "AuthAction.CLEAR_REQUEST_GET_INVITE_CODE_MESSAGE";

export const clearRequestGetInviteCode = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_GET_INVITE_CODE_MESSAGE);
};

export const CLEAR_INVITE_EMAIL: string = "AuthAction.CLEAR_INVITE_EMAIL";

export const clearInviteEmail = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_INVITE_EMAIL);
};

export const SET_REDIRECT_MESSAGE: string = "AuthAction.SET_REDIRECT_MESSAGE";

export const setRedirectMessage = (message: string | undefined): any => {
  return ActionUtility.createAction(SET_REDIRECT_MESSAGE, message);
};

// state atılmadı.
export const REQUEST_SEEN_NOTIFICATION: string = "AuthAction.REQUEST_SEEN_NOTIFICATION";
export const REQUEST_SEEN_NOTIFICATION_FINISHED: string = "AuthAction.REQUEST_SEEN_NOTIFICATION_FINISHED";

export const requestSeenNotification = (id: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_SEEN_NOTIFICATION,
      AuthEffect.requestSeenNotification,
      id,
    );
  };
};

export const REQUEST_SEEN_ALL_NOTIFICATION: string = "AuthAction.REQUEST_SEEN_ALL_NOTIFICATION";
export const REQUEST_SEEN_ALL_NOTIFICATION_FINISHED: string = "AuthAction.REQUEST_SEEN_ALL_NOTIFICATION_FINISHED";

export const requestSeenAllNotification = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_SEEN_ALL_NOTIFICATION,
      AuthEffect.requestSeenAllNotification,
    );
  };
};

export const REQUEST_GET_NOTIFICATION: string = "AuthAction.REQUEST_GET_NOTIFICATION";
export const REQUEST_GET_NOTIFICATION_FINISHED: string = "AuthAction.REQUEST_GET_NOTIFICATION_FINISHED";

export const requestNotifications = (page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<NotificationModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_GET_NOTIFICATION,
      AuthEffect.requestNotifications,
      page,
    );
  };
};

export const REQUEST_GET_UNSEEN_NOTIFICATION: string = "AuthAction.REQUEST_GET_UNSEEN_NOTIFICATION";
export const REQUEST_GET_UNSEEN_NOTIFICATION_FINISHED: string = "AuthAction.REQUEST_GET_UNSEEN_NOTIFICATION_FINISHED";

export const requestUnseenNotifications = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<NotificationModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_GET_UNSEEN_NOTIFICATION,
      AuthEffect.requestUnseenNotifications,
    );
  };
};
