import { Reducer } from "redux";
import baseReducer from "utilities/BaseReducer";
import ClientsReducer from "./clients/ClientsReducer";
import ICompaniesState from "./models/ICompainesState";
import ProductionsReducer from "./productions/ProductionsReducer";
import CastAgenciesReducer from "./cast-agencies/CastAgenciesReducer";

export const initialState: ICompaniesState = {
  productions: undefined,
  productionDetail: undefined,
  productionOptions: undefined,
  requestCreateProduction: undefined,
  requestUpdateProduction: undefined,

  clients: undefined,
  clientDetail: undefined,
  requestCreateClientCompany: undefined,
  requestUpdateClientCompany: undefined,
  clientOptions: undefined,

  castAgencies: undefined,
  castAgencyDetail: undefined,
  castAgencyOptions: undefined,
  castAgencyActors: [],
  castAgencyLikedByMe: undefined,
  requestActorLikeCast: undefined,
  requestCreateCastAgency: undefined,
  requestUpdateCastAgency: undefined,
};

const companiesReducer: Reducer = baseReducer(initialState, {
  ...ClientsReducer,

  ...ProductionsReducer,

  ...CastAgenciesReducer,
});

export default companiesReducer;
