import { BaseModel } from "sjs-base-model";
import LightCountryModel from "./LightCountryModel";

export default class PassportModel extends BaseModel {
  public readonly id: number = 0;
  public readonly issuing_country: LightCountryModel = LightCountryModel as any;
  public readonly passport_type: string = "";
  public readonly expiry_date: string = "";
  public readonly passport_number: string = "";
  public readonly user_profile: number = 0;
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<PassportModel>) {
    super();

    this.update(data);
  }
}
