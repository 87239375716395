import { Reducer } from "redux";
import IAction from "models/IAction";
import baseReducer from "utilities/BaseReducer";
import ProfileModel from "./models/ProfileModel";
import * as ProfilesAction from "./ProfilesAction";
import IProfileState from "./models/IProfilesState";
import ActorModel from "stores/actors/models/actors/ActorModel";
import CastAgencyModel from "stores/companies/cast-agencies/models/CastAgencyModel";
import GraduationModel from "./models/graduation-model/GraduationModel";

import LanguageReducer from "./language/LanguageReducer";

// TODO must be separate reducer language graduations
export const initialState: IProfileState = {
  profile: null,
  actor: null,
  likedAgencies: [],
  castProfile: undefined,
  castLikedActor: undefined,
  profileUpdated: null,
  requestAddGraduation: undefined,
  requestUpdateGraduation: undefined,
  requestDeleteGraduation: undefined,
  requestCreateLanguage: undefined,
  requestUpdateLanguage: undefined,
  requestDeleteLanguage: undefined,
};

const profilesReducer: Reducer = baseReducer(initialState, {
  ...LanguageReducer,

  [ProfilesAction.REQUEST_USER_FINISHED](state: IProfileState, action: IAction<ProfileModel>): IProfileState {
    return {
      ...state,
      profile: action.payload!,
    };
  },

  [ProfilesAction.EMPTY_PROFILE](state: IProfileState): IProfileState {
    return {
      ...state,
      profile: null,
      castProfile: undefined,
      actor: null,
      likedAgencies: [],
    };
  },

  [ProfilesAction.REQUEST_PROFILE_UPDATE_FINISHED](state: IProfileState, action: IAction<string>): IProfileState {
    return {
      ...state,
      profileUpdated: action.payload!,
    };
  },

  [ProfilesAction.EMPTY_PROFILE_UPDATE_MESSAGE](state: IProfileState): IProfileState {
    return {
      ...state,
      profileUpdated: null,
    };
  },

  [ProfilesAction.REQUEST_ACTOR_PROFILE_FINISHED](state: IProfileState, action: IAction<ActorModel>): IProfileState {
    return {
      ...state,
      actor: action.payload!,
    };
  },

  [ProfilesAction.REQUEST_ACTOR_LIKED_CASTS_FINISHED](
    state: IProfileState,
    action: IAction<CastAgencyModel[]>,
  ): IProfileState {
    return {
      ...state,
      likedAgencies: action.payload!,
    };
  },

  [ProfilesAction.REQUEST_CAST_PROFILE_FINISHED](
    state: IProfileState,
    action: IAction<CastAgencyModel>,
  ): IProfileState {
    return {
      ...state,
      castProfile: action.payload!,
    };
  },

  [ProfilesAction.REQUEST_CAST_LIKED_ACTORS_FINISHED](
    state: IProfileState,
    action: IAction<ActorModel[]>,
  ): IProfileState {
    return {
      ...state,
      castLikedActor: action.payload!,
    };
  },

  [ProfilesAction.REQUEST_ADD_GRADUATION_FINISHED](
    state: IProfileState,
    action: IAction<GraduationModel>,
  ): IProfileState {
    if (state.profile instanceof ProfileModel) {
      return {
        ...state,
        requestAddGraduation: "success",
        profile: new ProfileModel({ ...state.profile, graduations: [...state.profile.graduations!, action.payload!] }),
      };
    }
    return state;
  },

  [ProfilesAction.CLEAR_REQUEST_ADD_GRADUATION](state: IProfileState): IProfileState {
    return {
      ...state,
      requestAddGraduation: undefined,
    };
  },

  [ProfilesAction.REQUEST_UPDATE_GRADUATION_FINISHED](
    state: IProfileState,
    action: IAction<GraduationModel[]>,
  ): IProfileState {
    if (state.profile instanceof ProfileModel) {
      return {
        ...state,
        requestUpdateGraduation: "success",
        profile: new ProfileModel({ ...state.profile, graduations: action.payload! }),
        //profile: new ProfileModel({...state.profile, graduations: [...state.profile.graduations!, action.payload!]})
      };
    }
    return state;
  },

  [ProfilesAction.CLEAR_REQUEST_UPDATE_GRADUATION](state: IProfileState): IProfileState {
    return {
      ...state,
      requestUpdateGraduation: undefined,
    };
  },

  [ProfilesAction.REQUEST_DELETE_GRADUATION_FINISHED](
    state: IProfileState,
    action: IAction<GraduationModel[]>,
  ): IProfileState {
    if (state.profile instanceof ProfileModel) {
      return {
        ...state,
        requestDeleteGraduation: "success",
        profile: new ProfileModel({ ...state.profile, graduations: action.payload! }),
      };
    }
    return state;
  },

  [ProfilesAction.CLEAR_REQUEST_DELETE_GRADUATION](state: IProfileState): IProfileState {
    return {
      ...state,
      requestDeleteGraduation: undefined,
    };
  },
});

export default profilesReducer;
