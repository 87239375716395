import * as SkillsEffect from "./SkillsEffect";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as ActionUtility from "utilities/ActionUtility";
import { ReduxDispatch } from "models/ReduxProps";
import SkillModel from "./models/SkillModel";

type ActionUnion = undefined | HttpErrorResponseModel | SkillModel[] | SkillModel | string;

export const REQUEST_SEARCH_ACTOR_SKILLS: string = "SkillsAction.REQUEST_SEARCH_ACTOR_SKILLS";
export const REQUEST_SEARCH_ACTOR_SKILLS_FINISHED: string = "SkillsAction.REQUEST_SEARCH_ACTOR_SKILLS_FINISHED";

export const requestSearchActorSkills = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<SkillModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SEARCH_ACTOR_SKILLS,
      SkillsEffect.requestActorSkills,
    );
  };
};
