import { BaseModel } from "sjs-base-model";

export default class MeetingLocation extends BaseModel {
  public readonly id: string = "";
  public readonly address: string = "";
  public readonly message: string = "";
  public readonly date: string = "";

  constructor(data: Partial<MeetingLocation>) {
    super();

    this.update(data);
  }
}
