import environment from "environment";

import { AxiosResponse } from "axios";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";
import ProjectModel from "../models/ProjectModel";
import { IRoleFormModel } from "./models/IRoleFormModel";
import RoleModel from "./models/RoleModel";

export const requestProjectRoles = async (slug: string): Promise<RoleModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roles.index.replace(":project_slug", slug);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<RoleModel>) => new RoleModel(json));
};

export const requestCreateRole = async (formData: IRoleFormModel): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.index + "roles/";
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data.uuid;
};

export const requestSearchProjectRole = async (
  term: string | null,
  slug: string,
  type: string,
  roleStatus?: string,
): Promise<RoleModel[] | HttpErrorResponseModel> => {
  //let endpoint: string = environment.api.projects.index  + `roles/${type}/${slug}/`;
  //let endpoint: string = environment.api.projects.index  + `${type}/${slug}/roles/?search=${term}`;
  let endpoint: string = environment.api.projects.index + `${type}/${slug}/roles/`;

  //let endpoint: string = environment.api.projects.index  + `${type}/${slug}/roles/?search=${term}&status=${roleStatus}`;
  if (term) {
    endpoint = endpoint + `?role_name=${term}`;
  }

  if (roleStatus) {
    endpoint = endpoint + (term ? "&" : "") + `?role_status=${roleStatus}`;
  }

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.map((json: Partial<RoleModel>) => new RoleModel(json));
};

export const findByUUID = async (role_uuid: string): Promise<RoleModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.index + `roles/${role_uuid}/`;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new RoleModel(response.data);
};

export const requestUpdateProjectRole = async (
  formData: IRoleFormModel,
  uuid: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.index + `roles/${uuid}/`;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data.uuid;
};

export const requestProject = async (slug: string): Promise<ProjectModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.index.concat(slug, "/");

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new ProjectModel(response.data);
};
