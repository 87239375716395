import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.min.css";
import "@icon/icofont/icofont.css";

import i18n from "./i18n";
import { Store } from "redux";
import ReactDOM from "react-dom";
import environment from "environment";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { I18nextProvider } from "react-i18next";
import { createBrowserHistory, History } from "history";

import IStore from "./models/IStore";
import rootStore from "./stores/rootStore";
import App from "./views/App";

import "./index.css";

(async (window: Window): Promise<void> => {
  const initialState: Partial<IStore> = {};
  const history: History = createBrowserHistory({
    basename: environment.route.baseRoute,
  });
  const store: Store<IStore> = rootStore(initialState, history);

  const rootEl: HTMLElement | null = document.getElementById("root");

  const render = (Component: typeof App, el: HTMLElement | null): void => {
    ReactDOM.render(
      <Provider store={store}>
        <CookiesProvider>
          <I18nextProvider i18n={i18n}>
            <Component history={history} />
          </I18nextProvider>
        </CookiesProvider>
      </Provider>,
      el,
    );
  };

  render(App, rootEl);
})(window);
