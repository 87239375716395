import * as GenresEffect from "./GenresEffect";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as ActionUtility from "utilities/ActionUtility";
import GenreModel from "./models/GenreModel";
import { ReduxDispatch } from "models/ReduxProps";

type ActionUnion = undefined | HttpErrorResponseModel | GenreModel[] | string;

export const REQUEST_GENRES: string = "GenresAction.REQUEST_GENRES";
export const REQUEST_GENRES_FINISHED: string = "GenresAction.REQUEST_GENRES_FINISHED";

export const requestGenres = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<GenreModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_GENRES,
      GenresEffect.requestGenres,
    );
  };
};
