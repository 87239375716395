/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(baseApi: string) {
  return {
    route: {
      // TODO: url '/react-reddux-architecture' but cant run. will fixed
      baseRoute: "", //'/react-redux-architecture',
      // Fixes issue with Github Pages
    },
    api: {
      projects: {
        index: `${baseApi}/projects/`,
        commercials: `${baseApi}/projects/commercials/`,
        movies: `${baseApi}/projects/movies/`,
        series: `${baseApi}/projects/series/`,
        updateMovies: `${baseApi}/projects/movies/:slug/`,
        updateSeries: `${baseApi}/projects/series/:slug/`,
        updateCommercial: `${baseApi}/projects/commercials/:slug/`,
        platforms: `${baseApi}/projects/platforms/`,
        genres: `${baseApi}/projects/genres/`,
        photoChannels: `${baseApi}/projects/photochannels/`,
        roles: {
          index: `${baseApi}/projects/:project_slug/roles/`,
          invitations: `${baseApi}/projects/roles/:uuid/invitations/`,
          applications: `${baseApi}/projects/roles/:uuid/applications/`,
          generate_meeting_evaluation_link: `${baseApi}/projects/roleapplicationmeetingevaluations/`,
          meeting_evaluation_link: `${baseApi}/projects/roleapplicationmeetingevaluations/`,
          meeting_evaluation_actors: `${baseApi}/projects/roleapplicationmeetingevaluations/:uuid/get_actors`,
          create_role_application_evaluation: `${baseApi}/projects/roleapplicationmeetingevaluations/:uuid/create_evaluation/`,
          get_actor_audition_videos: `${baseApi}/projects/roleapplicationmeetingevaluations/:uuid/get_actor_audition_videos/`,
        },
        roleinvitations: {
          index: `${baseApi}/projects/roleinvitations/`,
          roleinvitation: `${baseApi}/projects/roleinvitations/:uuid/`,
          withdraw: `${baseApi}/projects/roleinvitations/:uuid/withdraw/`,
          accept: `${baseApi}/projects/roleinvitations/:uuid/accept/`,
          decline: `${baseApi}/projects/roleinvitations/:uuid/decline/`,
          resend: `${baseApi}/projects/roleinvitations/:uuid/resend/`,
        },
        roleapplications: {
          index: `${baseApi}/projects/roleapplications/`,
          roleapplication: `${baseApi}/projects/roleapplications/:uuid/`,
          present: `${baseApi}/projects/roleapplications/:uuid/present/`,
          invite_to_callback: `${baseApi}/projects/roleapplications/:uuid/invite_to_callback/`,
          update_meeting_location: `${baseApi}/projects/roleapplications/:uuid/update_meeting_location/`,
          shortlist: `${baseApi}/projects/roleapplications/:uuid/shortlist/`,
          decline: `${baseApi}/projects/roleapplications/:uuid/decline/`,
          make_offer: `${baseApi}/projects/roleapplications/:uuid/make_offer/`,
          option: `${baseApi}/projects/roleapplications/:uuid/option/`,
          elect: `${baseApi}/projects/roleapplications/:uuid/elect/`,
          finalize: `${baseApi}/projects/roleapplications/:uuid/finalize/`,
          pay_to_cast_agency: `${baseApi}/projects/roleapplications/:uuid/pay_to_cast_agency/`,
          pay_to_actor: `${baseApi}/projects/roleapplications/:uuid/pay_to_actor/`,
        },
        auditionvideos: {
          index: `${baseApi}/projects/auditionvideos/`,
          auditionvideo: `${baseApi}/projects/auditionvideos/:uuid/`,
        },
        roleoffers: {
          index: `${baseApi}/projects/roleoffers/`,
          offer: `${baseApi}/projects/roleoffers/:uuid/`,
          accept: `${baseApi}/projects/roleoffers/:uuid/accept/`,
          decline: `${baseApi}/projects/roleoffers/:uuid/decline/`,
          withdraw: `${baseApi}/projects/roleoffers/:uuid/withdraw/`,
          cancel: `${baseApi}/projects/roleoffers/:uuid/cancel/`,
        },
        roleapplication_evaluations: {
          index: `${baseApi}/projects/roleapplicationevaluations/`,
        },
        roleapplication_evaluation_criteria: {
          index: `${baseApi}/projects/roleapplicationevaluationcriteria/`,
        },
      },
      companies: {
        index: `${baseApi}/companies/`,
        productions: {
          index: `${baseApi}/companies/productions/`,
          production: `${baseApi}/companies/productions/:slug/`,
          roleapplications: `${baseApi}/companies/productions/:slug/roleapplications/`,
        },
        casts: {
          index: `${baseApi}/companies/casts/`,
          cast: `${baseApi}/companies/casts/:slug/`,
          actors: `${baseApi}/companies/casts/:slug/actors/`,
          like: `${baseApi}/companies/casts/:slug/like/`, // post=> for actor like action to cast agency
          unlike: `${baseApi}/companies/casts/:slug/unlike/`, // post=> for actor unlike action to cast agency
          likes: `${baseApi}/companies/casts/:slug/likes/`, // get=> for cast' like actor list
          liked_by: `${baseApi}/companies/casts/:slug/liked_by/`,
          roleinvitations: `${baseApi}/companies/casts/:slug/roleinvitations/`,
          roleapplications: `${baseApi}/companies/casts/:slug/roleapplications/`,
          roleoffers: `${baseApi}/companies/casts/:slug/roleoffers/`,
          projects: `${baseApi}/companies/casts/:slug/projects/`,
        },
        clients: {
          index: `${baseApi}/companies/clients/`,
          client: `${baseApi}/companies/clients/:slug/`,
        },
      },
      actors: {
        index: `${baseApi}/actors/`,
        actor: `${baseApi}/actors/:uuid/`,
        me: `${baseApi}/actors/me/`,
        children: {
          index: `${baseApi}/actors/children/`,
          child: `${baseApi}/actors/children/:uuid/`,
        },
        photos: `${baseApi}/actors/photos/`,
        videos: `${baseApi}/actors/videos/`,
        tags: `${baseApi}/actors/tags/`,
        skills: `${baseApi}/actors/skills/`,
        references: {
          index: `${baseApi}/actors/references/`,
          reference: `${baseApi}/actors/references/:uuid/`,
        },
        profile: `${baseApi}/actors/:uuid/profile/`,
        agencies: `${baseApi}/actors/:uuid/agencies/`,
        likes: `${baseApi}/actors/:uuid/likes/`,
        like: `${baseApi}/actors/:uuid/like/`, // POST => Cast agency like action to actor
        unlike: `${baseApi}/actors/:uuid/unlike/`, // POST => Cast agency unlike action to actor
        liked_by: `${baseApi}/actors/:uuid/liked_by/`, // POST => Cast agency like action to actor
        evaluations: {
          index: `${baseApi}/actors/evaluations/`,
          evaluation: `${baseApi}/actors/evaluations/:uuid/`,
        },
        roleinvitations: `${baseApi}/actors/:uuid/roleinvitations/`,
        roleapplications: `${baseApi}/actors/:uuid/roleapplications/`,
      },
      reports: {
        general_budget_and_cash: `${baseApi}/reports/general_budget_and_cash`,
        general_projects: `${baseApi}/reports/general_projects`,
        invitation_and_application_stats: `${baseApi}/reports/invitation_and_application_stats`,
        resource_distribution_by_project: `${baseApi}/reports/resource_distribution_by_project`,
        resource_distribution_by_project_type: `${baseApi}/reports/resource_distribution_by_project_type`,
        role_and_application_distribution: `${baseApi}/reports/role_and_application_distribution`,
      },
      auth: {
        login: `${baseApi}/auth/login/`,
        register: {
          index: `${baseApi}/auth/registration/`,
          verify_email: `${baseApi}/auth/registration/verify-email/`,
          resend_email: `${baseApi}/auth/registration/resend-email/`,
        },
        password: {
          change: `${baseApi}/auth/password/change/`,
          reset: `${baseApi}/auth/password/reset/`,
          confirm: `${baseApi}/auth/password/reset/confirm/`,
        },
        logout: `${baseApi}/auth/logout/`,
        user: `${baseApi}/auth/user/`,
        google: `${baseApi}/auth/google/`,
        invites: `${baseApi}/auth/invites/`,
      },
      users: {
        all: `${baseApi}/users/`,
        me: `${baseApi}/users/me/`,
        update_language: `${baseApi}/users/update_language/`,
      },
      profiles: {
        index: `${baseApi}/profiles/`,
        me: `${baseApi}/profiles/me/`,
        update: `${baseApi}/profiles/:userName/`,
      },
      locations: {
        countries: {
          index: `${baseApi}/locations/countries/`,
          regions: `${baseApi}/locations/countries/:country_pk/regions/`,
        },
        regions: {
          index: `${baseApi}/locations/regions/`,
          subregions: `${baseApi}/locations/regions/:region_pk/subregions/`,
          cities: `${baseApi}/locations/regions/:region_pk/cities/`,
        },
        subregions: {
          index: `${baseApi}/locations/subregions/`,
        },
        cities: {
          index: `${baseApi}/locations/cities/`,
        },
      },
      graduations: {
        index: `${baseApi}/graduations/`,
        graduation: `${baseApi}/graduations/:uuid/`,
      },
      languages: {
        index: `${baseApi}/languages/`,
        language: `${baseApi}/languages/:uuid/`,
      },
      pages: {
        contact: `${baseApi}/contact/`,
      },
      notifications: {
        index: `${baseApi}/notifications/`,
        seen: `${baseApi}/notifications/:uuid/seen/`,
        seen_all: `${baseApi}/notifications/seen_all/`,
      },

      errorExamplePage: "https://httpstat.us/520",
    },
    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  };
}

export type Environment = ReturnType<typeof baseEnv>;
