import { BaseModel } from "sjs-base-model";

export default class ActorTagModel extends BaseModel {
  public readonly name: string = "";
  public readonly slug: string = "";
  public readonly category: string = "";

  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<ActorTagModel>) {
    super();

    this.update(data);
  }
}
