import IAction from "models/IAction";
import IPagination from "models/IPagination";
import * as ClientsAction from "./ClientsAction";
import ICompaniesState from "../models/ICompainesState";
import ClientModel from "../clients/models/ClientModel";
import ClientOptionsModel from "../clients/models/ClientOptionsModel";

const ClientsReducer = {
  [ClientsAction.REQUEST_CLIENT_COMPANIES_FINISHED](
    state: ICompaniesState,
    action: IAction<IPagination<ClientModel[]>>,
  ): ICompaniesState {
    return {
      ...state,
      clients: action.payload!,
    };
  },

  [ClientsAction.REQUEST_CLIENT_COMPANY_OPTIONS_FINISHED](
    state: ICompaniesState,
    action: IAction<ClientOptionsModel>,
  ): ICompaniesState {
    return {
      ...state,
      clientOptions: action.payload!,
    };
  },

  [ClientsAction.REQUEST_CLIENT_COMPANY_FINISHED](
    state: ICompaniesState,
    action: IAction<ClientModel>,
  ): ICompaniesState {
    return {
      ...state,
      clientDetail: action.payload!,
    };
  },

  [ClientsAction.CLEAR_CLIENT_COMPANY_DETAIL](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      clientDetail: undefined,
    };
  },

  [ClientsAction.REQUEST_UPDATE_CLIENT_COMPANY_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestUpdateClientCompany: action.payload,
    };
  },

  [ClientsAction.CLEAR_REQUEST_UPDATE_CLIENT_COMPANY](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      requestUpdateClientCompany: undefined,
    };
  },

  [ClientsAction.REQUEST_CREATE_CLIENT_COMPANY_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestCreateClientCompany: action.payload,
    };
  },

  [ClientsAction.CLEAR_REQUEST_CREATE_CLIENT_COMPANY](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      requestCreateClientCompany: undefined,
    };
  },
};

export default ClientsReducer;
