import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import { AxiosResponse } from "axios";
import { removeCookieData } from "utilities/CookieUtility";
import CookieEnum from "constants/CookieEnum";

export const requestLogout = async (): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.auth.logout;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);
  removeCookieData(CookieEnum.User);
  removeCookieData(CookieEnum.Token);
  if (response instanceof HttpErrorResponseModel) {
    return "Logout";
  }
  return "Logout";
};
