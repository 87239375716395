import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import MovieProjectModel from "./models/MovieProjectModel";
import { AxiosResponse } from "axios";
import { selectErrors } from "utilities/ResponseErrorUtility";
import IPagination from "models/IPagination";

export const requestMovieProjects = async (
  search: string,
  page?: number,
): Promise<IPagination<MovieProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.movies.concat("?search=", search);
  if (page) {
    endpoint = endpoint.concat("&page=", page.toString());
  }
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  let result: IPagination<MovieProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    currentPage: page,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<MovieProjectModel>) => new MovieProjectModel(json)),
  };

  return result;
};

export const requestMovieProject = async (slug: string): Promise<MovieProjectModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.movies + slug + "/";
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new MovieProjectModel(response.data);
};

export const requestCreateMoviesProject = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.movies;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }
  return response.data.slug; //TODO CHANGE TO SLUG
};

export const requestUpdateMoviesProject = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.updateMovies.replace(":slug", slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data.production;
};

export const requestMoviesProjectsFilter = async (
  filters: string,
): Promise<IPagination<MovieProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.movies + `${filters}`;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  let result: IPagination<MovieProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<MovieProjectModel>) => new MovieProjectModel(json)),
  };

  return result;
};

export const requestUpdateMoviesProjectStatus = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.updateMovies.replace(":slug", slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  return response.data.production;
};
