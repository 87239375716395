import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";

import AuditionVideoModel from "models/api-models/audition-video/AuditionVideoModel";

export default interface IActorRoleApplication {
  readonly requestCreateRoleApplication: string | undefined;
  readonly requestUpdateRoleApplication: string | undefined;
  readonly invitationDetail: RoleInvitationModel | undefined;
  readonly applicationDetail: RoleApplicationModel | undefined;
  readonly applications: RoleApplicationModel[];

  readonly editAudition: AuditionVideoModel | undefined;
  readonly requestCreateAuditionVideo: string | undefined;
  readonly requestDeleteAuditionVideo: string | undefined;
  readonly requestUpdateAuditionVideo: string | undefined;

  readonly requestAcceptRoleOffer: string | undefined;
  readonly requestDeclineRoleOffer: string | undefined;
  readonly requestCancelRoleOffer: string | undefined;
}

export const initialState: IActorRoleApplication = {
  requestCreateRoleApplication: undefined,
  requestUpdateRoleApplication: undefined,
  invitationDetail: undefined,
  applicationDetail: undefined,
  applications: [],

  editAudition: undefined,
  requestCreateAuditionVideo: undefined,
  requestDeleteAuditionVideo: undefined,
  requestUpdateAuditionVideo: undefined,

  requestAcceptRoleOffer: undefined,
  requestDeclineRoleOffer: undefined,
  requestCancelRoleOffer: undefined,
};
