import RouteEnum from "constants/RouteEnum";
import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import AuthRoute from "views/components/router-components/auth-route/Auth-Route";
import PublicRoute from "views/components/router-components/public-route/PublicRoute";

const HomePage = lazy(() => import("views/home-page/HomePage"));
const LoginPage = lazy(() => import("views/auth-page/login-page/LoginPage"));
const RegisterPage = lazy(() => import("views/auth-page/register-page/RegisterPage"));
const VerifyEmailPage = lazy(() => import("views/auth-page/verify-email-page/VerifyEmailPage"));
const ResetPasswordPage = lazy(() => import("views/auth-page/reset-password-page/ResetPasswordPage"));
const ResendEmailPage = lazy(() => import("views/auth-page/resend-email-page/ResendEmailPage"));
const ConfirmPasswordPage = lazy(() => import("views/auth-page/confirm-password-page/ConfirmPasswordPage"));
const ReportPages = lazy(() => import("views/reports-pages/ReportPages"));
const ProjectPages = lazy(() => import("views/project-pages/ProjectPages"));
const ProfilePages = lazy(() => import("views/profile-pages/ProfilePages"));
const ActorPages = lazy(() => import("views/actor-pages/ActorPages"));
const CompaniesPages = lazy(() => import("views/companies-pages/CompaniesPages"));
const AboutPage = lazy(() => import("views/about-page/AboutPage"));
const ContactPage = lazy(() => import("views/contact-page/ContactPage"));
const InvitationMemberPage = lazy(() => import("views/auth-page/invitation-member-page/InvitationMemberPage"));
const NotificationPages = lazy(() => import("views/notifications-page/NotificationPages"));
const RoleApplicationMeetingEvaluationPage = lazy(
  () => import("views/role-application-meeting-evaluation-page/RoleApplicationMeetingEvaluationPage"),
);

const Routers: React.FC = () => {
  return (
    <Switch>
      <AuthRoute path={RouteEnum.Login} component={LoginPage} />
      <AuthRoute path={RouteEnum.Register} component={RegisterPage} />
      <Route path={RouteEnum.VerifyEmail} component={VerifyEmailPage} />
      <Route path={RouteEnum.InvitationMember} component={InvitationMemberPage} />
      <Route path={RouteEnum.ResendEmail} component={ResendEmailPage} />
      <Route path={RouteEnum.ResetPasswordConfirm} component={ConfirmPasswordPage} />
      <Route exact={true} path={RouteEnum.ResetPassword} component={ResetPasswordPage} />

      {/* redux: auth.selectedProfile === "cast_agency_employees" */}
      {/* redux: auth.selectedProfile === "production_company_employees" */}
      <Route path={RouteEnum.Reports} component={ReportPages} />

      <Route path={RouteEnum.Profile} component={ProfilePages} />

      <Route path={RouteEnum.Projects} component={ProjectPages} />

      <Route path={RouteEnum.Actors} component={ActorPages} />
      <Route path={RouteEnum.Companies} component={CompaniesPages} />
      <Route path={RouteEnum.Notifications} component={NotificationPages} />

      <Route path={RouteEnum.RoleApplicationMeetingEvaluation} component={RoleApplicationMeetingEvaluationPage} />
      <PublicRoute path={RouteEnum.ContactPage} component={ContactPage} />
      <PublicRoute path={RouteEnum.AboutPage} component={AboutPage} />
      <PublicRoute path={RouteEnum.Home} component={HomePage} />
    </Switch>
  );
};

export default Routers;
