import { BaseModel } from "sjs-base-model";
import NotificationContentModel from "./NotificationContentModel";

export default class NotificationModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly created_at: string = "";
  public readonly seen_at: string | null = "";
  public readonly seen: boolean = false;
  public readonly content: NotificationContentModel | null = null;
  public readonly url: string | null = "";
  public readonly profile_type: string | null = "";

  constructor(data: Partial<NotificationModel>) {
    super();

    this.update(data);
  }
}
