import environment from "environment";

import { AxiosResponse } from "axios";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IPagination from "models/IPagination";
import * as EffectUtility from "utilities/EffectUtility";
import * as HttpUtility from "utilities/HttpUtility";
import ProductionModel from "./models/ProductionModel";
import ProductionOptionsModel from "./models/ProductionOptionsModel";

export const requestProductionOptions = async (): Promise<ProductionOptionsModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.productions.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.options(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new ProductionOptionsModel(response.data);
};

export const requestProductionCompanies = async (
  search: string,
  page?: number,
): Promise<IPagination<ProductionModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.companies.productions.index.concat("?search=", search);

  endpoint = page ? endpoint.concat("&page=", page.toString()) : endpoint;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const result: IPagination<ProductionModel[]> = {
    currentPage: page || 1,
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<ProductionModel>) => new ProductionModel(json)),
  };

  return result;
};

export const requestProductionCompany = async (slug: string): Promise<ProductionModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.productions.index.concat(slug);

  return EffectUtility.getToModel<ProductionModel>(ProductionModel, endpoint);
};

export const requestUpdateProductionCompany = async (
  formData: any,
  slug: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.productions.production.replace(":slug", slug);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.slug;
};

export const requestCreateProductionCompany = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.productions.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.slug;
};
