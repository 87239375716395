import { AxiosResponse } from "axios";

import environment from "environment";
import * as HttpUtility from "utilities/HttpUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import AuditionVideoModel from "models/api-models/audition-video/AuditionVideoModel";
import RoleOfferModel from "models/api-models/role-offer/RoleOfferModel";

export const requestRoleApplication = async (uuid: string): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.roleapplication.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new RoleApplicationModel(response.data);
};

export const requestCreateRoleApplication = async (
  formData: any,
): Promise<RoleApplicationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new RoleApplicationModel(response.data);
};

export const requestUpdateRoleApplication = async (
  formData: any,
  uuid: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleapplications.roleapplication.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.cover_letter;
  //return new RoleApplicationModel(response.data);
};

export const requestRoleInvitation = async (uuid: string): Promise<RoleInvitationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.roleinvitation.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new RoleInvitationModel(response.data);
};

export const requestRoleApplications = async (
  uuid: string,
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.roleapplications.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map((json: Partial<RoleApplicationModel>) => new RoleApplicationModel(json));
};

export const requestCreateAuditionVideo = async (
  formData: any,
  auditionVideos: AuditionVideoModel[] | undefined,
): Promise<AuditionVideoModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.auditionvideos.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  const newAudVideo: AuditionVideoModel = new AuditionVideoModel(response.data);
  if (auditionVideos) {
    return [...auditionVideos, newAudVideo];
  }

  return [newAudVideo];
};

export const requestDeleteAuditionVideo = async (
  uuid: string,
  auditionVideos: AuditionVideoModel[] | undefined,
): Promise<AuditionVideoModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.auditionvideos.auditionvideo.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.del(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  if (auditionVideos) {
    let newAuditionVideos: AuditionVideoModel[] = auditionVideos.filter((object) => object.uuid !== uuid);

    return newAuditionVideos;
  }

  return [];
};

export const requestUpdateAuditionVideo = async (
  uuid: string,
  formData: any,
  auditionVideos: AuditionVideoModel[] | undefined,
): Promise<AuditionVideoModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.auditionvideos.auditionvideo.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  if (auditionVideos) {
    const audition_video: AuditionVideoModel | undefined = auditionVideos.find((object) => object.uuid === uuid);
    const updatedAuditionVideo: AuditionVideoModel = new AuditionVideoModel({ ...audition_video, ...response.data });
    const index: number = auditionVideos.findIndex((object) => object.uuid === uuid);
    let newAuditionVideos = auditionVideos.filter((object) => object.uuid !== uuid);

    newAuditionVideos.splice(index, 0, updatedAuditionVideo);
    return newAuditionVideos;
  }

  return [];
};

export const requestAcceptRoleOffer = async (uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleoffers.accept.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "success";
};

export const requestDeclineRoleOffer = async (
  uuid: string,
  roleoffers: RoleOfferModel[] | undefined,
): Promise<RoleOfferModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleoffers.decline.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  if (roleoffers) {
    const updatedOffer: RoleOfferModel = new RoleOfferModel(response.data);
    const index: number = roleoffers.findIndex((object) => object.uuid === uuid);
    let newOffers = roleoffers.filter((object) => object.uuid !== uuid);

    newOffers.splice(index, 0, updatedOffer);
    return newOffers;
  }

  return [];
};

export const requestCancelRoleOffer = async (uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleoffers.cancel.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return "success";
};
