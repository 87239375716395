import * as RegistersEffect from "./RegistersEffect";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as ActionUtility from "utilities/ActionUtility";
import { ReduxDispatch } from "models/ReduxProps";
import IAction from "models/IAction";

type ActionUnion = undefined | HttpErrorResponseModel | string;

export const REQUEST_REGISTER: string = "RegisterAction.REQUEST_REGISTER";
export const REQUEST_REGISTER_FINISHED: string = "RegisterAction.REQUEST_REGISTER_FINISHED";

export const requestRegister = (userName: string, email: string, password: string, confirmPassword: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_REGISTER,
      RegistersEffect.requestRegister,
      userName,
      email,
      password,
      confirmPassword,
    );
  };
};

export const CLEAR_MESSAGE: string = "RegisterAction.CLEAR_MESSAGE";

export const clearMessage = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_MESSAGE);
};

export const REQUEST_VERIFY_EMAIL: string = "RegisterAction.REQUEST_VERIFY_EMAIL";
export const REQUEST_VERIFY_EMAIL_FINISHED: string = "RegisterAction.REQUEST_VERIFY_EMAIL_FINISHED";

export const requestVerifyEmail = (key: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_VERIFY_EMAIL,
      RegistersEffect.requestVerifyEmail,
      key,
    );
  };
};

export const REQUEST_RESET_PASSWORD: string = "RegisterAction.REQUEST_RESET_PASSWORD";
export const REQUEST_RESET_PASSWORD_FINISHED: string = "RegisterAction.REQUEST_RESET_PASSWORD_FINISHED";

export const requestResetPassword = (email: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string>(
      dispatch,
      REQUEST_RESET_PASSWORD,
      RegistersEffect.requestResetPassword,
      email,
    );
  };
};

export const REQUEST_RESEND_EMAIL: string = "RegisterAction.REQUEST_RESEND_EMAIL";
export const REQUEST_RESEND_EMAIL_FINISHED: string = "RegisterAction.REQUEST_RESEND_EMAIL_FINISHED";

export const requestResendEmail = (email: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_RESEND_EMAIL,
      RegistersEffect.requestResendEmail,
      email,
    );
  };
};

export const REQUEST_PASSWORD_CONFIRM: string = "RegisterAction.REQUEST_PASSWORD_CONFIRM";
export const REQUEST_PASSWORD_CONFIRM_FINISHED: string = "RegisterAction.REQUEST_PASSWORD_CONFIRM_FINISHED";

export const requestConfirmResetPassword = (
  uid: string,
  token: string,
  new_password1: string,
  new_password2: string,
): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PASSWORD_CONFIRM,
      RegistersEffect.confirmResetPassword,
      uid,
      token,
      new_password1,
      new_password2,
    );
  };
};

export const REQUEST_ERROR: string = "RegisterAction.REQUEST_ERROR";
export const REQUEST_ERROR_FINISHED: string = "RegisterAction.REQUEST_ERROR_FINISHED";

export const requestError = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<any>(dispatch, REQUEST_ERROR, RegistersEffect.requestError);
  };
};
