import { BaseModel } from "sjs-base-model";

export default class RoleAndApplicationDistributionModel extends BaseModel {
  public readonly project_role_name: string = "";
  public readonly roleapplication_count: number = 0;

  constructor(data: Partial<RoleAndApplicationDistributionModel>) {
    super();

    this.update(data);
  }
}
