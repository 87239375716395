import IAction from "models/IAction";
import IPagination from "models/IPagination";
import IProjectState from "../models/IProjectsState";
import SeriesProjectModel from "./models/SeriesProjectModel";
import * as SeriesProjectsAction from "./SeriesProjectsAction";

const SeriesProjectsReducer = {
  [SeriesProjectsAction.REQUEST_SERIES_PROJECTS_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<SeriesProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      series: action.payload!,
    };
  },

  [SeriesProjectsAction.REQUEST_SERIES_PROJECT_DETAIL_FINISHED](
    state: IProjectState,
    action: IAction<SeriesProjectModel>,
  ): IProjectState {
    return {
      ...state,
      seriesDetail: action.payload!,
    };
  },

  [SeriesProjectsAction.REQUEST_CREATE_SERIES_PROJECT_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      createdProject: action.payload!,
    };
  },

  [SeriesProjectsAction.REQUEST_UPDATE_SERIES_PROJECT_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      updatedProject: action.payload!,
    };
  },

  [SeriesProjectsAction.FILTER_SERIES_PROJECTS_SHOW](state: IProjectState, action: IAction<boolean>): IProjectState {
    return {
      ...state,
      seriesFilter: {
        ...state.seriesFilter,
        filtersShow: action.payload!,
      },
    };
  },

  [SeriesProjectsAction.REQUEST_SERIES_PROJECTS_FILTER_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<SeriesProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      series: action.payload!,
    };
  },

  [SeriesProjectsAction.REQUEST_UPDATE_SERIES_PROJECT_STATUS_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      updatedSeriesStatus: action.payload!,
    };
  },

  [SeriesProjectsAction.CLEAR_REQUEST_UPDATE_SERIES_PROJECT_STATUS](state: IProjectState): IProjectState {
    return {
      ...state,
      updatedSeriesStatus: undefined,
    };
  },

  [SeriesProjectsAction.REQUEST_FIND_SERIES_PROJECT_DETAIL_FINISHED](
    state: IProjectState,
    action: IAction<SeriesProjectModel>,
  ): IProjectState {
    return {
      ...state,
      seriesDetail: action.payload!,
    };
  },
};

export default SeriesProjectsReducer;
