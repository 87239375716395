import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import IPagination from "models/IPagination";
import { ReduxDispatch } from "models/ReduxProps";
import * as ActionUtility from "utilities/ActionUtility";
import * as ProjectsEffect from "./ProjectsEffect";
import ProjectModel from "./models/ProjectModel";

type ActionUnion = undefined | HttpErrorResponseModel | IPagination<ProjectModel[]> | ProjectModel | string;

export const REQUEST_PROJECTS: string = "ProjectsAction.REQUEST_PROJECTS";
export const REQUEST_PROJECTS_FINISHED: string = "ProjectsAction.REQUEST_PROJECTS_FINISHED";

export const requestProjects = (search: string, page?: number): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<ProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PROJECTS,
      ProjectsEffect.requestProjects,
      search,
      page,
    );
  };
};

export const CLEAR_REQUEST_PROJECTS: string = "ProjectsAction.CLEAR_REQUEST_PROJECTS";

export const clearRequestProjects = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_PROJECTS);
};

export const CLEAR_CREATED_PROJECT: string = "ProjectsAction.CLEAR_CREATED_PROJECT";

export const clearCreatedMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_CREATED_PROJECT);
};

export const CLEAR_UPDATED_PROJECT: string = "ProjectsAction.CLEAR_UPDATED_PROJECT";

export const clearUpdatedMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_UPDATED_PROJECT);
};

export const ADD_REDIRECT_FROM_PAGE: string = "ProjectsAction.ADD_REDIRECT_FROM_PAGE";

export const addRedirectFromPage = (page: string): IAction<string> => {
  return ActionUtility.createAction(ADD_REDIRECT_FROM_PAGE, page);
};

export const REMOVE_REDIRECT_FROM_PAGE: string = "ProjectsAction.REMOVE_REDIRECT_FROM_PAGE";

export const removeRedirectFromPage = (): IAction<string> => {
  return ActionUtility.createAction(REMOVE_REDIRECT_FROM_PAGE);
};

export const FILTER_PROJECTS_SHOW: string = "ProjectsAction.FILTER_PROJECTS_SHOW";

export const filterProjectsShow = (filter: boolean): IAction<boolean> => {
  return ActionUtility.createAction(FILTER_PROJECTS_SHOW, filter);
};

export const REQUEST_PROJECTS_FILTER: string = "ProjectsAction.REQUEST_PROJECTS_FILTER";
export const REQUEST_PROJECTS_FILTER_FINISHED: string = "ProjectsAction.REQUEST_PROJECTS_FILTER_FINISHED";

export const requestProjectsFilter = (filters: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<IPagination<ProjectModel[]> | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PROJECTS_FILTER,
      ProjectsEffect.requestProjectsFilter,
      filters,
    );
  };
};
