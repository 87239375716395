import { BaseModel } from "sjs-base-model";

export default class TagModel extends BaseModel {
  public readonly name: string = "";
  public readonly slug: string = "";
  public readonly category: string = "";

  constructor(data: Partial<TagModel>) {
    super();

    this.update(data);
  }
}
