import environment from "environment";

import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import TagModel from "./models/TagModel";

import { AxiosResponse } from "axios";

export const requestActorTags = async (): Promise<TagModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.tags;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.results.map((json: TagModel) => new TagModel(json));
};
