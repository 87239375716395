import IAction from "models/IAction";
import GeneralBudgetAndCashCastModel from "models/api-models/reports/GeneralBudgetAndCashCastModel";
import GeneralBudgetAndCashModel from "models/api-models/reports/GeneralBudgetAndCashModel";
import GeneralProjectsModel from "models/api-models/reports/GeneralProjectsModel";
import InvitationAndApplicationStats from "models/api-models/reports/InvitationAndApplicationStats";
import ResourceDistributionByProjectCastModel from "models/api-models/reports/ResourceDistributionByProjectCastModel";
import ResourceDistributionByProjectModel from "models/api-models/reports/ResourceDistributionByProjectModel";
import ResourceDistributionByProjectTypeModel from "models/api-models/reports/ResourceDistributionByProjectTypeModel";
import RoleAndApplicationDistributionModel from "models/api-models/reports/RoleAndApplicationDistributionModel";
import { Reducer } from "redux";
import baseReducer from "utilities/BaseReducer";
import * as ReportsAction from "./ReportsAction";
import IReportsState from "./models/IReportsState";

export const initialState: IReportsState = {
  generalBudgetAndCash: undefined,
  generalBudgetAndCashCast: undefined,
  generalProjects: undefined,
  invitationAndApplicationStats: undefined,
  roleAndApplicationDistribution: undefined,
  resourceDistributionByProject: undefined,
  resourceDistributionByProjectCast: undefined,
  resourceDistributionByProjectType: undefined,
};

const ReportsReducer: Reducer = baseReducer(initialState, {
  [ReportsAction.REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_FINISHED](
    state: IReportsState,
    action: IAction<GeneralBudgetAndCashModel>,
  ): IReportsState {
    return {
      ...state,
      generalBudgetAndCash: action.payload!,
    };
  },

  [ReportsAction.REQUEST_REPORTS_GENERAL_BUDGET_AND_CASH_CAST_FINISHED](
    state: IReportsState,
    action: IAction<GeneralBudgetAndCashCastModel>,
  ): IReportsState {
    return {
      ...state,
      generalBudgetAndCashCast: action.payload!,
    };
  },

  [ReportsAction.REQUEST_REPORTS_GENERAL_PROJECTS_FINISHED](
    state: IReportsState,
    action: IAction<GeneralProjectsModel>,
  ): IReportsState {
    return {
      ...state,
      generalProjects: action.payload!,
    };
  },

  [ReportsAction.REQUEST_REPORTS_INVITATION_AND_APPLICATION_STATS_FINISHED](
    state: IReportsState,
    action: IAction<InvitationAndApplicationStats[]>,
  ): IReportsState {
    return {
      ...state,
      invitationAndApplicationStats: action.payload!,
    };
  },

  [ReportsAction.REQUEST_REPORTS_ROLE_AND_APPLICATION_DISTRIBUTION_FINISHED](
    state: IReportsState,
    action: IAction<RoleAndApplicationDistributionModel[]>,
  ): IReportsState {
    return {
      ...state,
      roleAndApplicationDistribution: action.payload!,
    };
  },

  [ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_FINISHED](
    state: IReportsState,
    action: IAction<ResourceDistributionByProjectModel[]>,
  ): IReportsState {
    return {
      ...state,
      resourceDistributionByProject: action.payload!,
      resourceDistributionByProjectCast: undefined,
    };
  },

  [ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_CAST_FINISHED](
    state: IReportsState,
    action: IAction<ResourceDistributionByProjectCastModel[]>,
  ): IReportsState {
    return {
      ...state,
      resourceDistributionByProjectCast: action.payload!,
      resourceDistributionByProject: undefined,
    };
  },

  [ReportsAction.REQUEST_REPORTS_RESOURCE_DISTRIBUTION_BY_PROJECT_TYPE_FINISHED](
    state: IReportsState,
    action: IAction<ResourceDistributionByProjectTypeModel[]>,
  ): IReportsState {
    return {
      ...state,
      resourceDistributionByProjectType: action.payload!,
    };
  },

  [ReportsAction.CLEAR_REPORTS](): IReportsState {
    return {
      ...initialState,
    };
  },
});

export default ReportsReducer;
