import * as LogoutEffect from "./LogoutEffect";
import * as ActionUtility from "utilities/ActionUtility";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import { ReduxDispatch } from "models/ReduxProps";

type ActionUnion = undefined | HttpErrorResponseModel | string;

export const REQUEST_LOGOUT: string = "LogoutAction.REQUEST_LOGOUT";
export const REQUEST_LOGOUT_FINISHED: string = "LogoutAction.REQUEST_LOGOUT_FINISHED";

export const requestLogout = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_LOGOUT,
      LogoutEffect.requestLogout,
    );
  };
};
