import ProductionProjectModel from "stores/companies/productions/models/ProductionProjectModel";
import BaseCompanyModel from "../../models/BaseCompanyModel";

export default class ClientModel extends BaseCompanyModel {
  public readonly projects: ProductionProjectModel[] = [ProductionProjectModel as any];

  constructor(client_model: Partial<ClientModel>) {
    super(client_model);

    this.update(client_model);
  }
}
