import "./SwitchProfileModal.css";

import { Dispatch } from "redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, Button } from "react-bootstrap";

import IStore from "models/IStore";
import RoleEnum from "constants/RoleEnum";
import RouteEnum from "constants/RouteEnum";
import * as AuthAction from "stores/auth/AuthAction";
import IUserCookieModel from "models/common-models/IUserCookieModel";

import ToastStatusEnum from "constants/ToastStatusEnum";
import * as ToastAction from "stores/toasts/ToastsAction";

import ProfileAccount from "./components/ProfileAccount";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

import actorSvg from "media/svg/actor.svg";
import castSvg from "media/svg/cast-agency.svg";
import productionSvg from "media/svg/production.svg";
import clientSvg from "media/svg/client.svg";

interface IProps {}

const SwitchProfileModal: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const dispatch: Dispatch = useDispatch();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const user: IUserCookieModel | null = useSelector((state: IStore) => state.auth.user);
  const isVisible: boolean = useSelector((state: IStore) => state.auth.switchProfileModal);

  const selectedProfileState: string | undefined = useSelector((state: IStore) => state.auth.selectedProfile);
  const [selectedProfile, setSelectedProfile] = useState<string | undefined>();

  const handleClose = () => {
    setShow(false);
    dispatch(AuthAction.closeProfileSwitchModal());
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (isVisible) {
      setSelectedProfile(selectedProfileState);
      handleShow();
    } else {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (selectedProfileState !== selectedProfile && selectedProfileState !== undefined) {
      setSelectedProfile(selectedProfileState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfileState, show]);

  const setProfile = (role: string) => {
    setSelectedProfile(role);
  };

  const switchProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (selectedProfile) {
      dispatch(
        ToastAction.add(
          t("components.switch-profile-modal.switch-success-message").replace(
            "{profile}",
            t(`role-type.${selectedProfile}`),
          ),
          ToastStatusEnum.Success,
        ),
      );
      dispatch(AuthAction.setSelectedProfile(selectedProfile));
    }
  };

  return (
    <Fragment>
      <Modal show={show} onHide={handleClose} centered className="cs-profile-account">
        <ModalHeader className="d-flex justify-content-center border-0 p-1">
          <p style={{ fontSize: "24px" }}>
            <strong>{t("components.switch-profile-modal.header")}</strong>
          </p>
          <i className="icofont icofont-close cs-switch-profile-close" onClick={handleClose}></i>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap cs-switch-profile">
            {user?.actor_uuid && (
              <Fragment>
                <ProfileAccount
                  role={RoleEnum.Actor}
                  profileType="actor"
                  title={user?.first_name + " " + user?.last_name}
                  image={user?.actor_uuid ? user.avatar : null}
                  imageNull={actorSvg}
                  isProfile={user?.actor_uuid ? true : false}
                  changeProfile={setProfile}
                  selectedProfile={selectedProfile}
                  redirectToPath={RouteEnum.ActorProfile.replace(":uuid", user?.actor_uuid!)}
                />
              </Fragment>
            )}

            {user?.companies.cast_agency && (
              <ProfileAccount
                role={RoleEnum.CastAgency}
                profileType="cast"
                title={user?.companies.cast_agency.name}
                image={user?.companies.cast_agency?.logo!}
                imageNull={castSvg}
                isProfile={user?.companies.cast_agency ? true : false}
                changeProfile={setProfile}
                selectedProfile={selectedProfile}
                redirectToPath={RouteEnum.CastProfile.replace(":slug", user?.companies.cast_agency?.slug!)}
              />
            )}

            {user?.companies.production_company && (
              <ProfileAccount
                role={RoleEnum.ProductionCompany}
                profileType="production"
                title={user?.companies.production_company.name}
                image={user?.companies.production_company?.logo!}
                imageNull={productionSvg}
                isProfile={user?.companies.production_company ? true : false}
                changeProfile={setProfile}
                selectedProfile={selectedProfile}
                redirectToPath={RouteEnum.ProductionProfile.replace(":slug", user?.companies.production_company?.slug!)}
              />
            )}

            {user?.companies.client && (
              <ProfileAccount
                role={RoleEnum.Client}
                profileType="client"
                title={user?.companies.client.name}
                image={user?.companies.client?.logo!}
                imageNull={clientSvg}
                isProfile={user?.companies.client ? true : false}
                changeProfile={setProfile}
                selectedProfile={selectedProfile}
                redirectToPath={RouteEnum.ClientProfile.replace(":slug", user?.companies.client?.slug!)}
              />
            )}

            {!(
              user?.actor_uuid &&
              user?.companies.client &&
              user?.companies.production_company &&
              user?.companies.cast_agency
            ) && (
              <div className="cs-profile-account mb-3">
                <p className="pb-2">&nbsp;</p>
                <div className="cs-profile-img d-flex justify-content-center">
                  <Link
                    className="cs-profile-create d-flex justify-content-center align-items-center"
                    onClick={handleClose}
                    to={RouteEnum.ProfileCreate}
                  >
                    <i className="icofont icofont-plus"></i>
                  </Link>
                </div>
                <div className="d-flex justify-content-center text-center">
                  <span>{t("components.switch-profile-modal.create-profile")}</span>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="btn switch-profile-button mt-3" type="submit" onClick={switchProfile}>
            {t("components.switch-profile-modal.switch")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default SwitchProfileModal;
