import { BaseModel } from "sjs-base-model";

export default class ClientOptionsModel extends BaseModel {
  public readonly name: string = "";
  public readonly description: string = "";
  public readonly renders: Array<string> = [];
  public readonly parses: Array<string> = [];
  public readonly actions: Object = {};

  constructor(data: Partial<ClientOptionsModel>) {
    super();

    this.update(data);
  }
}
