import { AxiosResponse } from "axios";
import environment from "environment";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import * as EffectUtility from "utilities/EffectUtility";
import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";

export const requestAcceptRoleInvitation = async (
  uuid: string,
): Promise<RoleInvitationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.accept.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }
  return new RoleInvitationModel(response.data);
};

export const requestDeclineRoleInvitation = async (uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.decline.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }
  return "Done";
};

export const requestRoleInvitations = async (uuid: string): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.roleinvitations.replace(":uuid", uuid);
  return EffectUtility.getToModel<RoleInvitationModel[]>(RoleInvitationModel, endpoint);
};

/*

export const requestAcceptRoleInvitationCard = async (uuid: string, roleInvitations: RoleInvitationModel[]): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
    const endpoint: string = environment.api.projects.roleinvitations.accept.replace(':uuid', uuid);
    const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

    if (response instanceof HttpErrorResponseModel) {
        return response;
    }
    
    if(roleInvitations){
        const updatedRoleInvitation: RoleInvitationModel = new RoleInvitationModel(response.data);
        const objIndex = roleInvitations.findIndex((obj => obj.uuid === uuid));
    
        let newRoleInvitations = roleInvitations.filter(object => {
          return object.uuid !== uuid;
        });
    
        newRoleInvitations.splice(objIndex, 0, updatedRoleInvitation);
        return newRoleInvitations;
      }

    return [];
}*/

export const requestAcceptRoleInvitationCard = async (uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.accept.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.uuid;
};

export const requestDeclineRoleInvitationCard = async (
  uuid: string,
  roleInvitations: RoleInvitationModel[],
): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.decline.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  if (roleInvitations) {
    const updatedRoleInvitation: RoleInvitationModel = new RoleInvitationModel(response.data);
    const objIndex = roleInvitations.findIndex((obj) => obj.uuid === uuid);

    let newRoleInvitations = roleInvitations.filter((object) => {
      return object.uuid !== uuid;
    });

    newRoleInvitations.splice(objIndex, 0, updatedRoleInvitation);
    return newRoleInvitations;
  }

  return [];
};

export const requestRoleApplications = async (
  uuid: string,
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.roleapplications.replace(":uuid", uuid);
  return EffectUtility.getToModel<RoleApplicationModel[]>(RoleApplicationModel, endpoint);
};
