import "./NavTopMenu.css";

import React from "react";

interface IProps {}

const NavTopMenu: React.FC = (props: React.PropsWithChildren<IProps>) => {
  const children = props.children;
  return (
    <div className="nav-item nav-top-menu">
      <nav className="template-main-menu">
        <ul className="menu-content">{children}</ul>
      </nav>
    </div>
  );
};

export default NavTopMenu;
