import IAction from "models/IAction";
import IPagination from "models/IPagination";
import IProjectState from "../models/IProjectsState";
import MovieProjectModel from "./models/MovieProjectModel";
import * as MoviesProjectsAction from "./MoviesProjectsAction";

const MoviesProjectsReducer = {
  [MoviesProjectsAction.REQUEST_MOVIE_PROJECTS_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<MovieProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      movies: action.payload!,
    };
  },

  [MoviesProjectsAction.REQUEST_MOVIE_PROJECT_DETAIL_FINISHED](
    state: IProjectState,
    action: IAction<MovieProjectModel>,
  ): IProjectState {
    return {
      ...state,
      moviesDetail: action.payload!,
    };
  },

  [MoviesProjectsAction.REQUEST_CREATE_MOVIES_PROJECT_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      createdProject: action.payload!,
    };
  },

  [MoviesProjectsAction.REQUEST_UPDATE_MOVIES_PROJECT_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      updatedProject: action.payload!,
    };
  },

  [MoviesProjectsAction.FILTER_MOVIES_PROJECTS_SHOW](state: IProjectState, action: IAction<boolean>): IProjectState {
    return {
      ...state,
      moviesFilter: {
        ...state.moviesFilter,
        filtersShow: action.payload!,
      },
    };
  },

  [MoviesProjectsAction.REQUEST_MOVIES_PROJECTS_FILTER_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<MovieProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      movies: action.payload!,
    };
  },

  [MoviesProjectsAction.REQUEST_UPDATE_MOVIES_PROJECT_STATUS_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      updatedMoviesStatus: action.payload!,
    };
  },

  [MoviesProjectsAction.CLEAR_REQUEST_UPDATE_MOVIES_PROJECT_STATUS](state: IProjectState): IProjectState {
    return {
      ...state,
      updatedMoviesStatus: undefined,
    };
  },

  [MoviesProjectsAction.REQUEST_FIND_MOVIE_PROJECT_DETAIL_FINISHED](
    state: IProjectState,
    action: IAction<MovieProjectModel>,
  ): IProjectState {
    return {
      ...state,
      moviesDetail: action.payload!,
    };
  },
};

export default MoviesProjectsReducer;
