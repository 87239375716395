import React, { Fragment } from "react";
import Loader from "react-loader-spinner";

interface IProps {
  isActive?: boolean;
  className?: object | undefined;
  isPageLoader?: boolean;
  loadingType?: "TailSpin" | "Oval";
  loadingColor?: string;
  loadingHeight?: number;
  loadingWidth?: number;
}

const LoadingIndicator: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const {
    isActive = false,
    isPageLoader = false,
    loadingType = "TailSpin",
    loadingColor = "rgb(97, 93, 250)",
    loadingHeight = 60,
    loadingWidth = 60,
    children,
    className: propClassName,
  } = props;

  const className: object =
    propClassName === undefined ? { display: "flex", justifyContent: "center", alignItems: "center" } : propClassName;

  const pageLoaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  };

  return (
    <Fragment>
      {isActive && (
        <div style={isPageLoader ? pageLoaderStyle : className}>
          <Loader type={loadingType} color={loadingColor} height={loadingHeight} width={loadingWidth} />
        </div>
      )}
      {children}
    </Fragment>
  );
};

export default LoadingIndicator;
