import { BaseModel } from "sjs-base-model";
import CastAgencyLightModel from "models/api-models/CastAgencyLightModel";

export default class RoleOfferModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly cast_agency: CastAgencyLightModel = CastAgencyLightModel as any;
  public readonly message: string = "";
  public readonly status: string | null = ""; // TODO check out
  public readonly amount: string = "";
  public readonly created: string = "";
  public readonly modified: string = "";
  public readonly payment_type: string = "";
  public readonly get_display_budget: string = "";
  public readonly role_application: string = "";
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<RoleOfferModel>) {
    super();

    this.update(data);
  }
}

/*
const get_display_budget = (budget: string | undefined, budgetCurrency: string | undefined): string => {
  if(budget === undefined || budgetCurrency === undefined){
      return '';
  } else {
      return (Number(budget)).toLocaleString(
          localeLang(), {
              style: 'currency',
              currency: budgetCurrency,
          });
  }
}*/
/*
const localeLang = () => {
  let lang: string | null = getCookieLang();

  return lang === null ? 'tr-TR' : lang;
}*/
