import environment from "environment";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import * as HttpUtility from "utilities/HttpUtility";
import { AxiosResponse } from "axios";
import ProjectModel from "./models/ProjectModel";
import IPagination from "models/IPagination";

export const requestProjects = async (
  search: string,
  page?: number,
): Promise<IPagination<ProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.index.concat("?search=", search);
  if (page) {
    endpoint = endpoint.concat("&page=", page.toString());
  }

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  let result: IPagination<ProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    currentPage: page,
    results: response.data.results.map((json: Partial<ProjectModel>) => new ProjectModel(json)),
  };

  return result;
};

export const requestProjectsFilter = async (
  filters: string,
): Promise<IPagination<ProjectModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.projects.index + `${filters}`;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  let result: IPagination<ProjectModel[]> = {
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<ProjectModel>) => new ProjectModel(json)),
  };

  return result;
};
