import { BaseModel } from "sjs-base-model";
import RoleApplicationEvaluationCriterion from "./RoleApplicationEvaluationCriterion";

export default class RoleApplicationEvaluationModel extends BaseModel {
  public readonly comment: string = "";
  public readonly created: string = "";
  public readonly role_application: string = "";
  public readonly uuid: string = "";
  public readonly evaluations: RoleApplicationEvaluationCriterion[] = [];
  public readonly is_created_by_link: boolean = false;

  constructor(data: Partial<RoleApplicationEvaluationModel>) {
    super();

    this.update(data);
  }
}
