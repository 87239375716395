import IAction from "models/IAction";
import IProjectState from "../models/IProjectsState";

import * as RolesAction from "../roles/RolesAction";
import RoleModel from "../roles/models/RoleModel";

import ProjectModel from "../models/ProjectModel";
import RoleInvitationsReducer from "./invitations/RoleInvitationsReducer";

import RoleApplicationReducer from "./application/RoleApplicationReducer";

const RolesReducer = {
  ...RoleInvitationsReducer,

  ...RoleApplicationReducer,

  [RolesAction.REQUEST_PROJECT_ROLES_FINISHED](state: IProjectState, action: IAction<RoleModel[]>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roles: action.payload!,
      },
    };
  },

  [RolesAction.CLEAR_PROJECT_ROLES](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roles: [],
      },
    };
  },

  [RolesAction.REQUEST_SEARCH_PROJECT_ROLE_WITH_UUID_FINISHED](
    state: IProjectState,
    action: IAction<RoleModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleDetail: action.payload!,
      },
    };
  },

  [RolesAction.REQUEST_CREATE_ROLE_FINISHED](state: IProjectState, action: IAction<string>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        requestCreatedRole: action.payload!,
      },
    };
  },

  [RolesAction.REQUEST_UPDATE_PROJECT_ROLE_FINISHED](state: IProjectState, action: IAction<string>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        requestUpdatedRole: action.payload!,
      },
    };
  },

  [RolesAction.CLEAR_CREATED_ROLES](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        requestCreatedRole: undefined,
      },
    };
  },

  [RolesAction.CLEAR_UPDATED_ROLES](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        requestUpdatedRole: undefined,
      },
    };
  },

  //Role of the Project
  [RolesAction.REQUEST_ROLE_DETAIL_FINISHED](state: IProjectState, action: IAction<RoleModel>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleDetail: action.payload!,
      },
    };
  },

  //CLEAR_ROLE_DETAIL
  [RolesAction.CLEAR_ROLE_DETAIL](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleDetail: undefined,
      },
    };
  },

  [RolesAction.REQUEST_PROJECT_FINISHED](state: IProjectState, action: IAction<ProjectModel>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        project: action.payload!,
      },
    };
  },

  //CLEAR_PROJECT
  [RolesAction.CLEAR_PROJECT](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        project: undefined,
      },
    };
  },

  [RolesAction.REQUEST_SEARCH_PROJECT_ROLE_FINISHED](
    state: IProjectState,
    action: IAction<RoleModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roles: action.payload!,
      },
    };
  },

  [RolesAction.CLEAR_SEARCH_PROJECT_ROLES](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roles: [],
      },
    };
  },
};

export default RolesReducer;
