import HttpErrorResponseModel from "models/HttpErrorResponseModel";

export const selectErrors = (response: HttpErrorResponseModel): string => {
  if (response.status === 400) {
    let errorsArray: any = Object.values(response.raw.data);
    response.message = errorsArray[0][0];
    return errorsArray[0][0];
  }
  return response.message;
};
