import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import { ReduxDispatch } from "models/ReduxProps";
import * as ActionUtility from "utilities/ActionUtility";
import IStore from "../../../models/IStore";
import ProjectModel from "../models/ProjectModel";
import * as RolesEffect from "./RolesEffect";
import { IRoleFormModel } from "./models/IRoleFormModel";
import RoleModel from "./models/RoleModel";

type ActionUnion = undefined | HttpErrorResponseModel | RoleModel[] | RoleModel | ProjectModel | string;

export const REQUEST_PROJECT_ROLES: string = "RolesAction.REQUEST_PROJECT_ROLES";
export const REQUEST_PROJECT_ROLES_FINISHED: string = "RolesAction.REQUEST_PROJECT_ROLES_FINISHED";

export const requestProjectRoles = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PROJECT_ROLES,
      RolesEffect.requestProjectRoles,
      slug,
    );
  };
};

//clear the project roles
export const CLEAR_PROJECT_ROLES: string = "RolesAction.CLEAR_PROJECT_ROLES";

export const clearProjectRoles = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_PROJECT_ROLES);
};

export const REQUEST_CREATE_ROLE: string = "RolesAction.REQUEST_CREATE_ROLE";
export const REQUEST_CREATE_ROLE_FINISHED: string = "RolesAction.REQUEST_CREATE_ROLE_FINISHED";

export const requestCreateRole = (formData: IRoleFormModel): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ROLE,
      RolesEffect.requestCreateRole,
      formData,
    );
  };
};

export const REQUEST_SEARCH_PROJECT_ROLE_WITH_UUID: string = "RolesAction.REQUEST_SEARCH_PROJECT_ROLE_WITH_UUID";
export const REQUEST_SEARCH_PROJECT_ROLE_WITH_UUID_FINISHED: string =
  "RolesAction.REQUEST_SEARCH_PROJECT_ROLE_WITH_UUID_FINISHED";

export const requestProjectRoleWithUUID = (role_uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SEARCH_PROJECT_ROLE_WITH_UUID,
      RolesEffect.findByUUID,
      role_uuid,
    );
  };
};

export const REQUEST_SEARCH_PROJECT_ROLE: string = "RolesAction.REQUEST_SEARCH_PROJECT_ROLE";
export const REQUEST_SEARCH_PROJECT_ROLE_FINISHED: string = "RolesAction.REQUEST_SEARCH_PROJECT_ROLE_FINISHED";

export const requestSearchRole = (term?: string, roleStatus?: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const project: ProjectModel | undefined = getState().projects.projectRoles.project;
    await ActionUtility.createThunkEffect<RoleModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SEARCH_PROJECT_ROLE,
      RolesEffect.requestSearchProjectRole,
      term,
      project?.slug,
      project?.type,
      roleStatus,
    );
  };
};

export const CLEAR_SEARCH_PROJECT_ROLES: string = "RolesAction.CLEAR_SEARCH_PROJECT_ROLES";

export const clearSearchProjectRoles = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_SEARCH_PROJECT_ROLES);
};

export const REQUEST_UPDATE_PROJECT_ROLE: string = "RolesAction.REQUEST_UPDATE_PROJECT_ROLE";
export const REQUEST_UPDATE_PROJECT_ROLE_FINISHED: string = "RolesAction.REQUEST_UPDATE_PROJECT_ROLE_FINISHED";

export const requestUpdateProjectRole = (formData: IRoleFormModel, uuid: string | undefined): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_UPDATE_PROJECT_ROLE,
      RolesEffect.requestUpdateProjectRole,
      formData,
      uuid,
    );
  };
};

export const CLEAR_CREATED_ROLES: string = "RolesAction.CLEAR_CREATED_PROJECT";

export const clearCreatedMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_CREATED_ROLES);
};

export const CLEAR_UPDATED_ROLES: string = "RolesAction.CLEAR_UPDATED_PROJECT";

export const clearUpdatedMessage = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_UPDATED_ROLES);
};

export const REQUEST_ROLE_DETAIL: string = "RolesAction.REQUEST_ROLE_DETAIL";
export const REQUEST_ROLE_DETAIL_FINISHED: string = "RolesAction.REQUEST_ROLE_DETAIL_FINISHED";

export const requestRoleDetail = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_DETAIL,
      RolesEffect.findByUUID,
      uuid,
    );
  };
};

//clear the project roles
export const CLEAR_ROLE_DETAIL: string = "RolesAction.CLEAR_ROLE_DETAIL";

export const clearRoleDetail = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_ROLE_DETAIL);
};

export const REQUEST_PROJECT: string = "RolesAction.REQUEST_PROJECT";
export const REQUEST_PROJECT_FINISHED: string = "RolesAction.REQUEST_PROJECT_FINISHED";

export const requestProject = (slug: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ProjectModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PROJECT,
      RolesEffect.requestProject,
      slug,
    );
  };
};

//clear the project roles
export const CLEAR_PROJECT: string = "RolesAction.CLEAR_PROJECT";

export const clearProject = (): IAction<string> => {
  return ActionUtility.createAction(CLEAR_PROJECT);
};
