import { AxiosResponse } from "axios";
import environment from "environment";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import CastRoleOfferModel from "models/api-models/cast-role-offer/CastRoleOfferModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import ISimpleProjectModel from "selectors/projects/models/ISimpleProjectModel";
import * as EffectUtility from "utilities/EffectUtility";
import * as HttpUtility from "utilities/HttpUtility";

export const requestCastRoleInvitations = async (
  cast_slug: string,
  role_uuid?: string,
): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.companies.casts.roleinvitations.replace(":slug", cast_slug);

  if (role_uuid) {
    endpoint += "?role=" + role_uuid;
  }

  return EffectUtility.getToModel<RoleInvitationModel[]>(RoleInvitationModel, endpoint);
};

export const requestCastRoleApplications = async (
  cast_slug: string,
  role_uuid?: string,
  status?: string,
): Promise<RoleApplicationModel[] | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.companies.casts.roleapplications.replace(":slug", cast_slug);

  if (role_uuid) {
    endpoint += "?role=" + role_uuid;
  }

  if (status) {
    endpoint += `${role_uuid ? "&" : "?"}status=${status}`;
  }

  return EffectUtility.getToModel<RoleApplicationModel[]>(RoleApplicationModel, endpoint);
};

export const requestCastRoleOffers = async (
  cast_slug: string,
  role_uuid?: string,
): Promise<CastRoleOfferModel[] | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.companies.casts.roleoffers.replace(":slug", cast_slug);

  if (role_uuid) {
    endpoint += "?role=" + role_uuid;
  }

  return EffectUtility.getToModel<CastRoleOfferModel[]>(CastRoleOfferModel, endpoint);
};

export const requestCastProjects = async (
  cast_slug: string,
): Promise<ISimpleProjectModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.companies.casts.projects.replace(":slug", cast_slug);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const projects = response.data.map((item: Partial<ISimpleProjectModel>) => {
    return {
      logo: item.logo,
      name: item.name,
      slug: item.slug,
    };
  });

  return projects;
};
