import { ConnectedRouter } from "connected-react-router";
import CookieEnum from "constants/CookieEnum";
import { History } from "history";
import i18n from "i18n";
import IStore from "models/IStore";
import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "selectors/profile/language/LanguageSelector";
import { getCookieLang, storeDataToCookie } from "utilities/CookieUtility";
import "../config/dayjs.config";
import CookieControl from "./components/cookie-control/CookieControl";
import LoadingIndicator from "./components/loading-indicator/LoadingIndicator";
import Routers from "./components/router-components/Routers";
import SwitchProfileModal from "./components/switch-profile-modal/SwitchProfileModal";
import Toasts from "./components/toasts/Toasts";

interface IProps {
  readonly history: History;
}

const App: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  const { history } = props;
  const userLanguage: string = useSelector((state: IStore) => selectLanguage(state));

  useEffect(() => {
    const cookieLanguage = getCookieLang();
    if (userLanguage) {
      i18n.changeLanguage(userLanguage);
    } else if (cookieLanguage) {
      i18n.changeLanguage(cookieLanguage);
    } else {
      i18n.changeLanguage(i18n.language.slice(0, 2));
      storeDataToCookie(CookieEnum.Lang, i18n.language.slice(0, 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage]);

  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<LoadingIndicator isActive={true} isPageLoader={true} />}>
        <Routers />
        <Toasts />
        <CookieControl />
        <SwitchProfileModal />
      </Suspense>
    </ConnectedRouter>
  );
};

export default App;
