import { BaseModel } from "sjs-base-model";
import { Translation } from "interfaces/Translation";

export default class ResourceDistributionByProjectModel extends BaseModel {
  public readonly project_name: Translation = { en: "", tr: "" };
  public readonly project_count: number = 0;

  constructor(data: Partial<ResourceDistributionByProjectModel>) {
    super();

    this.update(data);
  }
}
