import { AxiosResponse } from "axios";

import environment from "environment";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IPagination from "models/IPagination";
import * as EffectUtility from "utilities/EffectUtility";
import * as HttpUtility from "utilities/HttpUtility";
import { selectErrors } from "utilities/ResponseErrorUtility";
import ActorModel from "./models/actors/ActorModel";
import ActorProfileModel from "./models/actors/ActorProfileModel";

import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import IResponseExperienceModel from "models/form/actor/IExperienceModel";
import IResponseActorEducationModel from "models/form/actor/IResponseActorEducationModel";
import IResponseAvailabilityModel from "models/form/actor/IResponseAvailabilityModel";
import CastAgencyModel from "stores/companies/cast-agencies/models/CastAgencyModel";
import ActorReferenceModel from "./models/actors/models/ActorReferenceModel";
import ActorChildModel from "./models/actors/models/actor-child/ActorChildModel";

export const requestFilterActors = async (
  params: string,
  search?: string,
  page?: number,
): Promise<IPagination<ActorModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.actors.index.concat(params);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const result: IPagination<ActorModel[]> = {
    currentPage: page || 1,
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<ActorModel>) => new ActorModel(json)),
    searchTerm: search,
    searchParams: params,
  };

  return result;
};

export const requestSearchActors = async (
  search: string,
  page?: number,
): Promise<IPagination<ActorModel[]> | HttpErrorResponseModel> => {
  let endpoint: string = environment.api.actors.index.concat("?search=", search);

  if (page) {
    endpoint = endpoint.concat("&page=", page.toString());
  }

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const result: IPagination<ActorModel[]> = {
    currentPage: page || 1,
    count: response.data.count,
    next: response.data.next,
    previous: response.data.previous,
    results: response.data.results.map((json: Partial<ActorModel>) => new ActorModel(json)),
    searchTerm: search,
  };

  return result;
};

export const requestCreateActorProfile = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.uuid;
};

export const requestUpdateActor = async (actor: any, uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.index.concat(uuid, "/");
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, actor);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "success";
};

export const getActor = async (): Promise<ActorModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.me;
  return EffectUtility.getToModel<ActorModel>(ActorModel, endpoint);
};

export const find = async (uuid: string): Promise<ActorModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.index.concat(uuid + "/");
  return EffectUtility.getToModel<ActorModel>(ActorModel, endpoint);
};

export const requestActorProfile = async (uuid: string): Promise<ActorProfileModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.profile.replace(":uuid", uuid);
  return EffectUtility.getToModel<ActorProfileModel>(ActorProfileModel, endpoint);
};

export const requestActorCasts = async (uuid: string): Promise<CastAgencyModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.agencies.replace(":uuid", uuid);
  return EffectUtility.getToModel<CastAgencyModel[]>(CastAgencyModel, endpoint);
};

export const requestActorInvitations = async (
  uuid: string,
): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.roleinvitations.replace(":uuid", uuid);

  return EffectUtility.getToModel<RoleInvitationModel[]>(RoleInvitationModel, endpoint);
};

export const requestAcceptRoleInvitation = async (
  uuid: string,
  roleinvitations: RoleInvitationModel[],
): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.accept.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  const updatedInvitation: RoleInvitationModel = new RoleInvitationModel(response.data);
  const objIndex = roleinvitations.findIndex((obj) => obj.uuid === uuid);

  const newInvitations = roleinvitations.filter((object) => {
    return object.uuid !== uuid;
  });

  newInvitations.splice(objIndex, 0, updatedInvitation);
  return newInvitations;
};

export const requestDeclineRoleInvitation = async (
  uuid: string,
  roleinvitations: RoleInvitationModel[],
): Promise<RoleInvitationModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.projects.roleinvitations.decline.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    response.message = selectErrors(response);
    return response;
  }

  const newInvitations = roleinvitations.filter((object) => {
    return object.uuid !== uuid;
  });

  newInvitations.push(new RoleInvitationModel(response.data));

  return newInvitations;
};

export const requestActorLikedByMe = async (
  uuid: string,
): Promise<CastAgencyModel | undefined | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.liked_by.replace(":uuid", uuid).concat("?me");
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (response.data && response.data.length > 0) {
    return new CastAgencyModel(response.data[0]);
  } else {
    return undefined;
  }
};

export const requestCastLikeToActor = async (uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.like.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Liked";
};

export const requestCastUnLikeToActor = async (uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.unlike.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "UnLiked";
};

export const requestDeleteActorVideo = async (video_uuid: string): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.videos.concat(video_uuid, "/");
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.del(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Video Deleted";
};

export const requestUploadActorVideo = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.videos;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Video Upload";
};

export const requestActorEvaluation = async (actorEvaluationData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.evaluations.index;

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, actorEvaluationData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Success";
};

export const requestUpdateActorEvaluation = async (
  formData: any,
  uuid: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.evaluations.evaluation.replace(":uuid", uuid);

  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Success";
};

export const requestDeleteActorPhotos = async (photo_uuids: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.photos + "delete_photos/";
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, photo_uuids);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "success";
};

export const updatePhysicalAppearance = async (
  formData: any,
  uuid: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.index.concat(uuid, "/");
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "Actor Profile Updated.";
};

export const requestUploadActorPhoto = async (formData: any): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.photos;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "success";
};

export const requestUpdateActorAvailability = async (
  formData: any,
  uuid: string,
): Promise<IResponseAvailabilityModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.actor.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const availability: IResponseAvailabilityModel = {
    days_available: response.data.days_available,
    has_agency_contract: response.data.has_agency_contract,
    has_day_job: response.data.has_day_job,
  };

  return availability;
};

export const requestUpdateActorExperience = async (
  key: string,
  value: string,
  uuid: string,
): Promise<IResponseExperienceModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.actor.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, { [key]: value });
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  const experience: IResponseExperienceModel = {};
  experience[key] = value;

  return experience;
};

export const requestUpdateActorAbout = async (
  value: string,
  uuid: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.actor.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, { description: value });
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.description;
};

// Children
export const requestCreateActorChildren = async (formData: any): Promise<ActorChildModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.children.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new ActorChildModel(response.data);
};

export const requestUpdateActorChildren = async (
  formData: any,
  uuid: string,
  children: ActorChildModel[] | undefined,
): Promise<ActorChildModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.children.child.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (children) {
    const updatedChild: ActorChildModel = new ActorChildModel(response.data);
    const objIndex = children.findIndex((obj) => obj.uuid === uuid);
    let newChildren = children.filter((object) => {
      return object.uuid !== uuid;
    });
    newChildren.splice(objIndex, 0, updatedChild);
    return newChildren;
  }
  return [];
};

export const requestDeleteActorChildren = async (
  uuid: string,
  children: ActorChildModel[] | undefined,
): Promise<ActorChildModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.children.child.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.del(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (children) {
    let newChildren = children.filter((object) => {
      return object.uuid !== uuid;
    });
    return newChildren;
  }
  return [];
};

export const requestUpdateActorHobbies = async (
  value: string,
  uuid: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.actor.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, { hobbies: value });
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return response.data.hobbies;
};

export const requestUpdateActorEducation = async (
  formData: string,
  uuid: string,
): Promise<IResponseActorEducationModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.actor.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  let education: IResponseActorEducationModel = {
    acting_level: response.data.acting_level,
    professional_trainings: response.data.professional_trainings,
  };

  return education;
};

export const requestUpdateActorSkills = async (
  formData: string,
  uuid: string,
): Promise<string | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.actor.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.patch(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return "success";
};

export const requestCreateActorReference = async (
  formData: any,
): Promise<ActorReferenceModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.references.index;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.post(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  return new ActorReferenceModel(response.data);
};

export const requestUpdateActorReference = async (
  formData: any,
  uuid: string,
  references: ActorReferenceModel[] | undefined,
): Promise<ActorReferenceModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.references.reference.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.put(endpoint, formData);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (references) {
    const updatedReference: ActorReferenceModel = new ActorReferenceModel(response.data);
    const objIndex = references.findIndex((obj) => obj.uuid === uuid);
    let newReferenceList = references.filter((object) => {
      return object.uuid !== uuid;
    });
    newReferenceList.splice(objIndex, 0, updatedReference);
    return newReferenceList;
  }
  return [];
};

export const requestDeleteActorReference = async (
  uuid: string,
  references: ActorReferenceModel[] | undefined,
): Promise<ActorReferenceModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.actors.references.reference.replace(":uuid", uuid);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.del(endpoint);
  if (response instanceof HttpErrorResponseModel) {
    return response;
  }
  if (references) {
    let newReferenceList = references.filter((object) => {
      return object.uuid !== uuid;
    });
    return newReferenceList;
  }
  return [];
};
