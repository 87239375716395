import * as ActorRoleInvitationsAction from "./ActorRoleInvitationsAction";
import IAction from "models/IAction";
import IProjectState from "../models/IProjectsState";
import RoleInvitationModel from "models/api-models/role-invitation/RoleInvitationModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";

const ActorRoleInvitationsReducer = {
  [ActorRoleInvitationsAction.REQUEST_ACCEPT_ROLE_INVITATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel>,
  ): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        invitationDetail: action.payload!,
        requestAcceptRoleInvitation: "success",
      },
    };
  },

  [ActorRoleInvitationsAction.CLEAR_REQUEST_ACCEPT_ROLE_INVITATION_MESSAGE](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        requestAcceptRoleInvitation: undefined,
      },
    };
  },

  [ActorRoleInvitationsAction.CLEAR_REQUEST_ROLE_INVITATION_DETAIL](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        invitationDetail: undefined,
      },
    };
  },

  [ActorRoleInvitationsAction.REQUEST_DECLINE_ROLE_INVITATION_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        requestDeclineRoleInvitation: action.payload!,
      },
    };
  },

  [ActorRoleInvitationsAction.CLEAR_REQUEST_DECLINE_ROLE_INVITATION](state: IProjectState): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        requestDeclineRoleInvitation: undefined,
      },
    };
  },

  [ActorRoleInvitationsAction.REQUEST_ROLE_INVITATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel[]>,
  ): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        roleInvitations: action.payload!,
      },
    };
  },

  [ActorRoleInvitationsAction.REQUEST_ACCEPT_ROLE_INVITATION_CARD_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        requestAcceptRoleInvitation: action.payload,
      },
    };
  },

  [ActorRoleInvitationsAction.REQUEST_DECLINE_ROLE_INVITATION_CARD_FINISHED](
    state: IProjectState,
    action: IAction<RoleInvitationModel[]>,
  ): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        roleInvitations: action.payload!,
        requestDeclineRoleInvitation: "success",
      },
    };
  },

  [ActorRoleInvitationsAction.REQUEST_ROLE_APPLICATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      actorRoleInvitationState: {
        ...state.actorRoleInvitationState,
        roleApplications: action.payload!,
      },
    };
  },
};

export default ActorRoleInvitationsReducer;
