import IAction from "models/IAction";
import IPagination from "models/IPagination";
import ProductionModel from "./models/ProductionModel";
import ICompaniesState from "../models/ICompainesState";
import * as ProductionsAction from "./ProductionsAction";
import ProductionOptionsModel from "./models/ProductionOptionsModel";

const ProductionsReducer = {
  [ProductionsAction.REQUEST_PRODUCTION_OPTIONS_FINISHED](
    state: ICompaniesState,
    action: IAction<ProductionOptionsModel>,
  ): ICompaniesState {
    return {
      ...state,
      productionOptions: action.payload!,
    };
  },

  [ProductionsAction.REQUEST_PRODUCTION_COMPANIES_FINISHED](
    state: ICompaniesState,
    action: IAction<IPagination<ProductionModel[]>>,
  ): ICompaniesState {
    return {
      ...state,
      productions: action.payload!,
    };
  },

  [ProductionsAction.REQUEST_PRODUCTION_COMPANY_FINISHED](
    state: ICompaniesState,
    action: IAction<ProductionModel>,
  ): ICompaniesState {
    return {
      ...state,
      productionDetail: action.payload!,
    };
  },

  [ProductionsAction.CLEAR_PRODUCTION_COMPANY_DETAIL](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      productionDetail: undefined,
    };
  },

  [ProductionsAction.REQUEST_UPDATE_PRODUCTION_COMPANY_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestUpdateProduction: action.payload!,
    };
  },

  [ProductionsAction.CLEAR_REQUEST_UPDATE_PRODUCTION_COMPANY](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      requestUpdateProduction: undefined,
    };
  },

  [ProductionsAction.REQUEST_CREATE_PRODUCTION_COMPANY_FINISHED](
    state: ICompaniesState,
    action: IAction<string>,
  ): ICompaniesState {
    return {
      ...state,
      requestCreateProduction: action.payload!,
    };
  },

  [ProductionsAction.CLEAR_REQUEST_CREATE_PRODUCTION_COMPANY](state: ICompaniesState): ICompaniesState {
    return {
      ...state,
      requestCreateProduction: undefined,
    };
  },
};

export default ProductionsReducer;
