import { BaseModel } from "sjs-base-model";
import IProjectProductionCompanyModel from "./IProjectProductionCompanyModel";
import IProjectPlatformModel from "./IProjectPlatformModel";

export default class ProjectBaseModel extends BaseModel {
  public readonly id: number = 0;
  public readonly created: string = "";
  public readonly modified: string = "";
  public readonly slug: string = "";
  public readonly status: string = "";
  public readonly name: string = "";
  public readonly producer_name: string = "";
  public readonly director_name: string = "";
  public readonly brief: string = "";
  public readonly is_private: boolean = false;
  public readonly payment_terms: string = "";
  public readonly is_payment_terms_private: boolean = false;
  public readonly expiry_date: string = "";
  public readonly start_date: string = "";
  public readonly end_date: string = "";
  public readonly production: IProjectProductionCompanyModel = IProjectProductionCompanyModel as any;
  public readonly platforms: IProjectPlatformModel[] = [IProjectPlatformModel as any];
  public readonly logo: string = "";
  public readonly audition_text: string = "";

  constructor(data: Partial<ProjectBaseModel>) {
    super();

    this.update(data);
  }
}
