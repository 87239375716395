import IAction from "models/IAction";
import IPagination from "models/IPagination";
import IProjectState from "../models/IProjectsState";

import CommercialProjectModel from "./models/CommercialProjectModel";
import * as CommercialsProjectsAction from "./CommercialsProjectsAction";

const CommercialsProjectsReducer = {
  [CommercialsProjectsAction.REQUEST_COMMERCIAL_PROJECTS_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<CommercialProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      commercials: action.payload!,
    };
  },

  [CommercialsProjectsAction.REQUEST_COMMERCIAL_PROJECT_DETAIL_FINISHED](
    state: IProjectState,
    action: IAction<CommercialProjectModel>,
  ): IProjectState {
    return {
      ...state,
      commercialDetail: action.payload!,
    };
  },

  [CommercialsProjectsAction.REQUEST_FIND_COMMERCIAL_PROJECT_DETAIL_FINISHED](
    state: IProjectState,
    action: IAction<CommercialProjectModel>,
  ): IProjectState {
    return {
      ...state,
      commercialDetail: action.payload!,
    };
  },

  [CommercialsProjectsAction.REQUEST_CREATE_COMMERCIAL_PROJECT_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      createdProject: action.payload!,
    };
  },

  [CommercialsProjectsAction.REQUEST_UPDATE_COMMERCIAL_PROJECT_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      updatedProject: action.payload!,
    };
  },

  [CommercialsProjectsAction.FILTER_COMMERCIALS_PROJECTS_SHOW](
    state: IProjectState,
    action: IAction<boolean>,
  ): IProjectState {
    return {
      ...state,
      commercialsFilter: {
        ...state.commercialsFilter,
        filtersShow: action.payload!,
      },
    };
  },

  [CommercialsProjectsAction.REQUEST_COMMERCIALS_PROJECTS_FILTER_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<CommercialProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      commercials: action.payload!,
    };
  },

  [CommercialsProjectsAction.REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      updatedCommercialStatus: action.payload!,
    };
  },

  [CommercialsProjectsAction.CLEAR_REQUEST_UPDATE_COMMERCIAL_PROJECT_STATUS](state: IProjectState): IProjectState {
    return {
      ...state,
      updatedCommercialStatus: undefined,
    };
  },
};

export default CommercialsProjectsReducer;
