import { BaseModel } from "sjs-base-model";

export default class InvitationAndApplicationStats extends BaseModel {
  public readonly project_name: string = "";
  public readonly roleapplication_count: number = 0;
  public readonly roleinvitation_count: number = 0;
  public readonly declined_invitation_count: number = 0;

  constructor(data: Partial<InvitationAndApplicationStats>) {
    super();

    this.update(data);
  }
}
