import { Reducer } from "redux";
import baseReducer from "utilities/BaseReducer";

import * as ProjectsAction from "./ProjectsAction";
import * as GenresAction from "./genres/GenresAction";
import * as PhotoChannelsAction from "./photochannels/PhotoChannelsAction";
import * as PlatformsAction from "./platforms/PlatformsAction";

import IAction from "models/IAction";
import IPagination from "models/IPagination";
import GenreModel from "./genres/models/GenreModel";
import IProjectState from "./models/IProjectsState";
import ProjectModel from "./models/ProjectModel";
import PhotoChannelModel from "./photochannels/models/PhotoChannelModel";
import PlatformModel from "./platforms/models/PlatformModel";

import { initialState as initialActorRoleApplication } from "./models/actor-role-application/IActorRoleApplication";
import { initialState as initialActorRoleInvitation } from "./models/actor-role-invitation/IActorRoleInvitation";
import { initialState as initialCastProjects } from "./models/cast-projects/ICastProjects";
import { initialState as initialProjectRoles } from "./models/project-role/IProjectRoles";
import { initialState as initialRolePayments } from "./models/role-payments/RolePayments";

import ActorRoleApplicationsReducer from "./actor-role-applications/ActorRoleApplicationsReducer";
import ActorRoleInvitationsReducer from "./actor-role-invitations/ActorRoleInvitationsReducer";
import CastProjectsReducer from "./cast-projects/CastProjectsReducer";
import CommercialsProjectsReducer from "./commercials/CommercialsProjectsReducer";
import MoviesProjectsReducer from "./movies/MoviesProjectsReducer";
import RolePaymentsReducer from "./payments/RolePaymentsReducer";
import { default as RolesReducer } from "./roles/RolesReducer"; // todo fix import RolesReducer from "./roles/RolesReducer";
import SeriesProjectsReducer from "./series/SeriesProjectsReducer";

export const initialState: IProjectState = {
  projects: null,
  projectsFilter: { filtersShow: false, filters: undefined },

  commercials: null,
  commercialDetail: undefined,
  commercialsFilter: { filtersShow: false, filters: undefined },
  updatedCommercialStatus: undefined,

  movies: null,
  moviesDetail: undefined,
  moviesFilter: { filtersShow: false, filters: undefined },
  updatedMoviesStatus: undefined,

  series: null,
  seriesDetail: undefined,
  seriesFilter: { filtersShow: false, filters: undefined },
  updatedSeriesStatus: undefined,

  platforms: [],
  genres: [],
  photoChannels: [],

  createdProject: undefined,
  updatedProject: undefined,

  redirectFromPage: undefined,

  actorRoleInvitationState: initialActorRoleInvitation,
  actorRoleApplicationState: initialActorRoleApplication,

  projectRoles: initialProjectRoles,

  castProjectsState: initialCastProjects,
  rolePaymentsState: initialRolePayments,
};

const projectsReducer: Reducer = baseReducer(initialState, {
  ...RolesReducer,

  ...ActorRoleInvitationsReducer,

  ...ActorRoleApplicationsReducer,

  ...CommercialsProjectsReducer,

  ...MoviesProjectsReducer,

  ...SeriesProjectsReducer,

  ...CastProjectsReducer,

  ...RolePaymentsReducer,

  [ProjectsAction.REQUEST_PROJECTS_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<ProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      projects: action.payload!,
    };
  },

  [ProjectsAction.CLEAR_REQUEST_PROJECTS](state: IProjectState): IProjectState {
    return {
      ...state,
      projects: null,
    };
  },

  [ProjectsAction.FILTER_PROJECTS_SHOW](state: IProjectState, action: IAction<boolean>): IProjectState {
    return {
      ...state,
      projectsFilter: {
        ...state.projectsFilter,
        filtersShow: action.payload!,
      },
    };
  },

  [ProjectsAction.REQUEST_PROJECTS_FILTER_FINISHED](
    state: IProjectState,
    action: IAction<IPagination<ProjectModel[]>>,
  ): IProjectState {
    return {
      ...state,
      projects: action.payload!,
    };
  },

  [ProjectsAction.CLEAR_CREATED_PROJECT](state: IProjectState): IProjectState {
    return {
      ...state,
      createdProject: undefined,
    };
  },

  [ProjectsAction.CLEAR_UPDATED_PROJECT](state: IProjectState): IProjectState {
    return {
      ...state,
      updatedProject: undefined,
    };
  },

  [ProjectsAction.ADD_REDIRECT_FROM_PAGE](state: IProjectState, action: IAction<string>): IProjectState {
    return {
      ...state,
      redirectFromPage: action.payload!,
    };
  },

  [ProjectsAction.REMOVE_REDIRECT_FROM_PAGE](state: IProjectState): IProjectState {
    return {
      ...state,
      redirectFromPage: undefined,
    };
  },

  [PlatformsAction.REQUEST_PLATFORMS_FINISHED](state: IProjectState, action: IAction<PlatformModel[]>): IProjectState {
    return {
      ...state,
      platforms: action.payload!,
    };
  },

  [GenresAction.REQUEST_GENRES_FINISHED](state: IProjectState, action: IAction<GenreModel[]>): IProjectState {
    return {
      ...state,
      genres: action.payload!,
    };
  },

  [PhotoChannelsAction.REQUEST_PHOTO_CHANNELS_FINISHED](
    state: IProjectState,
    action: IAction<PhotoChannelModel[]>,
  ): IProjectState {
    return {
      ...state,
      photoChannels: action.payload!,
    };
  },
});

export default projectsReducer;
