import * as PasswordEffect from "./PasswordEffect";
import * as ActionUtility from "../../../utilities/ActionUtility";
import { IRegisterValidationError } from "../models/register/IRegister";
import HttpErrorResponseModel from "../../../models/HttpErrorResponseModel";
import { ReduxDispatch } from "../../../models/ReduxProps";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | IRegisterValidationError
  | Boolean
  | { status: boolean; message: string };

export const REQUEST_PASSWORD_CHANGE: string = "PasswordAction.REQUEST_PASSWORD_CHNAGE";
export const REQUEST_PASSWORD_CHANGE_FINISHED: string = "PasswordAction.REQUEST_PASSWORD_CHNAGE_FINISHED";

export const requestChangePassword = (new_password1: string, new_password2: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<{ status: boolean; message: string } | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PASSWORD_CHANGE,
      PasswordEffect.requestChangePassword,
      new_password1,
      new_password2,
    );
  };
};

export const CLEAR_CHANGE_PASSWORD_SUCCESS: string = "PasswordAction.CLEAR_CHANGE_PASSWORD_SUCCESS";

export const clearChangePasswordSuccess = (): any => {
  return ActionUtility.createAction(CLEAR_CHANGE_PASSWORD_SUCCESS);
};

export const REQUEST_PASSWORD_RESET: string = "RegisterAction.REQUEST_PASSWORD_RESET";
export const REQUEST_PASSWORD_RESET_FINISHED: string = "RegisterAction.REQUEST_PASSWORD_RESET_FINISHED";

export const requestResetPassword = (email: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<{ status: boolean; message: string } | HttpErrorResponseModel>(
      dispatch,
      REQUEST_PASSWORD_RESET,
      PasswordEffect.requestResetPassword,
      email,
    );
  };
};

export const PASSWORD_RESET_CONFIRM: string = "RegisterAction.REGISTER_VERIFY_EMAIL";
export const PASSWORD_RESET_CONFIRM_FINISHED: string = "RegisterAction.REGISTER_VERIFY_EMAIL_FINISHED";

export const confirmResetPassword = (uid: string, token: string, new_password1: string, new_password2: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<Boolean | HttpErrorResponseModel>(
      dispatch,
      PASSWORD_RESET_CONFIRM,
      PasswordEffect.confirmResetPassword,
      uid,
      token,
      new_password1,
      new_password2,
    );
  };
};

export const RESET_VALIDATION: string = "RegisterAction.RESET_VALIDATION";

export const resetPasswordValidation = (): any => {
  return ActionUtility.createAction(RESET_VALIDATION, false);
};
