import { AxiosResponse } from "axios";
import RoleEnum from "constants/RoleEnum";
import environment from "environment";
import { ReportProfileType } from "interfaces";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import GeneralBudgetAndCashCastModel from "models/api-models/reports/GeneralBudgetAndCashCastModel";
import GeneralBudgetAndCashModel from "models/api-models/reports/GeneralBudgetAndCashModel";
import GeneralProjectsModel from "models/api-models/reports/GeneralProjectsModel";
import InvitationAndApplicationStats from "models/api-models/reports/InvitationAndApplicationStats";
import ResourceDistributionByProjectCastModel from "models/api-models/reports/ResourceDistributionByProjectCastModel";
import ResourceDistributionByProjectModel from "models/api-models/reports/ResourceDistributionByProjectModel";
import ResourceDistributionByProjectTypeModel from "models/api-models/reports/ResourceDistributionByProjectTypeModel";
import RoleAndApplicationDistributionModel from "models/api-models/reports/RoleAndApplicationDistributionModel";
import * as HttpUtility from "utilities/HttpUtility";

export const requestReportsGeneralBudgetAndCash = async (): Promise<
  GeneralBudgetAndCashModel | HttpErrorResponseModel
> => {
  const endpoint: string = environment.api.reports.general_budget_and_cash + "?role=" + RoleEnum.ProductionCompany;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new GeneralBudgetAndCashModel(response.data);
};

export const requestReportsGeneralBudgetAndCashCast = async (): Promise<
  GeneralBudgetAndCashCastModel | HttpErrorResponseModel
> => {
  const endpoint: string = environment.api.reports.general_budget_and_cash + "?role=" + RoleEnum.CastAgency;
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new GeneralBudgetAndCashCastModel(response.data);
};

export const requestReportsGeneralProjects = async (
  role: ReportProfileType,
): Promise<GeneralProjectsModel | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.reports.general_projects.concat("?role=", role);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return new GeneralProjectsModel(response.data);
};

export const requestReportsInvitationAndApplicationStats = async (
  role: ReportProfileType,
): Promise<InvitationAndApplicationStats[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.reports.invitation_and_application_stats.concat("?role=", role);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map((json: Partial<InvitationAndApplicationStats>) => new InvitationAndApplicationStats(json));
};

export const requestReportsRoleAndApplicationDistribution = async (
  role: ReportProfileType,
): Promise<RoleAndApplicationDistributionModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.reports.role_and_application_distribution.concat("?role=", role);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map(
    (json: Partial<RoleAndApplicationDistributionModel>) => new RoleAndApplicationDistributionModel(json),
  );
};

export const requestReportsResourceDistributionByProject = async (): Promise<
  ResourceDistributionByProjectModel[] | HttpErrorResponseModel
> => {
  const endpoint: string = environment.api.reports.resource_distribution_by_project.concat(
    "?role=" + RoleEnum.ProductionCompany,
  );
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map(
    (json: Partial<ResourceDistributionByProjectModel>) => new ResourceDistributionByProjectModel(json),
  );
};

export const requestReportsResourceDistributionByProjectCast = async (): Promise<
  ResourceDistributionByProjectCastModel[] | HttpErrorResponseModel
> => {
  const endpoint: string = environment.api.reports.resource_distribution_by_project.concat(
    "?role=" + RoleEnum.CastAgency,
  );
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map(
    (json: Partial<ResourceDistributionByProjectCastModel>) => new ResourceDistributionByProjectCastModel(json),
  );
};

export const requestReportsResourceDistributionByProjectType = async (
  role: ReportProfileType,
): Promise<ResourceDistributionByProjectTypeModel[] | HttpErrorResponseModel> => {
  const endpoint: string = environment.api.reports.resource_distribution_by_project_type.concat("?role=", role);
  const response: AxiosResponse | HttpErrorResponseModel = await HttpUtility.get(endpoint);

  if (response instanceof HttpErrorResponseModel) {
    return response;
  }

  return response.data.map(
    (json: Partial<ResourceDistributionByProjectTypeModel>) => new ResourceDistributionByProjectTypeModel(json),
  );
};
