import CastAgencyLightModel from "models/api-models/CastAgencyLightModel";
import LightActorModel from "models/api-models/LightActorModel";
import LightProjectModel from "models/api-models/LightProjectModel";
import LightRoleModel from "models/api-models/LightRoleModel";
import { BaseModel } from "sjs-base-model";

export default class RoleInvitationModel extends BaseModel {
  public readonly id: number = 0;
  public readonly uuid: string = "";
  public readonly cast_agency: CastAgencyLightModel = CastAgencyLightModel as any;
  public readonly status: string = "";
  public readonly project: LightProjectModel = LightProjectModel as any;
  public readonly role: LightRoleModel = LightRoleModel as any;
  public readonly actor: LightActorModel = LightActorModel as any;
  public readonly message: string = "";
  public readonly created: string = "";
  public readonly modified: string = "";
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<RoleInvitationModel>) {
    super();

    this.update(data);
  }
}
