import RouteEnum from "constants/RouteEnum";
import IStore from "models/IStore";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const HeaderControl: React.FC = () => {
  const { t } = useTranslation();
  const user = useSelector((state: IStore) => state.auth.user);
  const history = useHistory();

  const redirectTo = () => {
    if (user) {
      history.push(RouteEnum.ProfileCreate);
    } else {
      history.push(RouteEnum.Login);
    }
  };

  return (
    <div className="nav-item header-control">
      <div className="header-action">
        <ul>
          <li className="login-btn cs-login-btn">
            <Link to={"#"} className="item-btn" onClick={redirectTo}>
              <i className="fas fa-user"></i>
              {t("header.header-control.login")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderControl;
