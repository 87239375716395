import { BaseModel } from "sjs-base-model";

export default class SimpleAuditionVideoModel extends BaseModel {
  public readonly uuid: string = "";
  public readonly is_private: boolean = false;
  public readonly url: string | null = "";
  public readonly video: string | null = null;
  public readonly title: string | null = null;
  /*
   * Client-Side properties (Not from API)
   */
  // public noneApiProperties: unknown = null;

  constructor(data: Partial<SimpleAuditionVideoModel>) {
    super();

    this.update(data);
  }
}
