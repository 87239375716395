import ProjectBaseModel from "./ProjectBaseModel";

export default class ProjectModel extends ProjectBaseModel {
  public readonly type: string = "";
  public readonly created_by: number = 0;

  constructor(project_model: Partial<ProjectModel>) {
    super(project_model);

    this.update(project_model);
  }
}
