import { CreateEvaluationFromLinkRequest } from "interfaces/CreateEvaluationFromLinkRequest";
import { MeetingEvaluationLink } from "interfaces/MeetingEvaluationLink";
import HttpErrorResponseModel from "models/HttpErrorResponseModel";
import IAction from "models/IAction";
import IStore from "models/IStore";
import { ReduxDispatch } from "models/ReduxProps";
import EvaluationCriterionModel from "models/api-models/EvaluationCriterionModel";
import RoleApplicationEvaluationModel from "models/api-models/RoleApplicationEvaluationModel";
import SimpleAuditionVideoModel from "models/api-models/audition-video/SimpleAuditionModel";
import ActorModel from "stores/actors/models/actors/ActorModel";
import * as ActionUtility from "utilities/ActionUtility";
import * as PagesEffect from "./PagesEffect";

type ActionUnion =
  | undefined
  | HttpErrorResponseModel
  | string
  | MeetingEvaluationLink
  | ActorModel[]
  | EvaluationCriterionModel[]
  | RoleApplicationEvaluationModel
  | SimpleAuditionVideoModel[];

export const REQUEST_SEND_CONTACT_MESSAGE: string = "PagesAction.REQUEST_SEND_CONTACT_MESSAGE";
export const REQUEST_SEND_CONTACT_MESSAGE_FINISHED: string = "PagesAction.REQUEST_SEND_CONTACT_MESSAGE_FINISHED";

export const sendContactMessage = (
  first_name: string,
  last_name: string,
  email: string,
  message: string,
  captcha: string,
  phone?: string,
): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<string | HttpErrorResponseModel>(
      dispatch,
      REQUEST_SEND_CONTACT_MESSAGE,
      PagesEffect.requestSendContactMessage,
      first_name,
      last_name,
      email,
      message,
      captcha,
      phone,
    );
  };
};

export const CLEAR_REQUEST_SEND_CONTACT_MESSAGE: string = "PagesAction.CLEAR_REQUEST_SEND_CONTACT_MESSAGE";

export const clearRequestSendContactMessage = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_SEND_CONTACT_MESSAGE);
};

export const REQUEST_MEETING_EVALUATION_LINK: string = "PagesAction.REQUEST_MEETING_EVALUATION_LINK";
export const REQUEST_MEETING_EVALUATION_LINK_FINISHED: string = "PagesAction.REQUEST_MEETING_EVALUATION_LINK_FINISHED";

export const requestMeetingEvaluationLink = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>, getState: () => IStore): Promise<void> => {
    const selectedProfile: string | undefined = getState().auth.selectedProfile;
    await ActionUtility.createThunkEffect<MeetingEvaluationLink | HttpErrorResponseModel>(
      dispatch,
      REQUEST_MEETING_EVALUATION_LINK,
      PagesEffect.requestMeetingEvaluationLink,
      uuid,
      selectedProfile,
    );
  };
};

export const REQUEST_MEETING_EVALUATION_ACTORS: string = "PagesAction.REQUEST_MEETING_EVALUATION_ACTORS";
export const REQUEST_MEETING_EVALUATION_ACTORS_FINISHED: string =
  "PagesAction.REQUEST_MEETING_EVALUATION_ACTORS_FINISHED";

export const requestMeetingEvaluationActors = (uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<ActorModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_MEETING_EVALUATION_ACTORS,
      PagesEffect.requestMeetingEvaluationActors,
      uuid,
    );
  };
};

export const SET_MEETING_EVALUATION_SELECTED_ACTOR: string = "PagesAction.SET_MEETING_EVALUATION_SELECTED_ACTOR";

export const setMeetingEvaluationSelectedActor = (actor: ActorModel | undefined): IAction<ActorModel | undefined> => {
  return ActionUtility.createAction(SET_MEETING_EVALUATION_SELECTED_ACTOR, actor);
};

export const REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA: string =
  "PagesAction.REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA";
export const REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA_FINISHED: string =
  "PagesAction.REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA_FINISHED";

export const requestRoleApplicationEvaluationCriteria = (): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<EvaluationCriterionModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA,
      PagesEffect.requestRoleApplicationEvaluationCriteria,
    );
  };
};

export const REQUEST_CREATE_ROLE_APPLICATION_EVALUATION: string =
  "PagesAction.REQUEST_CREATE_ROLE_APPLICATION_EVALUATION";
export const REQUEST_CREATE_ROLE_APPLICATION_EVALUATION_FINISHED: string =
  "PagesAction.REQUEST_CREATE_ROLE_APPLICATION_EVALUATION_FINISHED";

export const requestCreateEvaluation = (uuid: string, data: CreateEvaluationFromLinkRequest): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<RoleApplicationEvaluationModel | HttpErrorResponseModel>(
      dispatch,
      REQUEST_CREATE_ROLE_APPLICATION_EVALUATION,
      PagesEffect.requestCreateEvaluation,
      uuid,
      data,
    );
  };
};

export const CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION: string =
  "PagesAction.CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION";

export const clearRequestCreateEvaluation = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION);
};

export const REQUEST_APPLICATION_ACTOR_AUDITIONS: string = "PagesAction.REQUEST_APPLICATION_ACTOR_AUDITIONS";
export const REQUEST_APPLICATION_ACTOR_AUDITIONS_FINISHED: string =
  "PagesAction.REQUEST_APPLICATION_ACTOR_AUDITIONS_FINISHED";

export const requestRoleApplicationActorAuditions = (uuid: string, actor_uuid: string): any => {
  return async (dispatch: ReduxDispatch<ActionUnion>): Promise<void> => {
    await ActionUtility.createThunkEffect<SimpleAuditionVideoModel[] | HttpErrorResponseModel>(
      dispatch,
      REQUEST_APPLICATION_ACTOR_AUDITIONS,
      PagesEffect.requestRoleApplicationActorAuditions,
      uuid,
      actor_uuid,
    );
  };
};

export const CLEAR_APPLICATION_ACTOR_AUDITIONS: string = "PagesAction.CLEAR_APPLICATION_ACTOR_AUDITIONS";

export const clearRoleApplicationActorAuditions = (): IAction<undefined> => {
  return ActionUtility.createAction(CLEAR_APPLICATION_ACTOR_AUDITIONS);
};
