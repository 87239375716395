import IAction from "models/IAction";
import EvaluationCriterionModel from "models/api-models/EvaluationCriterionModel";
import MeetingLocation from "models/api-models/MeetingLocation";
import RoleApplicationEvaluationModel from "models/api-models/RoleApplicationEvaluationModel";
import RoleApplicationModel from "models/api-models/role-application/RoleApplicationModel";
import ActorModel from "stores/actors/models/actors/ActorModel";
import IProjectState from "../../models/IProjectsState";
import * as RoleApplicationAction from "./RoleApplicationAction";

const RoleApplicationReducer = {
  [RoleApplicationAction.REQUEST_ROLE_APPLICATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
        },
      },
    };
  },

  //CLEAR_ROLE_APPLICATIONS
  [RoleApplicationAction.CLEAR_ROLE_APPLICATIONS](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: [],
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_ACTOR_FINISHED](state: IProjectState, action: IAction<ActorModel>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          actor: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_ACTOR](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          actor: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_PRESENT_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
          requestPresent: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_PRESENT](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestPresent: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_INVITE_TO_CALLBACK_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
          requestInviteToCallBack: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_INVITE_TO_CALLBACK](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestInviteToCallBack: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_SHORTLIST_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
          requestShortList: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_SHORTLIST](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestShortList: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_DECLINE_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
          requestDecline: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_DECLINE](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestDecline: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_MAKE_OFFER_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationDetail: action.payload!,
          requestMakeOfferRole: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_MAKE_OFFER](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestMakeOfferRole: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.SHOW_OFFER_MODAL](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          makeOfferModal: true,
        },
      },
    };
  },

  [RoleApplicationAction.HIDE_OFFER_MODAL](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationDetail: undefined,
          makeOfferModal: false,
        },
      },
    };
  },

  [RoleApplicationAction.SET_MAKE_OFFER_BUTTON](state: IProjectState, action: IAction<boolean>): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          makeOfferActive: action.payload!,
        },
      },
    };
  },

  /*[RoleApplicationAction.SET_SELECTED_APPLICATION_FINISHED](state: IProjectState, action: IAction<RoleApplicationModel | undefined>): IProjectState {        
        return {
          ...state,
          projectRoles: {
            ...state.projectRoles,
            roleApplication: {
                ...state.projectRoles.roleApplication,
                roleApplicationDetail: action.payload!,
                makeOfferModal: true
                //selectedApplication: "asd"//action.payload!,
            }
          }
        };
    },*/

  [RoleApplicationAction.REQUEST_WITHDRAW_OFFER_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationDetail: action.payload!,
          requestWithdrawRoleOffer: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_WITHDRAWN_OFFER](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestWithdrawRoleOffer: undefined,
        },
      },
    };
  },

  /*
    [RoleApplicationAction.REQUEST_OPTION_FINISHED](state: IProjectState, action: IAction<RoleApplicationModel[]>): IProjectState {
        return {
            ...state,
            projectRoles: {
                ...state.projectRoles,
                roleApplication: {
                    ...state.projectRoles.roleApplication,
                    roleApplications: action.payload!,
                    requestOption: "success"
                }
            }
        }
    },
    */

  [RoleApplicationAction.REQUEST_OPTION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationDetail: action.payload!,
          requestOption: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_OPTION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestOption: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_ROLE_APPLICATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationDetail: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_ELECT_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
          requestElect: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_ELECT](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestElect: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.SHOW_FINALIZE_MODAL](
    state: IProjectState,
    action: IAction<RoleApplicationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          finalizeApplicationDetail: action.payload,
          finalizeModal: true,
        },
      },
    };
  },

  [RoleApplicationAction.HIDE_FINALIZE_MODAL](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          finalizeApplicationDetail: undefined,
          finalizeModal: false,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_FINALIZE_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
          requestFinalizeApplication: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_FINALIZE](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestFinalizeApplication: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.SET_INVITE_CALLBACK_MODAL_STATUS](
    state: IProjectState,
    action: IAction<string | undefined>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          inviteCallbackModalApplicationUuid: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.SET_MEETING_LOCATION_EDIT_MODAL_STATUS](
    state: IProjectState,
    action: IAction<string | undefined>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          meetingLocationEditModalApplicationUuid: action.payload!,
          roleApplicationDetail: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_MEETING_LOCATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplications: action.payload!,
          requestMeetingLocation: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_MEETING_LOCATION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          requestMeetingLocation: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_ACTOR_MEETING_LOCATION_FINISHED](
    state: IProjectState,
    action: IAction<MeetingLocation>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          actorMeetingLocation: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_ACTOR_MEETING_LOCATION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          actorMeetingLocation: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATIONS_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationEvaluationModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluations: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_ROLE_APPLICATION_EVALUATIONS](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluations: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATION_CRITERIA_FINISHED](
    state: IProjectState,
    action: IAction<EvaluationCriterionModel[]>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationCriteria: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_ROLE_APPLICATION_EVALUATION_CRITERIA](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationCriteria: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_CREATE_ROLE_APPLICATION_EVALUATION_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationEvaluationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationDetail: action.payload!,
          roleApplicationEvaluationCreateRequest: "success",
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_CREATE_ROLE_APPLICATION_EVALUATION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationCreateRequest: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_ROLE_APPLICATION_EVALUATION_DETAIL_FINISHED](
    state: IProjectState,
    action: IAction<RoleApplicationEvaluationModel>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationDetail: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_ROLE_APPLICATION_EVALUATION_DETAIL](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationDetail: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationUpdateRequest: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_UPDATE_ROLE_APPLICATION_EVALUATION](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          roleApplicationEvaluationUpdateRequest: undefined,
        },
      },
    };
  },

  [RoleApplicationAction.REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK_FINISHED](
    state: IProjectState,
    action: IAction<string>,
  ): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          createMeetingEvaluationLinkResponse: action.payload!,
        },
      },
    };
  },

  [RoleApplicationAction.CLEAR_REQUEST_CREATE_MEETING_EVALUATION_CREATE_LINK](state: IProjectState): IProjectState {
    return {
      ...state,
      projectRoles: {
        ...state.projectRoles,
        roleApplication: {
          ...state.projectRoles.roleApplication,
          createMeetingEvaluationLinkResponse: undefined,
        },
      },
    };
  },
};

export default RoleApplicationReducer;
