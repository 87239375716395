import { BaseModel } from "sjs-base-model";

export default class IProjectProductionCompanyModel extends BaseModel {
  public readonly name: string = "";
  public readonly slug: string = "";
  public readonly logo: string | null = null;

  constructor(data: Partial<IProjectProductionCompanyModel>) {
    super();

    this.update(data);
  }
}
