enum CookieEnum {
  User = "user",
  Token = "token",
  Lang = "castelect-lang",
  SelectedProfile = "castelect-selected-profile",
  LastLoggedUser = "castelect-last-logged-user",
  InviteCode = "invite-code",
  CastelectReactAppVersion = "castelect-react-app-version",
}

export default CookieEnum;
